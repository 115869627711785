import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Loading, Responsive, Stack} from 'pylon/ui';
import {ProfileBasicCard} from '@/components/(profile)/ProfileBasicCard';
import {ProfileBioCard} from '@/components/(profile)/ProfileBioCard';
import {ProfileEventsCard} from '@/components/(profile)/ProfileEventsCard';
import {ProfileSocialLinksCard} from '@/components/(profile)/ProfileSocialLinksCard';
import {TeamProfileHeader} from '@/components/(profile)/TeamProfileHeader';
import {TeamProfileMembersCard} from '@/components/(profile)/TeamProfileMembersCard';
import {AppLayout} from '@/components/AppLayout';
import {useTeamProfile} from '@/fetch';

type Props = {
  params: {
    slug: string;
  };
};

export const PublicTeamProfileRoute: FunctionalComponent<Props> = ({
  params: {slug},
}) => {
  const {data: profile, loading} = useTeamProfile({
    params: {
      slug: slug,
    },
  });

  let content = null;

  if (loading) {
    content = <Loading centered />;
  } else if (profile) {
    content = (
      <div>
        <TeamProfileHeader profile={profile} />
        <Stack direction="column" gap={12}>
          <div
            css={`
              display: flex;
              flex-direction: column;
              gap: var(--gap-8);
              grid-template-columns: 1fr;
              ${mediaQuery(
                'md>',
                css`
                  align-items: flex-start;
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                `,
              )}
            `}
          >
            <Stack direction="column" gap={8}>
              <ProfileBioCard profile={profile} />
              <ProfileBasicCard profile={profile} />
            </Stack>
            <TeamProfileMembersCard profile={profile} />
            <ProfileSocialLinksCard profile={profile} />
            <ProfileEventsCard profile={profile} />
          </div>
        </Stack>
      </div>
    );
  }

  return (
    <AppLayout>
      <Responsive size="xxl">{content}</Responsive>
    </AppLayout>
  );
};
