import {FunctionalComponent} from 'preact';
import dayjs from 'dayjs';
import {
  Anchor,
  Card,
  DescriptionList,
  DescriptionListItem,
  Stack,
  Typography,
} from 'pylon/ui';
import {GetTeamProfile, GetUserProfile} from '@shared/api-types';
import {Event, UserTeamRole} from '@shared/database-types';
import {useAppContext} from '@/lib/app-context';
import {EventManageButton} from '../(event)/EventManageButton';

type Props = {
  profile: GetUserProfile['response'] | GetTeamProfile['response'];
};

export const ProfileEventsCard: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  const {currentUser, currentTeam} = useAppContext();
  if (!profile.events?.length) {
    return null;
  }

  return (
    <Card {...props}>
      <Typography weight="bold" css-mb={4}>
        Events
      </Typography>
      <DescriptionList
        expandWidthSize={6}
        css={`
          & b {
            font-weight: 500;
          }
        `}
      >
        {profile.events.map((event) => {
          const canManageEvent =
            currentUser?.id === event.userId ||
            (currentTeam?.id === event.teamId &&
              currentUser?.teams.some(
                (team) =>
                  team.id === event.teamId && team.role === UserTeamRole.OWNER,
              ));

          return (
            <DescriptionListItem
              key={event.id}
              title={dayjs(event.startDate).format('MMM D, YYYY')}
              cols="35% 1fr"
              css={`
                & .event-manage-button {
                  opacity: 0;
                  transition: opacity 0.2s;
                }
                &:hover .event-manage-button {
                  opacity: 1;
                }
              `}
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                css={``}
              >
                <Stack direction="column" gap={1}>
                  <div>
                    <b>{event.title}</b> · {event.location}
                  </div>
                  {!!event.description && (
                    <Typography
                      variant="body3"
                      css-color="var(--fg-muted)"
                      tagName="p"
                    >
                      {event.description}
                    </Typography>
                  )}
                  {!!event.externalLink && (
                    <Anchor
                      rel="noopener"
                      target="_blank"
                      variant="primary"
                      href={event.externalLink}
                    >
                      <Typography variant="body3">
                        {event.externalLink}
                      </Typography>
                    </Anchor>
                  )}
                </Stack>
                {canManageEvent && <EventManageButton event={event} />}
              </Stack>
            </DescriptionListItem>
          );
        })}
      </DescriptionList>
    </Card>
  );
};
