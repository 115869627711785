import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Controller, useForm} from 'react-hook-form';
import {css} from 'styled-components';
import {mutate} from 'swr';
import {mediaQuery} from 'pylon/lib';
import {
  Form,
  FormInput,
  FormSelect,
  FormSubmit,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {CreateMe} from '@shared/api-types';
import {accountTypesForEmail} from '@shared/helpers';
import {
  validateAccountType,
  validateFirstLastName,
  validateUsername,
} from '@shared/validators';
import {ProfileForm_ControlStateAndCity} from '@/components/ProfileForm/(controls)/_ControlStateAndCity';
import {useCreateMe} from '@/fetch';
import {useAppContext} from '@/lib/app-context';
import {handleFormValidate} from '@/lib/error-messages';
import {useNetworkError} from '@/lib/use-network-error';
import {capitalize} from '@/lib/utils';
import {StepComponent} from '../types';

type FormData = CreateMe['request']['body'];

type Props = StepComponent;

export const GetStartedProcess_StepAccount: FunctionalComponent<Props> = ({
  onNext,
}) => {
  const {currentFirebaseUser} = useAppContext();
  const [submiting, setSubmiting] = useState(false);
  const [createMe] = useCreateMe();
  const [networkError, setNetworkError] = useNetworkError();

  const {
    formState: {errors},
    handleSubmit,
    register,
    setValue,
    control,
    watch,
  } = useForm<FormData>();

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await createMe({
        body: {
          ...formData,
          username: sanitizeUsername(formData.username),
        },
      });
      onNext();
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h3"
        tagName="h1"
        textTransform="uppercase"
        fontStyle="italic"
        css-mb={4}
      >
        Welcome! <br />
        Let's create your profile
      </Typography>
      <Typography css-mb={12} css-color="var(--fg-muted)">
        First, we need to know a little bit about you.
      </Typography>
      {networkError && (
        <ToastMessage severity="error" css-mb={5}>
          {networkError}
        </ToastMessage>
      )}
      <Stack direction="column" gap={8}>
        <FormInput
          {...register('username', {
            validate: (value) =>
              handleFormValidate(validateUsername(sanitizeUsername(value))),
          })}
          id="username"
          label="Username"
          invalid={!!errors.username}
          invalidText={errors?.username?.message}
          fullWidth
        />
        <Stack
          gap={8}
          direction="column"
          css={`
            ${mediaQuery(
              'md>',
              css`
                flex-direction: row;
              `,
            )}
          `}
        >
          <FormInput
            {...register('firstName', {
              validate: (value) =>
                handleFormValidate(validateFirstLastName(value)),
            })}
            id="firstName"
            label="First name"
            invalid={!!errors.firstName}
            invalidText={errors?.firstName?.message}
            fullWidth
          />
          <FormInput
            {...register('lastName', {
              validate: (value) =>
                handleFormValidate(validateFirstLastName(value)),
            })}
            id="lastName"
            label="Last name"
            invalid={!!errors.lastName}
            invalidText={errors?.lastName?.message}
            fullWidth
          />
        </Stack>
        <ProfileForm_ControlStateAndCity
          register={register as any}
          watch={watch as any}
          errors={errors as any}
          control={control as any}
          setValue={setValue as any}
        />
        <Controller
          name="accountType"
          control={control}
          rules={{
            validate: (value) =>
              handleFormValidate(
                validateAccountType(value, currentFirebaseUser!.email!),
              ),
          }}
          render={({field}) => {
            return (
              <FormSelect
                id="accountType"
                label="What are you?"
                options={accountTypesForEmail(currentFirebaseUser!.email!).map(
                  (key) => ({
                    label: capitalize(key),
                    value: key,
                  }),
                )}
                invalid={!!errors.accountType}
                invalidText={errors?.accountType?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
      </Stack>
      <Stack css-mt={8} justifyContent="flex-end">
        <FormSubmit
          variant="primary"
          value="Continue"
          buttonSize="lg"
          submitting={submiting}
          disabled={submiting}
        />
      </Stack>
    </Form>
  );
};

function sanitizeUsername(username: string) {
  return username.toLocaleLowerCase().trim();
}
