import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Loading, Responsive, Stack, ToastMessage, Typography} from 'pylon/ui';
import {Search} from '@shared/api-types';
import {TeamPreview, UserPreview} from '@shared/common-types';
import {ErrorCode} from '@shared/error-code';
import {
  validateGradYear,
  validatePlayerHand,
  validatePlayerLevel,
  validatePlayerPosition,
  validateUsState,
} from '@shared/validators';
import {SearchFilters, SearchFiltersType} from '@/components/(search)';
import {SearchInput} from '@/components/(search)/SearchInput';
import {AppLayout} from '@/components/AppLayout';
import {TeamCard} from '@/components/TeamCard';
import {UserCard} from '@/components/UserCard';
import {useSearch} from '@/fetch';
import {toNumber} from '@/lib/utils';

type Props = {
  params: {
    searchValue: string;
  };
  query: LooseStrings<Search['request']['body']>;
  path: string;
};

export const PublicSearchRoute: FunctionalComponent<Props> = ({
  params: {searchValue},
  query,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [filters, setFilters] = useState<SearchFiltersType>(
    normalizeSearchFilters(query),
  );
  const [rows, setRows] = useState([]);
  const [runSearch] = useSearch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resp = await runSearch({
          body: {
            searchValue,
            offset: toNumber(query.offset) || 0,
            limit: toNumber(query.limit) || 60,
            ...filters,
          },
        });
        setRows(resp.rows as any);
      } catch (err: unknown) {}
      setLoading(false);
    })();
  }, [filters, searchValue]);

  let content = null;
  if (loading) {
    content = (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  } else {
    if (rows.length === 0) {
      content = (
        <Stack
          direction="column"
          alignItems="center"
          gap={1}
          css-pt={4}
          css-pb={4}
        >
          <Typography weight="medium">
            No profiles to show at the moment
          </Typography>
        </Stack>
      );
    } else {
      content = (
        <div
          css={`
            display: flex;
            flex-direction: column;
            gap: var(--gap-4);
            ${mediaQuery(
              'md>',
              css`
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
              `,
            )}
          `}
        >
          {rows.map((profile) => {
            return !!(profile as any).accountType ? (
              <UserCard user={profile as UserPreview} />
            ) : (
              <TeamCard team={profile as TeamPreview} />
            );
          })}
        </div>
      );
    }
  }

  return (
    <AppLayout noDrawer>
      <div
        css={`
          display: grid;
          grid-template-columns: 1fr;
          ${mediaQuery(
            'md>',
            css`
              grid-template-columns: 288px 1fr;
            `,
          )}
        `}
      >
        <Responsive
          css={`
            width: 100%;
            ${mediaQuery(
              'md>',
              css`
                padding-top: var(--gap-4);
                padding-bottom: var(--gap-4);
              `,
            )}
          `}
        >
          <SearchInput
            containerCss={css`
              margin: 0 auto;
              max-width: 35rem;
              width: 100%;
              margin-bottom: var(--gap-6);
              ${mediaQuery(
                'md>',
                css`
                  display: none;
                `,
              )}
            `}
          />

          <SearchFilters
            defaultValues={normalizeSearchFilters(query)}
            onChange={(newFilters) => setFilters(newFilters)}
          />
        </Responsive>
        <Responsive
          css={`
            width: 100%;
          `}
        >
          {content}
        </Responsive>
      </div>
    </AppLayout>
  );
};

const normalizeSearchFilters = (d: Loose<Search['request']['body']>) => {
  const type = d.type
    ? d.type.split(',').filter((v: any) => v === 'player' || v === 'team')
    : ['player', 'team'];

  const state = d.state
    ? d.state.split(',').filter((v: any) => validateUsState(v) === true)
    : [];

  const gradYear = d.gradYear
    ? d.gradYear
        .split(',')
        .map((v: any) => parseInt(v, 10))
        .filter((v: number) => validateGradYear(v) === true)
    : [];

  const position1 = d.position1
    ? d.position1
        .split(',')
        .filter((v: any) => validatePlayerPosition(v) === true)
    : [];

  const throwingHand = d.throwingHand
    ? d.throwingHand
        .split(',')
        .filter((v: any) => validatePlayerHand(v) === true)
    : [];

  const battingHand = d.battingHand
    ? d.battingHand
        .split(',')
        .filter((v: any) => validatePlayerHand(v) === true)
    : [];

  const level = d.level
    ? d.level.split(',').filter((v: any) => validatePlayerLevel(v) === true)
    : [];

  return {
    type,
    state,
    gradYear,
    position1,
    throwingHand,
    battingHand,
    level,
  };
};

type Loose<T> = {
  [P in keyof T]?: any;
};
