import {FunctionalComponent} from 'preact';
import {useLocation} from 'preact-iso';
import {useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {
  Button,
  Card,
  Form,
  FormInput,
  FormSelect,
  FormSubmit,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {CreateMeInvite} from '@shared/api-types';
import {AccountType, UsersOnTeams} from '@shared/database-types';
import {useCreateMeInvite} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {capitalize} from '@/lib/utils';

type FormData = Pick<
  CreateMeInvite['request']['body'],
  'toEmail' | 'accountType'
>;

type Props = {};

export const InviteMemberForm: FunctionalComponent<Props> = ({}: Props) => {
  const location = useLocation();
  const {currentTeam} = useAuthenticatedContext();
  const [success, setSuccess] = useState<string>('');
  const [networkError, setNetworkError] = useState<Error>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [createMeInvite] = useCreateMeInvite();

  const {
    register,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>();

  const onSubmit = async (formData: FormData) => {
    setSubmitting(true);
    try {
      await createMeInvite({
        body: {
          accountType: formData.accountType,
          teamId: currentTeam!.id,
          toEmail: formData.toEmail,
        },
      });
      setSuccess(formData.toEmail);
    } catch (error: any) {
      setNetworkError(error);
    }
    setSubmitting(false);
  };

  if (success) {
    return (
      <Card>
        <Typography variant="h5" tagName="p" css-mb={2}>
          Invite sent! 🎉
        </Typography>
        <Typography variant="body1">
          We've sent an invite to {''}
          <Typography variant="body1" weight="medium" tagName="span">
            {success}
          </Typography>
          . They'll be able to join the team once they accept the invite.
        </Typography>
        <Stack gap={4} alignItems="center" css-mt={6}>
          <Button
            variant="primary"
            onClick={() => {
              reset();
              setSuccess('');
            }}
          >
            Invite another member
          </Button>
          <Button
            variant="muted"
            onClick={() => location.route(ROUTES.AUTHED_TEAM_SETTINGS_MEMBERS)}
          >
            Back to team settings
          </Button>
        </Stack>
      </Card>
    );
  }

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={4}>
          <Typography>
            New team members will get an email with a link to accept the
            invitation.
          </Typography>
          <FormInput
            {...register('toEmail', {required: 'Email is required'})}
            id="to"
            label="Email"
            invalid={!!errors.toEmail}
            invalidText={errors?.toEmail?.message}
            fullWidth
          />
          <FormSelect
            {...register('accountType', {required: 'Account type is required'})}
            id="accountType"
            label="Account type"
            invalid={!!errors.accountType}
            invalidText={errors?.accountType?.message}
            options={[
              {
                label: capitalize(AccountType[AccountType.PLAYER]),
                value: AccountType.PLAYER,
              },
              {
                label: capitalize(AccountType[AccountType.COLLEGE_COACH]),
                value: AccountType.COLLEGE_COACH,
              },
              {
                label: capitalize(AccountType[AccountType.HIGH_SCHOOL_COACH]),
                value: AccountType.HIGH_SCHOOL_COACH,
              },
              {
                label: capitalize(AccountType[AccountType.YOUTH_COACH]),
                value: AccountType.YOUTH_COACH,
              },
            ]}
            fullWidth
          />
        </Stack>
        {networkError && (
          <ToastMessage severity="error" css-mt={6}>
            {networkError?.message}
          </ToastMessage>
        )}
        <Stack gap={4} css-mt={8}>
          <FormSubmit
            value="Send invite"
            variant="primary"
            submitting={submitting}
          />
          <Button
            variant="default"
            onClick={() => location.route(ROUTES.AUTHED_TEAM_SETTINGS_MEMBERS)}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </Card>
  );
};

export const roleSelectOptions: Array<{
  value: UsersOnTeams['role'];
  label: string;
}> = [
  {value: 'OWNER', label: 'Owner'},
  {value: 'ADMIN', label: 'Admin'},
  {value: 'MEMBER', label: 'Member'},
];
