import {ErrorCode} from '@shared/error-code';

// prettier-ignore
export const errorMessages = {
  [ErrorCode.BAD_REQUEST]: 'The request is invalid.',
  [ErrorCode.FORBIDDEN]: 'You don’t have permission to do this.',
  [ErrorCode.SERVER_ERROR]: 'A server error occurred. Please try again.',
  [ErrorCode.NOT_FOUND]: 'Requested resource not found.',
  [ErrorCode.RESOURCE_NOT_FOUND]: 'Requested resource not found.',
  [ErrorCode.UNAUTHORIZED]: 'You don’t have permission to do this.',
  //
  [ErrorCode.INVALID_ALBUM_NAME]: 'Album name is invalid.',
  [ErrorCode.INVALID_CITY]: 'Please select a city name',
  [ErrorCode.INVALID_EMAIL]: 'Please enter a valid email address.',
  [ErrorCode.INVALID_GRAD_YEAR]: 'Invalid graduation year.',
  [ErrorCode.INVALID_ORGANIZATION_NAME]: 'Organization name is invalid.',
  [ErrorCode.INVALID_ORGANIZATION_SLUG]: 'Please use only letters, numbers, and dashes.',
  [ErrorCode.INVALID_PASSWORD]: 'Password does not meet requirements.',
  [ErrorCode.INVALID_PLAYER_HAND]: 'Invalid player hand.',
  [ErrorCode.INVALID_PLAYER_LEVEL]: 'Invalid player level.',
  [ErrorCode.INVALID_PLAYER_POSITION]: 'Invalid player position.',
  [ErrorCode.INVALID_POST_CONTENT]: 'Post content cannot be empty.',
  [ErrorCode.INVALID_PROFILE_BIO]: 'Profile bio is too long or contains invalid characters.',
  [ErrorCode.INVALID_STATE]: 'Please select a state',
  [ErrorCode.INVALID_TEAM_NAME]: 'Team name contains invalid characters or is too long.',
  [ErrorCode.INVALID_URL]: 'Invalid URL.',
  [ErrorCode.INVALID_USERNAME]: 'Username must be between 3 and 20 characters and contain only letters, numbers, and dashes.',
  [ErrorCode.INVALID_VALUE]: 'Invalid value.',
  [ErrorCode.INVITE_ACCOUNT_TYPE_MISMATCH]: 'Account type mismatch.',
  [ErrorCode.INVITE_NOT_FOUND]: 'Invite not found or has expired.',
  [ErrorCode.MISSING_ACCOUNT_TYPE]: 'Please select an account type',
  [ErrorCode.MISSING_FIELD]: 'This field is required.',
  [ErrorCode.MISSING_VIDEO]: 'Please upload a video.',
  [ErrorCode.MUST_AGREE_TO_TERMS]: 'You must agree to the terms and conditions.',
  [ErrorCode.OFFSET_AND_LIMIT_REQUIRED]: 'Offset and limit parameters are required.',
  [ErrorCode.POST_NOT_FOUND]: 'Post not found.',
  [ErrorCode.PROFILE_NOT_FOUND]: 'Profile not found.',
  [ErrorCode.SOMETHING_WENT_WRONG]: 'Something went wrong. Please try again.',
  [ErrorCode.TEAM_NOT_FOUND]: 'Specified team not found.',
  [ErrorCode.USER_ALREADY_BELONGS_TO_TEAM]: 'User already belongs to this team.',
  [ErrorCode.USER_ALREADY_EXISTS]: 'User already exists.',
  [ErrorCode.USER_ALREADY_INVITED]: 'User has already been invited.',
  [ErrorCode.USER_ALREADY_OWN_ORGANIZATION]: 'You already own an organization.',
  [ErrorCode.USER_DOES_NOT_BELONG_TO_TEAM]: 'User does not belong to this team.',
  [ErrorCode.USER_NOT_FOUND]: 'User not found.',
  [ErrorCode.USERNAME_ALREADY_EXISTS]: 'Username already exists.',
};

export function handleFormValidate(
  response: keyof typeof errorMessages | boolean,
) {
  if (typeof response === 'string') {
    return (
      errorMessages[response] ?? errorMessages[ErrorCode.SOMETHING_WENT_WRONG]
    );
  }
  return true;
}
