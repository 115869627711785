import {UseQueryOptions} from 'pylon/lib';
import * as T from '@shared/api-types';
import {ENDPOINTS} from '@shared/endpoints';
import {useQuery} from '.';

/* discover-ctrl */
export const useDiscoverRecentProfiles = (
  req: T.GetDiscoverRecentProfiles['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetDiscoverRecentProfiles['response']>(
    ENDPOINTS.DISCOVER_RECENT_PROFILES.replace(
      ':offset/:limit',
      `${req.params.offset}/${req.params.limit}`,
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
};

export const useDiscoverRecentPosts = (
  req: T.GetDiscoverRecentPosts['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetDiscoverRecentPosts['response']>(
    ENDPOINTS.DISCOVER_RECENT_POSTS.replace(
      ':offset/:limit',
      `${req.params.offset}/${req.params.limit}`,
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
};

export const useDiscoverRecentTeams = (
  req: T.GetDiscoverRecentTeams['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetDiscoverRecentTeams['response']>(
    ENDPOINTS.DISCOVER_RECENT_TEAMS.replace(
      ':offset/:limit',
      `${req.params.offset}/${req.params.limit}`,
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* me-ctrl */
export const useMe = (_req = null, opts?: UseQueryOptions) => {
  return useQuery<T.GetMe['response']>(ENDPOINTS.ME, {
    defaultData: null,
    ...opts,
  });
};

/* me-following-ctrl */
export const useMeFollowing = (
  req: T.GetMeFollowing['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetMeFollowing['response']>(
    ENDPOINTS.ME_FOLLOWING.replace(
      ':offset/:limit',
      `${req.params.offset}/${req.params.limit}`,
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* me-prospects-ctrl */
export const useMeProspects = (
  req: T.GetMeProspects['request'],
  opts?: UseQueryOptions,
) => {
  const searchParamStr = req.searchParams?.tagIds?.length
    ? `?${new URLSearchParams(req.searchParams as any).toString()}`
    : '';

  return useQuery<T.GetMeProspects['response']>(
    ENDPOINTS.ME_PROSPECTS.replace(
      ':offset/:limit',
      `${req.params.offset}/${req.params.limit}`,
    ) + searchParamStr,
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* me-prospect-tags */
export const useMeProspectTags = (req = null, opts?: UseQueryOptions) => {
  return useQuery<T.GetMeProspectTags['response']>(ENDPOINTS.ME_PROSPECT_TAGS, {
    defaultData: [],
    ...opts,
  });
};

/* me-notifications-ctrl */
export const useMeNotifications = (
  req: T.GetMeNotifications['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetMeNotifications['response']>(
    ENDPOINTS.ME_NOTIFICATIONS.replace(
      ':offset/:limit',
      `${req.params.offset}/${req.params.limit}`,
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* me-notifications-ctrl */
export const useMeNotificationsUnreadCount = (
  _req = null,
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetMeNotificationsUnreadCount['response']>(
    ENDPOINTS.ME_NOTIFICATIONS_UNREAD_COUNT,
    {
      defaultData: null,
      ...opts,
    },
  );
};

/* user-posts-ctrl */
export const useUserPosts = (
  req: T.GetUserPosts['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetUserPosts['response']>(
    ENDPOINTS.USER_POSTS.replace(':userId', String(req.params.userId)),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* user-posts-ctrl */
export const useUserPost = (
  req: T.GetUserPost['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetUserPost['response']>(
    ENDPOINTS.USER_POST.replace(':username', req.params.username).replace(
      ':postId',
      String(req.params.postId),
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* users-profile-ctrl */
export const useUsersProfile = (
  req: T.GetUserProfile['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetUserProfile['response']>(
    ENDPOINTS.USER_PROFILE.replace(':username', String(req.params.username)),
    {
      defaultData: null,
      ...opts,
    },
  );
};

/* me-email-notification-settings-ctrl */
export const useMeEmailNotificationSettings = (
  _req = null,
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetMeEmailNotificationSettings['response']>(
    ENDPOINTS.ME_SETTINGS_EMAIL_NOTIFICATIONS,
    {
      defaultData: null,
      ...opts,
    },
  );
};

/* me-team-members-ctrl */
export const useMeTeamMembers = (
  req: T.GetMeTeamMembers['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetMeTeamMembers['response']>(
    ENDPOINTS.ME_TEAM_MEMBERS.replace(':teamId', String(req.params.teamId)),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* me-albums */
export const useMeAlbums = (_req = null, opts?: UseQueryOptions) => {
  return useQuery<T.GetMeAlbums['response']>(ENDPOINTS.ME_ALBUMS, {
    defaultData: [],
    ...opts,
  });
};

/* user-albums */
export const useUserAlbums = (
  req: T.GetUserAlbums['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetUserAlbums['response']>(
    ENDPOINTS.USER_ALBUMS.replace(':userId', String(req.params.userId)),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* album */
export const useUserAlbum = (
  req: T.GetAlbum['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetAlbum['response']>(
    ENDPOINTS.ALBUM.replace(':albumId', String(req.params.albumId)),
    {
      defaultData: null,
      ...opts,
    },
  );
};

/* me-teams */
export const useTeamProfile = (
  req: T.GetTeamProfile['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetTeamProfile['response']>(
    ENDPOINTS.TEAM_PROFILE.replace(':slug', req.params.slug),
    {
      defaultData: null,
      ...opts,
    },
  );
};

/* team-members */
export const useTeamMembers = (
  req: T.GetTeamMembers['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetTeamMembers['response']>(
    ENDPOINTS.TEAM_MEMBERS.replace(':teamId', String(req.params.teamId)),
    {
      defaultData: [],
      ...opts,
    },
  );
};

/* me-notes */
export const useMeUserProfileNotes = (
  req: T.GetMeUserProfileNotes['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetMeUserProfileNotes['response']>(
    ENDPOINTS.ME_USER_PROFILE_NOTES.replace(
      ':targetUserId',
      req.params.targetUserId,
    ),
    {
      defaultData: null,
      ...opts,
    },
  );
};


/* user-post-comments */
export const useUserPostComments = (
  req: T.GetPostComments['request'],
  opts?: UseQueryOptions,
) => {
  return useQuery<T.GetPostComments['response']>(
    ENDPOINTS.POST_COMMENTS.replace(':username', req.params.username).replace(
      ':postId',
      String(req.params.postId),
    ),
    {
      defaultData: [],
      ...opts,
    },
  );
}