import {FunctionalComponent} from 'preact';
import {Breadcrumbs, Responsive, Stack} from 'pylon/ui';
import {InviteMemberForm} from '@/components/(team-settings)/InviteMemberForm';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';

type Props = {};

const TeamSettingsInviteMemberRoute: FunctionalComponent<
  Props
> = ({}: Props) => {
  const {currentTeam} = useAuthenticatedContext();

  return (
    <Stack direction="column" css-pt={12} css-mb={8}>
      <Breadcrumbs
        items={[
          {
            title: 'Team members',
            href: ROUTES.AUTHED_TEAM_SETTINGS_MEMBERS,
          },

          {title: 'Invite'},
        ]}
      />
      <Responsive size="md">
        <InviteMemberForm />
      </Responsive>
    </Stack>
  );
};

export default TeamSettingsInviteMemberRoute;
