import {FunctionalComponent} from 'preact';
import {Loading, Stack, ToastMessage, Typography} from 'pylon/ui';
import {ErrorCode} from '@shared/error-code';
import {PostHorizontalList} from '@/components/(post)/PostHorizontalList';
import {useDiscoverRecentPosts} from '@/fetch';

type Props = {};

export const RecentPosts: FunctionalComponent<Props> = ({...props}) => {
  const {data, error, loading} = useDiscoverRecentPosts({
    params: {offset: 0, limit: 10},
  });

  let content = null;

  if (loading) {
    content = (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  } else {
    const {totalCount, rows} = data;
    if (totalCount === 0) {
      content = (
        <Stack
          direction="column"
          alignItems="center"
          gap={1}
          css-pt={4}
          css-pb={4}
        >
          <Typography weight="medium">
            No profiles to show at the moment
          </Typography>
        </Stack>
      );
    } else {
      content = <PostHorizontalList posts={rows} loading={false} />;
    }
  }

  return (
    <div {...props}>
      <Typography
        variant="h4"
        textTransform="uppercase"
        fontStyle="italic"
        css-mb={6}
      >
        Recent highlights
      </Typography>
      {content}
    </div>
  );
};
