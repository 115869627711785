import {FunctionalComponent} from 'preact';
import {Anchor, Card, Loading, Stack, Typography} from 'pylon/ui';
import {GetTeamProfile} from '@shared/api-types';
import {Team} from '@shared/database-types';
import {useTeamMembers} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';
import {capitalize, userInfoDisplay} from '@/lib/utils';
import {PDAvatar} from '../PDAvatar';
import {UserCard} from '../UserCard';

type Props = {
  profile: GetTeamProfile['response'];
};

export const TeamProfileMembersCard: FunctionalComponent<Props> = ({
  profile,
}) => {
  const {data, loading} = useTeamMembers({
    params: {
      teamId: profile.team.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }
  console.log(data);

  return (
    <Card
      css={`
        padding: 0 0 var(--gap-6);
      `}
    >
      <Typography weight="bold" css-mb={4} css-p={[6, 6, 0]}>
        Members
      </Typography>
      <Stack gap={4} direction="column" alignItems="stretch">
        {data?.map((member) => (
          <Anchor
            variant="unstyled"
            href={ROUTES.PUBLIC_USER_PROFILE.replace(
              ':username',
              member.username,
            )}
            css={`
              align-items: center;
              display: inline-flex;
              gap: var(--gap-4);
              padding: var(--gap-2) var(--gap-6);
              &:hover {
                background-color: var(--bg-minimal);
              }
            `}
          >
            <PDAvatar user={member} />
            <Stack direction="column" gap={1}>
              <Typography weight="bold" variant="body1">
                {member.firstName} {member.lastName}
              </Typography>
              <Typography css-color="var(--fg-muted)">
                {capitalize(member.accountType)}
              </Typography>
            </Stack>
          </Anchor>
        ))}
      </Stack>
    </Card>
  );
};
