import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {
  Button,
  DropdownMenu,
  IconButton,
  Loading,
  Overlay,
  Stack,
  Typography,
  menuItemVariant,
} from 'pylon/ui';
import {GetUserPost} from '@shared/api-types';
import {PDAvatar} from '@/components/PDAvatar';
import {useUserPostComments} from '@/fetch';
import {useAppContext} from '@/lib/app-context';
import {timeAgo} from '@/lib/dayjs';
import {Post_CommentDeleteOverlay} from './_CommentDeleteOverlay';
import {Post_CommentForm} from './_CommentForm';

type Props = {
  opened: boolean;
  post: GetUserPost['response'];
  setOpened: (opened: boolean) => void;
};

export const Post_CommentsOverlay: FunctionalComponent<Props> = ({
  opened,
  post,
  setOpened,
}: Props) => {
  const {currentUser} = useAppContext();
  const [commentToDelete, setCommentToDelete] = useState<number | null>(null);
  const {data, loading, error} = useUserPostComments({
    params: {
      username: post.user.username,
      postId: post.id,
    },
  });

  const handleDeleteClick = async (commentId: number) => {
    setCommentToDelete(commentId);
  };

  return (
    <>
      <Overlay
        opened={opened}
        setOpened={setOpened}
        animation="opacity"
        cancelOnOutsideClick
        withBackdrop
        disableBodyScroll
        css={`
          background: var(--bg-overlay);
          bottom: 0;
          max-width: 100%;
          padding: var(--gap-6);
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          ${mediaQuery(
            'md>',
            css`
              width: 26rem;
            `,
          )}
        `}
      >
        <div
          css={`
            display: grid;
            grid-template-rows: 1fr max-content;
            gap: var(--gap-4);
            height: 100%;
          `}
        >
          <div
            css={`
              height: 100%;
              overflow-y: auto;
            `}
          >
            <Stack alignItems="flex-start" justifyContent="space-between">
              <Typography
                variant="h5"
                textTransform="uppercase"
                fontStyle="italic"
                css-mb={6}
              >
                Comments
              </Typography>
              <IconButton icon="close" onClick={() => setOpened(false)} />
            </Stack>
            {/* {loading && <Loading centered />} */}
            <Stack direction="column" gap={7}>
              {data?.map((comment) => {
                return (
                  <Stack
                    gap={3}
                    key={comment.id}
                    alignItems="flex-start"
                    css={`
                      width: 100%;

                      & .manage-button {
                        opacity: 0;
                        transition: opacity 0.2s;
                      }
                      &:hover .manage-button {
                        opacity: 1;
                      }
                    `}
                  >
                    <PDAvatar user={comment.user} />
                    <Stack direction="column">
                      <Typography weight="medium" variant="body3">
                        {comment.user.firstName} {comment.user.lastName}
                      </Typography>
                      <Typography variant="body3" css-mt={1}>
                        {comment.content}
                      </Typography>
                      <Typography
                        variant="body5"
                        css-color="var(--fg-muted)"
                        css-mt={0.5}
                      >
                        {timeAgo(comment.createdAt)}
                      </Typography>
                    </Stack>
                    {currentUser && comment.userId === currentUser.id && (
                      <DropdownMenu
                        triggerCss={css`
                          margin-left: auto;
                        `}
                      >
                        <IconButton
                          icon="ellipsis-horizontal"
                          buttonSize="md"
                          className="manage-button"
                        />

                        <Button
                          customVariant={menuItemVariant}
                          onClick={() => handleDeleteClick(comment.id)}
                        >
                          Delete
                        </Button>
                      </DropdownMenu>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </div>
          <Post_CommentForm post={post} />
        </div>
      </Overlay>
      {commentToDelete && (
        <Post_CommentDeleteOverlay
          commentId={commentToDelete}
          opened
          post={post}
          setOpened={() => {
            setCommentToDelete(null);
          }}
        />
      )}
    </>
  );
};
