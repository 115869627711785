import {FunctionalComponent} from 'preact';
import {Anchor, Stack, Typography} from 'pylon/ui';
import {TeamPreview} from '@shared/common-types';
import {usStates} from '@shared/us-states';
import {ROUTES} from '@/lib/app-routes';
import {PDAvatar} from '../PDAvatar';

type Props = {
  team: TeamPreview;
};

export const TeamCard: FunctionalComponent<Props> = ({team, ...props}) => {
  return (
    <Anchor
      variant="unstyled"
      href={ROUTES.PUBLIC_TEAM_PROFILE.replace(':slug?', team.slug)}
      css={`
        align-items: center;
        border-radius: var(--radius-3);
        border: 1px solid var(--border-muted);
        display: inline-flex;
        gap: var(--gap-4);
        padding: var(--gap-4);
        &:hover {
          background-color: var(--bg-minimal);
        }
      `}
      {...props}
    >
      <PDAvatar team={team} size="xl" />
      <Stack direction="column" gap={1}>
        <Typography weight="bold" variant="body1">
          {team.name}
        </Typography>
        <Typography css-color="var(--fg-muted)">
          {(!!team.state && usStates[team.state]) ?? team.state}
        </Typography>
        <Stack gap={1}>
          <div
            css={`
              background: var(--yellow10);
              color: var(--yellow80);
              border-radius: var(--radius-4);
              font-size: var(--font-size-body-4);
              margin-top: var(--gap-1);
              padding: 0 var(--gap-2);
              text-transform: uppercase;
            `}
          >
            Team
          </div>
        </Stack>
      </Stack>
    </Anchor>
  );
};
