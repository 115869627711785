import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Controller, useForm} from 'react-hook-form';
import {
  Form,
  FormSubmit,
  FormTextarea,
  Overlay,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {CreateMePostComment, GetUserPost} from '@shared/api-types';
import {ErrorCode} from '@shared/error-code';
import {validatePostCommentContent} from '@shared/validators';
import {useCreateMePostComment} from '@/fetch';
import {handleFormValidate} from '@/lib/error-messages';

type Props = {
  post: GetUserPost['response'];
};

type FormData = CreateMePostComment['request']['body'];

export const Post_CommentForm: FunctionalComponent<Props> = ({post}: Props) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [createMePostComment] = useCreateMePostComment();
  const [completed, setCompleted] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<FormData>({});

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await createMePostComment({
        params: {
          postId: post.id,
        },
        body: {
          content: formData?.content ?? '',
        },
        updateCacheParams: {
          username: post.user.username,
        }
      });
      setCompleted(true);
    } catch (error: any) {
      setNetworkError(error?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmiting(false);
  };

  if (completed) {
    return <Typography>Comment published!</Typography>;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {networkError && (
        <ToastMessage severity="error" css-mb={5}>
          {ErrorCode.SOMETHING_WENT_WRONG}
        </ToastMessage>
      )}
      <Controller
        control={control}
        name="content"
        rules={{
          validate: (value) =>
            handleFormValidate(validatePostCommentContent(value ?? '')),
        }}
        render={({field: {onChange, onBlur, value}}) => (
          <FormTextarea
            id="content"
            defaultValue={value ?? ''}
            onChange={onChange}
            onBlur={onBlur}
            invalid={!!errors.content}
            invalidText={errors?.content?.message}
            fullWidth
          />
        )}
      />
      <FormSubmit submitting={submiting} disabled={submiting} css-mt={4}>
        Publish
      </FormSubmit>
    </Form>
  );
};
