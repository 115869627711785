import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Button} from 'pylon/ui';
import {GetUserProfile} from '@shared/api-types';
import {canBeFollowed, canFollow} from '@shared/permissions';
import {useCreateMeUserFollow, useDeleteMeUserFollow} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';

type Props = {
  profile: GetUserProfile['response'];
};

export const FollowUserButton: FunctionalComponent<Props> = ({profile}) => {
  const {currentUser} = useAuthenticatedContext();
  const [submitting, setSubmitting] = useState(false);
  const {isFollowing, user} = profile!;
  const [createMeUserFollow] = useCreateMeUserFollow();
  const [deleteMeUserFollow] = useDeleteMeUserFollow();

  const handleFollow = async () => {
    setSubmitting(true);
    try {
      if (isFollowing) {
        await deleteMeUserFollow({
          params: {
            userId: user.id,
          },
          updateCacheParams: {
            username: user.username,
          },
        });
      } else {
        await createMeUserFollow({
          params: {
            userId: user.id,
          },
          updateCacheParams: {
            username: user.username,
          },
        });
      }
    } catch (err) {
      console.warn(err);
    }
    setSubmitting(false);
  };

  if (currentUser.id === profile.user.id) {
    return null;
  }

  if (
    canFollow(currentUser.accountType) &&
    canBeFollowed(profile.user.accountType)
  ) {
    return (
      <Button
        variant={isFollowing ? 'outline' : 'primary'}
        onClick={handleFollow}
        disabled={submitting}
        {...(!isFollowing && {iconLeading: 'plus'})}
      >
        <span>{isFollowing ? 'Unfollow' : 'Follow'}</span>
      </Button>
    );
  }

  return null;
};
