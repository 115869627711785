import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Button} from 'pylon/ui';
import {GetUserProfile} from '@shared/api-types';
import {canAddToPlanner, canBeAddedToPlanner} from '@shared/permissions';
import {useCreateMeProspect, useDeleteMeProspect} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ProspectButton_CreateOverlay} from './_CreateOverlay';

type Props = {
  profile: GetUserProfile['response'];
};

export const ProspectButton: FunctionalComponent<Props> = ({profile}) => {
  const {currentUser} = useAuthenticatedContext();
  const [submitting, setSubmitting] = useState(false);
  const {isProspect, user} = profile!;
  const [deleteMeProspect] = useDeleteMeProspect();
  const [displayCreateOverlay, setDisplayCreateOverlay] = useState(false);

  const handleFollow = async () => {
    setSubmitting(true);
    try {
      if (isProspect) {
        await deleteMeProspect({
          body: {
            userId: user.id,
          },
          updateCacheParams: {
            username: user.username,
          },
        });
      } else {
        setDisplayCreateOverlay(true);
      }
    } catch (err) {
      console.warn(err);
    }
    setSubmitting(false);
  };

  if (currentUser.id === profile.user.id) {
    return null;
  }

  if (
    canAddToPlanner(currentUser.accountType) &&
    canBeAddedToPlanner(profile.user.accountType)
  ) {
    return (
      <>
        <Button
          variant={isProspect ? 'outline' : 'primary'}
          onClick={handleFollow}
          disabled={submitting}
          {...(!isProspect && {iconLeading: 'plus'})}
        >
          <span>{isProspect ? 'Remove from planner' : 'Add to planner'}</span>
        </Button>
        <ProspectButton_CreateOverlay
          profile={profile}
          opened={displayCreateOverlay}
          setOpened={setDisplayCreateOverlay}
        />
      </>
    );
  }

  return null;
};
