import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import styled, {keyframes} from 'styled-components';
import {afterNextRender} from 'pylon/lib';
import {Loading, Redirect, Responsive, Stack} from 'pylon/ui';
import {AccountType} from '@shared/database-types';
import {useUsersProfile} from '@/fetch';
import {AppContextType, useAppContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {GetStartedProcess_StepAccount} from './(steps)/_StepAccount';
import {GetStartedProcess_StepAllDone} from './(steps)/_StepAllDone';
import {GetStartedProcess_StepPlayerDetails} from './(steps)/_StepPlayerDetails';
import {GetStartedProcess_StepPlayerSkills} from './(steps)/_StepPlayerSkills';

type Props = {};

const steps = [
  {
    component: GetStartedProcess_StepAccount,
    condition: () => true,
  },
  {
    component: GetStartedProcess_StepPlayerDetails,
    condition: (currentUser: AppContextType['currentUser']) =>
      currentUser?.accountType === AccountType.PLAYER,
  },
  {
    component: GetStartedProcess_StepPlayerSkills,
    condition: (currentUser: AppContextType['currentUser']) =>
      currentUser?.accountType === AccountType.PLAYER,
  },
  {
    component: GetStartedProcess_StepAllDone,
    condition: () => true,
  },
];

export const GetStartedProcess: FunctionalComponent<Props> = () => {
  const {currentUser} = useAppContext();
  const [currentStep, setCurrentStep] = useState(!!currentUser ? 1 : 0);
  const {data: currentUserProfile, loading} = useUsersProfile(
    {
      params: {
        username: currentUser?.username ?? '',
      },
    },
    {
      skip: !currentUser,
    },
  );

  const onNext = () => {
    // don't do steps.length - 1, because we want to redirect when !StepComponent after final step
    setCurrentStep((prev) => Math.min(steps.length, prev + 1));
  };

  const onBack = () => {
    setCurrentStep((prev) => {
      return Math.max(1, prev - 1);
    });
  };

  const renderStep = () => {
    const StepComponent = getStepsForAccountType(currentUser).find(
      (step, index) => index === currentStep && step.condition(currentUser),
    )?.component;

    if (!StepComponent) {
      // Fallback or redirect if the step is not configured or condition is not met
      return (
        <Redirect
          to={ROUTES.PUBLIC_USER_PROFILE.replace(
            ':username',
            currentUser?.username ?? '',
          )}
        />
      );
    }

    return (
      <StepComponent
        onNext={onNext}
        onSkip={onNext}
        onBack={onBack}
        profile={currentUserProfile}
      />
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  if (loading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        css={`
          height: 100%;
        `}
      >
        <Loading centered />
      </Stack>
    );
  }

  return (
    <AnimatedStepContainer key={currentStep}>
      {renderStep()}
    </AnimatedStepContainer>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const AnimatedStepContainer = styled.div`
  flex: 1;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const getStepsForAccountType = (currentUser: AppContextType['currentUser']) => {
  return steps.filter((step) => step.condition(currentUser));
};
