import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Anchor, Button, IconButton, Stack} from 'pylon/ui';
import pdLogo from '@/assets/pd-logo.png';
import {useAppContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {SearchInput} from '../(search)/SearchInput';
import {AppToolbar_AuthedControls} from './_AuthedControls';

type Props = {
  openedDrawer: boolean;
  setOpenedDrawer: (value: boolean) => void;
};

export const AppToolbar: FunctionalComponent<Props> = ({
  setOpenedDrawer,
  openedDrawer,
  ...props
}) => {
  const {currentUser} = useAppContext();

  return (
    <div
      css={`
        align-items: center;
        background: var(--bg-default);
        display: flex;
        height: 4.25rem;
        padding: 0 var(--gap-6);
        position: sticky;
        top: 0;
        z-index: 10;
        ${mediaQuery(
          'md>',
          css`
            display: grid;
            grid-template-columns: 0.5fr 1fr 0.5fr;
          `,
        )}
      `}
      {...props}
    >
      {/* left stack */}
      <Stack alignItems="center" gap={2}>
        <IconButton
          icon="menu"
          onClick={() => setOpenedDrawer(!openedDrawer)}
          css={`
            ${mediaQuery(
              'md>',
              css`
                display: none;
              `,
            )}
          `}
        />
        <Anchor
          variant="unstyled"
          href={ROUTES.PUBLIC_HOME}
          css={`
            display: inline-flex;
          `}
        >
          <img
            src={pdLogo}
            css={`
              height: 36px;
              width: auto;
              ${mediaQuery(
                '<md',
                css`
                  height: 32px;
                  margin-left: var(--gap-2);
                `,
              )}
            `}
          />
          <div
            css={`
              background: var(--fg-default);
              color: var(--bg-default);
              border-radius: var(--radius-4);
              display: flex;
              font-size: var(--font-size-body-5);
              font-weight: 500;
              margin-bottom: auto;
              margin-left: var(--gap-3);
              padding: var(--gap-0-5) var(--gap-2);
            `}
          >
            Beta
          </div>
        </Anchor>
      </Stack>
      {/* center stack */}
      <SearchInput
        containerCss={css`
          margin: 0 auto;
          max-width: 35rem;
          width: 100%;
          ${mediaQuery(
            '<md',
            css`
              display: none;
            `,
          )}
        `}
      />

      {/* right stack */}
      <Stack alignItems="center" css-ml="auto">
        <IconButton
          href={ROUTES.PUBLIC_EMPTY_SEARCH_ROUTE}
          icon="search"
          variant="muted"
          css-mr={3}
          css={`
            ${mediaQuery(
              'md>',
              css`
                display: none;
              `,
            )}
          `}
        />
        {currentUser ? (
          <AppToolbar_AuthedControls css-ml="auto" />
        ) : (
          <Stack alignItems="center" gap={4} css-ml="auto">
            <Button href={ROUTES.GUEST_SIGN_IN}>Sign in</Button>
            <Button
              variant="primary"
              href={ROUTES.GUEST_SIGN_UP}
              css={`
                ${mediaQuery(
                  '<md',
                  css`
                    display: none;
                  `,
                )}
              `}
            >
              Sign up
            </Button>
          </Stack>
        )}
      </Stack>
    </div>
  );
};
