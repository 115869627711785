import {FunctionalComponent} from 'preact';
import {
  Anchor,
  Card,
  DescriptionList,
  DescriptionListItem,
  Typography,
} from 'pylon/ui';
import {GetUserProfile} from '@shared/api-types';
import {beautifyPlayerPosition, capitalize} from '@/lib/utils';
import {searchFiltersToUrl} from '../(search)/search-filters-to-url';

type Props = {
  profile: GetUserProfile['response'];
};

export const UserProfilePlayerProfileCard: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  return (
    <Card {...props}>
      <Typography weight="bold" css-mb={4}>
        Player profile
      </Typography>
      <DescriptionList expandWidthSize={6}>
        <DescriptionListItem title="Most recent team" cols="35% 1fr">
          {capitalize(profile.mostRecentTeam ?? '-')}
        </DescriptionListItem>
        <DescriptionListItem title="Playing level" cols="35% 1fr">
          {profile.level ? (
            <Anchor
              href={searchFiltersToUrl({level: [profile.level]})}
              variant="primary"
            >
              {capitalize(profile.level)}
            </Anchor>
          ) : (
            '-'
          )}
        </DescriptionListItem>
        <DescriptionListItem title="HS graduation year" cols="35% 1fr">
          {profile.gradYear ? (
            <Anchor
              href={searchFiltersToUrl({gradYear: [profile.gradYear]})}
              variant="primary"
            >
              {profile.gradYear}
            </Anchor>
          ) : (
            '-'
          )}
        </DescriptionListItem>
        <DescriptionListItem title="Throwing hand" cols="35% 1fr">
          {profile.throwingHand ? (
            <Anchor
              href={searchFiltersToUrl({throwingHand: [profile.throwingHand]})}
              variant="primary"
            >
              {capitalize(profile.throwingHand)}
            </Anchor>
          ) : (
            '-'
          )}
        </DescriptionListItem>
        <DescriptionListItem title="Batting hand" cols="35% 1fr">
          {profile.battingHand ? (
            <Anchor
              href={searchFiltersToUrl({battingHand: [profile.battingHand]})}
              variant="primary"
            >
              {capitalize(profile.battingHand)}
            </Anchor>
          ) : (
            '-'
          )}
        </DescriptionListItem>
        <DescriptionListItem title="Primary position" cols="35% 1fr">
          {profile.position1 ? (
            <Anchor
              href={searchFiltersToUrl({position1: [profile.position1]})}
              variant="primary"
            >
              {beautifyPlayerPosition(profile.position1)}
            </Anchor>
          ) : (
            '-'
          )}
        </DescriptionListItem>
        <DescriptionListItem title="Secondary position" cols="35% 1fr">
          {beautifyPlayerPosition(profile.position2 ?? '-')}
        </DescriptionListItem>
        <DescriptionListItem title="State" cols="35% 1fr">
          {profile.state ? (
            <Anchor
              href={searchFiltersToUrl({state: [profile.state]})}
              variant="primary"
            >
              {capitalize(profile.state)}
            </Anchor>
          ) : (
            '-'
          )}
        </DescriptionListItem>
        <DescriptionListItem title="City" cols="35% 1fr">
          {capitalize(profile.city ?? '-')}
        </DescriptionListItem>
      </DescriptionList>
    </Card>
  );
};
