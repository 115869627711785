import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Anchor, Card, EmptyMessage, Loading, Stack, Typography} from 'pylon/ui';
import {useUserAlbums} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';
import {timeAgo} from '@/lib/dayjs';

type Props = {
  params: {
    username: string;
  };
  userId: string;
};

export const PublicUserProfileAlbumsRoute: FunctionalComponent<Props> = ({
  params: {username},
  userId,
}) => {
  const {data, loading} = useUserAlbums({
    params: {
      userId: userId,
    },
  });

  if (loading) {
    return <Loading centered />;
  } else if (!data.length) {
    return (
      <EmptyMessage
        css={`
          flex: 1;
        `}
      >
        No albums found
      </EmptyMessage>
    );
  } else {
    return (
      <div
        css={`
          display: grid;
          gap: var(--gap-6);
          ${mediaQuery(
            'xl>',
            css`
              grid-template-columns: repeat(2, 1fr);
            `,
          )}
          ${mediaQuery(
            'xxl>',
            css`
              grid-template-columns: repeat(3, 1fr);
            `,
          )}
        `}
      >
        {data.map((album) => (
          <Anchor
            key={album.id}
            href={ROUTES.PUBLIC_USER_PROFILE_ALBUM.replace(
              ':username',
              username,
            ).replace(':albumId', String(album.id))}
            css={`
              width: 100%;
            `}
          >
            <Card>
              <Stack direction="column">
                <Typography
                  variant="h5"
                  weight="bold"
                  fontStyle="italic"
                  textTransform="uppercase"
                >
                  {album.name}
                </Typography>
                <Typography css-color="var(--fg-muted)">
                  {album.postsCount} highlights • Updated{' '}
                  {timeAgo(album.updatedAt || album.createdAt)}
                </Typography>
              </Stack>
            </Card>
          </Anchor>
        ))}
      </div>
    );
  }
};
