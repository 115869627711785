import {FunctionalComponent} from 'preact';
import {useLocation} from 'preact-iso';
import {useState} from 'preact/hooks';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth';
import {useForm} from 'react-hook-form';
import {
  Anchor,
  Form,
  FormCheckbox,
  FormInput,
  FormSubmit,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {ErrorCode} from '@shared/error-code';
import {validateEmail, validatePassword} from '@shared/validators';
import {AuthLayout} from '@/components/AuthLayout';
import {ROUTES} from '@/lib/app-routes';
import {AFTER_LOGIN_REDIRECT} from '@/lib/auth';
import {handleFormValidate} from '@/lib/error-messages';

type FormData = {
  email: string;
  password: string;
  agreed: boolean;
};

type Props = {};

export const GuestSignUpRoute: FunctionalComponent<Props> = () => {
  const location = useLocation();
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useState<string | null>(null);
  const {
    formState: {errors},
    handleSubmit,
    register,
  } = useForm<FormData>({
    defaultValues: {
      agreed: false,
    },
  });

  const onSubmit = async (formData: FormData) => {
    if (!formData.agreed) return;
    setSubmiting(true);
    const auth = getAuth();
    try {
      const resp = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password,
      );
      const createdUser = resp.user;
      await sendEmailVerification(createdUser);
      location.route(AFTER_LOGIN_REDIRECT);
    } catch (err: any) {
      setNetworkError(err?.message || ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmiting(false);
  };

  return (
    <AuthLayout title="Sign up to Prospect Dugout">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        <Stack direction="column" gap={8}>
          <FormInput
            {...register('email', {
              validate: (value) => handleFormValidate(validateEmail(value)),
            })}
            id="email"
            label="Email"
            type="email"
            size="lg"
            invalid={!!errors.email}
            invalidText={errors?.email?.message}
            fullWidth
          />
          <FormInput
            {...register('password', {
              validate: (value) => handleFormValidate(validatePassword(value)),
            })}
            id="password"
            size="lg"
            label="Password"
            type="password"
            placeholder="6+ characters"
            invalid={!!errors.password}
            invalidText={errors?.password?.message}
            fullWidth
          />
          <FormCheckbox
            id="agree"
            {...register('agreed', {
              validate: (value) =>
                handleFormValidate(value || ErrorCode.MUST_AGREE_TO_TERMS),
            })}
            invalid={!!errors.agreed}
            invalidText={errors?.agreed?.message}
            label={
              (
                <>
                  I agree with Prospect Dugout's{' '}
                  <Anchor
                    href={ROUTES.PUBLIC_TERMS}
                    variant="underline"
                    target="_blank"
                  >
                    Terms of Service
                  </Anchor>{' '}
                  and{' '}
                  <Anchor
                    href={ROUTES.PUBLIC_PRIVACY}
                    variant="underline"
                    target="_blank"
                  >
                    Privacy Policy
                  </Anchor>
                </>
              ) as any
            }
          />
          <FormSubmit
            variant="primary"
            value="Sign up"
            submitting={submiting}
            disabled={submiting}
            buttonSize="lg"
            fullWidth
          />
        </Stack>
      </Form>
      <Typography variant="body3" css-textAlign="center" css-mt={4}>
        Already have an account?{' '}
        <Anchor variant="primary" href={ROUTES.GUEST_SIGN_IN}>
          Sign in
        </Anchor>
      </Typography>
    </AuthLayout>
  );
};
