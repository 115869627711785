import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Loading, Stack, ToastMessage, Typography} from 'pylon/ui';
import {ErrorCode} from '@shared/error-code';
import {UserCard} from '@/components/UserCard/UserCard';
import {useDiscoverRecentTeams} from '@/fetch';
import {TeamCard} from '../TeamCard';

type Props = {};

export const RecentTeams: FunctionalComponent<Props> = ({...props}) => {
  const {data, error, loading} = useDiscoverRecentTeams({
    params: {offset: 0, limit: 10},
  });

  let content = null;

  if (loading) {
    content = (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  } else {
    const {totalCount, rows} = data;
    if (totalCount === 0) {
      content = (
        <Stack
          direction="column"
          alignItems="center"
          gap={1}
          css-pt={4}
          css-pb={4}
        >
          <Typography weight="medium">
            No teams to show at the moment
          </Typography>
        </Stack>
      );
    } else {
      content = (
        <>
          <div
            css={`
              display: flex;
              flex-direction: column;
              gap: var(--gap-4);
              ${mediaQuery(
                'md>',
                css`
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
                `,
              )}
            `}
          >
            {rows.map((team) => {
              return <TeamCard team={team} />;
            })}
          </div>
        </>
      );
    }
  }

  return (
    <div {...props}>
      <Typography
        variant="h4"
        textTransform="uppercase"
        fontStyle="italic"
        css-mb={6}
      >
        New teams
      </Typography>
      {content}
    </div>
  );
};
