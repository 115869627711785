import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Button, DropdownMenu, IconButton, menuItemVariant} from 'pylon/ui';
import {Event} from '@shared/database-types';
import {EventDeleteOverlay} from './EventDeleteOverlay';
import {EventForm} from './EventForm';

type Props = {
  event: Event;
};

export const EventManageButton: FunctionalComponent<Props> = ({event}) => {
  const [editOpened, setEditOpened] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);

  const handleEditClick = () => {
    setEditOpened(true);
  };

  const handleDeleteClick = () => {
    setDeleteOpened(true);
  };

  return (
    <>
      <DropdownMenu>
        <IconButton
          icon="ellipsis-horizontal"
          buttonSize="md"
          className="event-manage-button"
        />
        <Button customVariant={menuItemVariant} onClick={handleEditClick}>
          Edit
        </Button>
        <Button customVariant={menuItemVariant} onClick={handleDeleteClick}>
          Delete
        </Button>
      </DropdownMenu>
      {editOpened && (
        <EventForm
          opened={editOpened}
          setOpened={setEditOpened}
          defaultEvent={event}
        />
      )}
      {deleteOpened && (
        <EventDeleteOverlay
          opened={deleteOpened}
          setOpened={setDeleteOpened}
          eventId={event.id}
        />
      )}
    </>
  );
};
