import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {
  Button,
  Form,
  FormInput,
  FormSubmit,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {ProspectTag} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {useCreateMeProspect} from '@/fetch';
import {useNetworkError} from '@/lib/use-network-error';
import {GetUserProfile} from '../../../../api/functions/src/controllers/user-profile/types';

type Props = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  profile: GetUserProfile['response'];
};

type FormData = {
  tags: string;
};

export const ProspectButton_CreateOverlay: FunctionalComponent<Props> = ({
  opened,
  setOpened,
  profile,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const [createMeProspect] = useCreateMeProspect();
  const {isProspect, user} = profile!;

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      tags: '',
    },
  });

  const onSubmit = async (formData: FormData) => {
    setSubmiting(true);
    setNetworkError(null);
    try {
      await createMeProspect({
        body: {
          userId: user.id,
          tags: formData.tags.split(',').map((tag) => tag.trim()),
        },
        updateCacheParams: {
          username: user.username,
        },
      });
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
    setOpened(false);
  };

  const handleCancel = () => {
    setOpened(false);
  };

  return (
    <Overlay
      opened={opened && !isProspect}
      setOpened={setOpened}
      closeOthers
      cancelOnEscKey
      cancelOnOutsideClick
      animation="scale-in"
      withBackdrop
      position={{
        mode: 'centered',
      }}
      css={`
        ${overlayPopupCss}
      `}
    >
      <Typography
        variant="h5"
        textTransform="uppercase"
        fontStyle="italic"
        css-mb={5}
      >
        Add to planner
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        <FormInput
          {...register('tags')}
          id="tags"
          label="Tags"
          invalid={!!errors.tags}
          invalidText={errors?.tags?.message}
          helpText="Separate tags with commas"
          fullWidth
        />
        <Stack gap={4} css-mt={6}>
          <FormSubmit submitting={submiting} disabled={submiting}>
            Submit
          </FormSubmit>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Form>
    </Overlay>
  );
};
