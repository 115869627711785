export function withStrLength(
  value: string | null,
  min: number,
  max: number,
): boolean {
  if (min === 0 && (value === null || typeof value === 'undefined')) {
    return true; // empty str can be null
  }
  return (
    typeof value === 'string' && value.length >= min && value.length <= max
  );
}

export function withRegex(value: string | null, regex: RegExp) {
  return typeof value === 'string' && regex.test(value);
}

export function inSet<T>(value: T | null, validSet: T[]): boolean {
  if (value === null) return false;
  return validSet.includes(value);
}

export function inMap(
  value: string | null,
  map: {[key: string]: string[]},
): boolean {
  if (value === null) return false;
  const allValues = Object.values(map).flat();
  return allValues.includes(value);
}

export function nonEmpty(value: any): boolean {
  return value !== null && value !== undefined && value !== '';
}

export function withValidUrl(value: string | null) {
  const pattern =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return withRegex(value, pattern);
}

export function withValidSlug(value: string | null) {
  const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  return withRegex(value, pattern);
}

export function withMinValue(value: string | null, min: number): boolean {
  if (value === null) return false;
  const num = parseInt(value, 10);
  return !isNaN(num) && num >= min;
}

export function isValidDate(value: string | null) {
  const pattern = /^\d{4}-\d{2}-\d{2}$/;
  return value !== null && pattern.test(value);
}

export function isNumeric(value: string | null) {
  return value !== null && /^\d+$/.test(value);
}

export function isValidEmail(value: string | null) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return withRegex(value, pattern);
}

export function isValidSlug(value: string | null) {
  const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  return withRegex(value, pattern);
}

export function isValidUrl(value: string | null) {
  const pattern =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return withRegex(value, pattern);
}
