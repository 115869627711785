import {FunctionalComponent} from 'preact';
import {Loading} from 'pylon/ui';
import {PostGrid} from '@/components/(post)/PostGrid';
import {useUserPosts} from '@/fetch';

type Props = {
  params: {
    username: string;
  };
  userId: string;
};

export const PublicUserProfilePostsRoute: FunctionalComponent<Props> = ({
  userId,
}) => {
  const {data: posts, loading} = useUserPosts({
    params: {
      userId: userId,
    },
  });

  if (loading) {
    return <Loading centered />;
  } else {
    return <PostGrid posts={posts} />;
  }
};
