import {FunctionalComponent} from 'preact';
import {Card, Typography} from 'pylon/ui';
import {GetTeamProfile, GetUserProfile} from '@shared/api-types';

type Props = {
  profile: GetUserProfile['response'] | GetTeamProfile['response'];
};

export const ProfileBioCard: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  if (!profile.bio) {
    return null;
  }
  return (
    <Card {...props}>
      <Typography weight="bold" css-mb={4}>
        Biography
      </Typography>
      <Typography>{profile.bio ?? '-'}</Typography>
    </Card>
  );
};
