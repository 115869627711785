import {FunctionalComponent} from 'preact';
import {Avatar, type AvatarProps} from 'pylon/ui';
import {Team, User} from '@shared/database-types';
import {toMediaPath} from '@/lib/environment';

type Props = AvatarProps & {
  team?: Partial<Team> | null;
  user?: Partial<User> | null;
};

export const PDAvatar: FunctionalComponent<Props> = ({
  team,
  user,
  ...props
}) => {
  const username = team?.name || user?.firstName || '';
  const avatarLarge = team?.avatarLarge || user?.avatarLarge;
  const avatarSmall = team?.avatarSmall || user?.avatarSmall;

  return (
    <Avatar
      username={username}
      {...((avatarLarge || avatarSmall) && {
        src: toMediaPath(
          props.size === 'lg' || props.size === 'xl'
            ? avatarLarge
            : avatarSmall,
        ),
      })}
      {...props}
    />
  );
};
