import {FunctionalComponent} from 'preact';
import {TableColumn} from 'node_modules/pylon/dist/ui/Table/types';
import {Button, Stack, Table, Typography} from 'pylon/ui';
import {GetMe} from '@shared/api-types';
import {useAuthenticatedContext} from '@/lib/app-context';
import {capitalize} from '@/lib/utils';

type Props = {
  teams: NonNullable<GetMe['response']>['teams'];
};

export const UserSettingsTeamsTable: FunctionalComponent<Props> = ({teams}) => {
  const {setCurrentTeam} = useAuthenticatedContext();

  const handleLogInAsTeam = (teamId: number) => {
    setCurrentTeam(String(teamId));
  };

  const columns: TableColumn<Props['teams'][0]>[] = [
    {
      accessor: 'name',
      title: 'Name',
    },
    {
      accessor: 'role',
      title: 'Role',
      renderContent: ({d}) => <span>{capitalize(d.role)}</span>,
    },
    {
      accessor: '',
      renderContent: ({d}) => (
        <Stack>
          <Button variant="default" onClick={() => handleLogInAsTeam(d.id)}>
            Log in as team
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Typography css-mb={4}>
        Here you can view all the teams you are a part of. To interact and
        manage specific team activities, use the 'Log In as Team' button. This
      </Typography>
      <Table columns={columns} data={teams} />
    </>
  );
};
