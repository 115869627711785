import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Anchor, Button, Grid, IconButton, Stack, Typography} from 'pylon/ui';
import {GetUserPost} from '@shared/api-types';
import {PDAvatar} from '@/components/PDAvatar';
import {PDIcon} from '@/components/PDIcon';
import {SuperUserButton} from '@/components/SuperUserButton';
import {useAppContext} from '@/lib/app-context';
import {timeAgo} from '@/lib/dayjs';
import {ROUTES} from '../../../lib/app-routes';
import {Post_CommentsOverlay} from './_CommentsOverlay';
import {Post_Video} from './_Video';

type Props = {
  post: GetUserPost['response'];
  onClose?: () => void;
  viewport: {width: number; height: number};
};

export const Post: FunctionalComponent<Props> = ({post, onClose, viewport}) => {
  const {currentUser} = useAppContext();
  const [commentsOpened, setCommentsOpened] = useState(false);
  const HEADER_HEIGHT = 70;
  const adjustedViewport = {
    width: viewport.width,
    height: viewport.height - HEADER_HEIGHT,
  };

  const onClickListener = !!onClose ? {onClick: onClose} : {};

  return (
    <div
      css={`
        display: grid;
        grid-template-rows: ${HEADER_HEIGHT}px 1fr;
        height: 100%;
      `}
    >
      <Grid
        cols={`max-content 1fr auto`}
        alignItems="center"
        gap={3}
        css={`
          min-height: 0;
          padding: var(--gap-4) var(--gap-6);
        `}
      >
        <Anchor
          variant="unstyled"
          href={ROUTES.PUBLIC_USER_PROFILE.replace(
            ':username',
            post.user.username,
          )}
          {...onClickListener}
        >
          <PDAvatar user={post.user} />
        </Anchor>
        <Stack direction="column">
          <Stack gap={2} alignItems="baseline">
            <Anchor
              href={ROUTES.PUBLIC_USER_PROFILE.replace(
                ':username',
                post.user.username,
              )}
              css={`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
              `}
              {...onClickListener}
            >
              <Typography weight="bold">
                {post.user.firstName} {post.user.lastName}
              </Typography>
            </Anchor>
            <Typography variant="body4" css-color="var(--fg-muted)">
              {timeAgo(post.createdAt)}
            </Typography>
          </Stack>
          <Typography>{post.content}</Typography>
        </Stack>
        <Stack alignItems="center" gap={2}>
          {currentUser?.isSuperUser && <SuperUserButton postId={post.id} />}
          <Button
            variant="unstyled"
            iconLeading={<PDIcon icon="chat" />}
            onClick={() => setCommentsOpened(true)}
            css={`
              font-weight: var(--font-weight-bold);
              padding: var(--gap-2);
            `}
          >
            {post.commentsCount}
          </Button>
          {!!onClose && (
            <IconButton icon="close" buttonSize="lg" {...onClickListener} />
          )}
        </Stack>
      </Grid>
      {!!post.video && <Post_Video post={post} viewport={adjustedViewport} />}
      {commentsOpened && (
        <Post_CommentsOverlay
          post={post}
          opened={commentsOpened}
          setOpened={setCommentsOpened}
        />
      )}
    </div>
  );
};
