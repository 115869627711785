import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {sendEmailVerification} from 'firebase/auth';
import {css} from 'styled-components';
import {FormSubmit, ToastMessage} from 'pylon/ui';
import {useAppContext} from '@/lib/app-context';

type Props = {};

export const NotVerifiedEmailToast: FunctionalComponent<Props> = () => {
  const {currentFirebaseUser} = useAppContext();
  const [submiting, setSubmiting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleResendVerificationEmail = async () => {
    if (currentFirebaseUser) {
      setSubmiting(true);
      await sendEmailVerification(currentFirebaseUser);
      setCompleted(true);
      setSubmiting(false);
    }
  };
  return (
    <ToastMessage
      severity="info"
      contentCss={css`
        gap: var(--gap-3);
      `}
    >
      {completed ? (
        <>Verification email sent! Please check your inbox. </>
      ) : (
        <>
          Please verify your email to access all features.{' '}
          <FormSubmit
            variant="primary"
            disabled={submiting}
            submitting={submiting}
            onClick={handleResendVerificationEmail}
          >
            Resend verification email
          </FormSubmit>
        </>
      )}
    </ToastMessage>
  );
};
