import {FunctionalComponent} from 'preact';
import {Loading, ToastMessage} from 'pylon/ui';
import {UserSettingsEmailNotificationsForm} from '@/components/(user-settings)/UserSettingsEmailNotificationsForm';
import {useMeEmailNotificationSettings} from '@/fetch';

type Props = {};

export const UserSettingsEmailNotificationsRoute: FunctionalComponent<
  Props
> = () => {
  const {data, loading} = useMeEmailNotificationSettings();

  if (loading) {
    return <Loading centered />;
  } else if (data) {
    return <UserSettingsEmailNotificationsForm data={data} />;
  } else {
    return <ToastMessage>Something went wrong.</ToastMessage>;
  }
};
