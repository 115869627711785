import {useState} from 'preact/hooks';
import {ErrorCode} from '@shared/error-code';
import {errorMessages} from './error-messages';

interface KnownError {
  message: ErrorCode;
}

type Props = {
  defaultError?: ErrorCode;
};

export const useNetworkError = ({
  defaultError = ErrorCode.SOMETHING_WENT_WRONG,
}: Props = {}): [string | null, (error: unknown) => void] => {
  const [networkError, setNetworkError] = useState<string | null>(null);

  const _setNetworkError = (error: unknown | null) => {
    if (error === null) {
      setNetworkError(null);
    } else {
      const errorCode: ErrorCode =
        (error as KnownError)?.message ?? defaultError;
      setNetworkError(errorMessages[errorCode]);
    }
  };

  return [networkError, _setNetworkError];
};
