import {ComponentChildren, FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Button, Icon, Stack, Typography} from 'pylon/ui';

type Props = {
  title: string;
  defaultOpened?: boolean;
  children: ComponentChildren;
};

export const SearchFilters_Collapsable: FunctionalComponent<Props> = ({
  title,
  defaultOpened = false,
  children,
}) => {
  const [opened, setOpened] = useState(defaultOpened);

  return (
    <Stack
      direction="column"
      css={`
        padding: var(--gap-0-5) 0;
      `}
    >
      <Button
        variant="unstyled"
        onClick={() => setOpened(!opened)}
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: inherit;
        `}
      >
        <Typography
          variant="h6"
          fontStyle="italic"
          textTransform="uppercase"
          css={`
            font-weight: var(--font-weight-extra-bold);
          `}
        >
          {title}
        </Typography>
        <Icon
          icon="chevron-down"
          css={`
            transform: rotate(${opened ? 0 : -90}deg);
            transition: transform 0.2s;
            & svg {
              width: 22px;
              height: 22px;
            }
          `}
        />
      </Button>
      {opened && (
        <div
          css={`
            padding-top: var(--gap-2);
          `}
        >
          {children}
        </div>
      )}
    </Stack>
  );
};
