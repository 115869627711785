import {ComponentChildren, FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Responsive} from 'pylon/ui';
import {useAppContext} from '@/lib/app-context';
import {NotOnboardedToast} from '../(toasts)/NotOnboardedToast';
import {NotVerifiedEmailToast} from '../(toasts)/NotVerifiedEmailToast';
import {AppDrawer} from '../AppDrawer';
import {AppToolbar} from '../AppToolbar';
import {PendingInviteOverlay} from '../PendingInviteOverlay';

type Props = {
  children?: ComponentChildren;
  noDrawer?: boolean;
};

export const AppLayout: FunctionalComponent<Props> = ({
  children,
  noDrawer = false,
}) => {
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const {currentUser, currentFirebaseUser} = useAppContext();
  const [displayPendingInvites, setDisplayPendingInvites] = useState(false);
  const displayUnverifiedEmail =
    currentFirebaseUser && !currentFirebaseUser.emailVerified;
  const displayUncompletedProfile =
    !displayUnverifiedEmail && currentUser && !currentUser.onboarded;

  useEffect(() => {
    setDisplayPendingInvites(
      !!currentUser && currentUser.pendingInvites.length > 0,
    );
  }, [currentUser]);

  return (
    <div
      css={`
        display: grid;
        grid-template-areas: 'toolbar' 'main';
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        height: 100%;
        min-height: 100vh;
        ${mediaQuery(
          'md>',
          css`
            grid-template-rows: auto 1fr;
            ${!noDrawer &&
            css`
              grid-template-areas: 'toolbar toolbar' 'drawer main';
              grid-template-columns: 232px 1fr;
            `}
          `,
        )}
      `}
    >
      <AppToolbar
        openedDrawer={openedDrawer}
        setOpenedDrawer={setOpenedDrawer}
        css={`
          grid-area: toolbar;
        `}
      />
      <AppDrawer
        openedDrawer={openedDrawer}
        setOpenedDrawer={setOpenedDrawer}
        css={`
          grid-area: drawer;
          ${noDrawer &&
          mediaQuery(
            'md>',
            css`
              display: none;
            `,
          )}
        `}
      />
      <div
        css={`
          grid-area: main;
          padding-top: var(--gap-2);
        `}
      >
        {displayUnverifiedEmail && (
          <Responsive>
            <NotVerifiedEmailToast />
          </Responsive>
        )}
        {displayUncompletedProfile && (
          <Responsive>
            <NotOnboardedToast />
          </Responsive>
        )}
        <div
          css={`
            padding: var(--gap-4) 0;
            ${mediaQuery(
              'md>',
              css`
                min-height: 100%;
                padding: var(--gap-10) 0;
              `,
            )}
          `}
        >
          {children}
        </div>
      </div>
      {displayPendingInvites && (
        <PendingInviteOverlay
          opened={displayPendingInvites}
          setOpened={setDisplayPendingInvites}
        />
      )}
    </div>
  );
};
