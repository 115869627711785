import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {
  Button,
  FormSubmit,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {Team, User} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {useDeleteMeTeamMember} from '@/fetch';
import {useNetworkError} from '@/lib/use-network-error';

type Props = {
  memberUserId: User['id'];
  opened: boolean;
  setOpened: (value: boolean) => void;
  teamId: Team['id'];
};

export const DeleteMemberOverlay: FunctionalComponent<Props> = ({
  opened,
  teamId,
  memberUserId,
  setOpened,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const [deleteMeTeamMember] = useDeleteMeTeamMember();

  const handleDeletePost = async () => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await deleteMeTeamMember({
        params: {
          userId: memberUserId,
          teamId,
        },
      });
      setOpened(false);
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
  };

  const handleCancel = () => {
    setOpened(false);
  };

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      position={{mode: 'centered'}}
      closeOthers
      cancelOnEscKey
      cancelOnOutsideClick
      withBackdrop
      animation="scale-in"
      css={`
        ${overlayPopupCss}
      `}
    >
      {networkError && (
        <ToastMessage severity="error" css-mb={5}>
          {networkError}
        </ToastMessage>
      )}
      <Typography css-mb={4}>
        Are you sure you want to remove this member from the team?
      </Typography>
      <Stack gap={4}>
        <FormSubmit
          variant="error"
          onClick={handleDeletePost}
          submitting={submiting}
          disabled={submiting}
        >
          Remove member
        </FormSubmit>
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
      </Stack>
    </Overlay>
  );
};
