import {FunctionalComponent} from 'preact';
import {Breadcrumbs, EmptyMessage, Loading, Typography} from 'pylon/ui';
import {PostGrid} from '@/components/(post)/PostGrid';
import {useUserAlbum} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';
import {timeAgo} from '@/lib/dayjs';

type Props = {
  params: {
    albumId: string;
    username: string;
  };
};

export const PublicUserProfileAlbumRoute: FunctionalComponent<Props> = ({
  params: {albumId},
}) => {
  const {data, loading, error} = useUserAlbum({
    params: {albumId: Number(albumId)},
  });

  if (loading) {
    return <Loading centered />;
  } else if (!data?.posts?.length) {
    return <EmptyMessage>This album is empty.</EmptyMessage>;
  } else {
    const posts = data.posts;

    return (
      <>
        <Breadcrumbs
          items={[
            {
              title: `Albums from ${posts[0].user.firstName}`,
              href: ROUTES.PUBLIC_USER_PROFILE_ALBUMS.replace(
                ':username',
                posts[0].user.username,
              ),
            },
            {title: data.name},
          ]}
          css={`
            --breadcrums-font-size: var(--font-size-h4);
            --breadcrums-icon-size: 28;
            margin-bottom: var(--gap-1);
          `}
        />
        <Typography css-color="var(--fg-muted)" css-mb={10}>
          {timeAgo(data.createdAt)} &middot; {posts.length} posts
        </Typography>
        <PostGrid posts={posts} />
      </>
    );
  }
};
