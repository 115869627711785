import {FunctionalComponent} from 'preact';
import {useEffect, useRef, useState} from 'preact/hooks';
import {afterNextRender, breakpoints, useWindowSize} from 'pylon/lib';
import {EmptyMessage} from 'pylon/ui';
import {GetUserPosts} from '@shared/api-types';
import {PostCard} from './PostCard';

type Props = {
  posts: GetUserPosts['response'];
};

const GAP_PX = 16;

export const PostGrid: FunctionalComponent<Props> = ({posts}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [size, setSize] = useState<number>(200);

  useEffect(() => {
    afterNextRender(() => {
      if (!containerRef.current) return;
      const clientWith = Math.floor(
        containerRef.current.getBoundingClientRect().width,
      );
      let cols = 2;
      if (clientWith > breakpoints.lg) {
        cols = 4;
      } else if (clientWith > breakpoints.md) {
        cols = 3;
      }
      setSize(Math.floor((clientWith - (cols - 1) * GAP_PX) / cols));
    });
  }, [windowSize]);

  if (posts.length) {
    return (
      <div
        ref={containerRef}
        css={`
          display: flex;
          flex-wrap: wrap;
          gap: ${GAP_PX}px;
          overflow-x: auto;
        `}
      >
        {posts.map((post) => (
          <PostCard key={post.id} post={post} height={size} width={size} />
        ))}
      </div>
    );
  } else {
    return (
      <EmptyMessage
        css={`
          flex: 1;
        `}
      >
        No highlights found
      </EmptyMessage>
    );
  }
};
