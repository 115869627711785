import {createContext} from 'preact';
import {useContext} from 'preact/hooks';
import {User as FirebaseUser} from '@firebase/auth';
import {GetMe} from '@shared/api-types';

type GetMeTeam = NonNullable<GetMe['response']>['teams'][0];

export type AppContextType = {
  currentUser: GetMe['response'] | null;
  currentFirebaseUser: FirebaseUser | null;
  currentTeam: GetMeTeam | null;
  loadingUser: boolean;
  loadingFirebaseUser: boolean;
  setCurrentTeam: (teamId?: string | null) => void;
};

export const AppContext = createContext<AppContextType>({
  currentUser: null,
  currentFirebaseUser: null,
  currentTeam: null,
  loadingUser: false,
  loadingFirebaseUser: false,
  setCurrentTeam: () => {},
});

export const useAppContext = (): AppContextType => useContext(AppContext);

export type AuthenticatedAppContextType = {
  currentUser: NonNullable<GetMe['response']>;
  currentFirebaseUser: FirebaseUser;
  currentTeam: GetMeTeam | null;
  setCurrentTeam: (teamId?: string | null) => void;
};

export const useAuthenticatedContext = (): AuthenticatedAppContextType => {
  const context = useContext(AppContext);

  if (!context.currentUser || !context.currentFirebaseUser) {
    throw new Error(
      'useAuthenticatedContext must be used when firebaseUser and user are not null',
    );
  }

  return context as AuthenticatedAppContextType;
};

export type AuthenticatedAsTeamAppContextType = {
  currentUser: NonNullable<GetMe['response']>;
  currentFirebaseUser: FirebaseUser;
  currentTeam: GetMeTeam;
  setCurrentTeam: (teamId?: string | null) => void;
};

export const useAuthenticatedAsTeamContext =
  (): AuthenticatedAsTeamAppContextType => {
    const context = useContext(AppContext);

    if (
      !context.currentUser ||
      !context.currentFirebaseUser ||
      !context.currentTeam
    ) {
      throw new Error(
        'useAuthenticatedAsTeamContext must be used when firebaseUser, user and team are not null',
      );
    }

    return context as AuthenticatedAsTeamAppContextType;
  };
