import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {Button, ToastMessage} from 'pylon/ui';
import {useAppContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';

type Props = {};

export const NotOnboardedToast: FunctionalComponent<Props> = () => {
  const {currentUser} = useAppContext();

  if (!currentUser) {
    return null;
  }

  return (
    <ToastMessage
      severity="info"
      contentCss={css`
        gap: var(--gap-3);
      `}
    >
      Please complete your profile to appear in search results and
      recommendations.{' '}
      <Button
        variant="primary"
        onClick={(evt: MouseEvent) => {
          // TODO: This should be handled by Anchor?
          evt.preventDefault();
          window.history.pushState({}, '', (evt as any).currentTarget.href);
          window.dispatchEvent(new Event('popstate'));
        }}
        href={ROUTES.PUBLIC_USER_PROFILE_EDIT.replace(
          ':username',
          currentUser.username,
        )}
      >
        Edit profile
      </Button>
    </ToastMessage>
  );
};
