import {CoachEmailsEnum} from './constants';
import {AccountType, PlayerPosition} from './database-types';

export const accountTypesForEmail = (email: string) => {
  const coachEmailsDomains = Object.values(CoachEmailsEnum);
  const isCoachEmail = coachEmailsDomains.some((domain) =>
    email.endsWith(domain),
  );

  if (isCoachEmail) {
    // Is coach, then return all
    return Object.values(AccountType);
  }

  // Not a coach...
  return Object.values(AccountType).filter(
    (type) =>
      type !== AccountType.COLLEGE_COACH &&
      type !== AccountType.HIGH_SCHOOL_COACH &&
      type !== AccountType.YOUTH_COACH,
  );
};

export const playerHasPopTime = (
  position1: PlayerPosition | null | undefined,
  position2: PlayerPosition | null | undefined,
) => {
  return position1 === PlayerPosition.C || position2 === PlayerPosition.C;
};
