import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {css} from 'styled-components';
import {mediaQuery, useSearchParams} from 'pylon/lib';
import {
  Button,
  Img,
  Stack,
  Typography,
} from 'pylon/ui';
import {GetTeamProfile} from '@shared/api-types';
import {UserTeamRole} from '@shared/database-types';
import {useAppContext} from '@/lib/app-context';
import {toMediaPath} from '@/lib/environment';
import {FollowTeamButton} from '../(follow)/FollowTeamButton';
import {PDAvatar} from '../PDAvatar';
import {SuperUserButton} from '../SuperUserButton';
import {TeamProfileForm} from '../TeamProfileForm';

type Props = {
  profile: GetTeamProfile['response'];
};

export const TeamProfileHeader: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  const {currentUser} = useAppContext();
  const [openedEdit, setOpenedEdit] = useState(false);
  const {queryParams, removeQP} = useSearchParams(['edit']);
  const hasCoverPhoto = !!profile.coverPhoto;

  useEffect(() => {
    if (queryParams.edit) {
      setOpenedEdit(true);
      removeQP('edit');
    }
  }, [queryParams.edit]);

  const canEditTeam =
    currentUser &&
    currentUser.teams.some(
      (team) => team.id === profile.team.id && team.role === UserTeamRole.OWNER,
    );

  return (
    <div
      css={`
        display: grid;
        gap: var(--gap-10);
        margin-bottom: var(--gap-10);
        ${hasCoverPhoto &&
        css`
          grid-template-rows: auto 1fr;
          grid-template-areas: 'cover' 'info';
          ${mediaQuery(
            'md>',
            css`
              grid-template-areas: 'info cover';
              grid-template-columns: 1fr 1fr;
              grid-template-rows: auto;
            `,
          )}
        `}
      `}
      {...props}
    >
      <Stack direction="column">
        <PDAvatar
          team={profile.team}
          size="lg"
          css={`
            grid-area: info;
            font-size: var(--font-size-h5);
            height: 4.25rem;
            width: 4.25rem;
            ${mediaQuery(
              'md>',
              css`
                height: 5.25rem;
                width: 5.25rem;
              `,
            )}
          `}
        />
        <Typography
          variant="h2"
          textTransform="uppercase"
          fontStyle="italic"
          css-mt={8}
          css={`
            letter-spacing: -0.035em;
          `}
        >
          {profile.team.name}
        </Typography>
        <Typography variant="h5" css-mt={2}>
          {profile.state || profile.city}
        </Typography>
        <Typography
          css-mt={6}
          css-color="var(--fg-muted)"
          css={`
            display: flex;
            align-items: center;
            gap: var(--gap-6);
          `}
        >
          <span>{profile.followersCount} followers</span>
        </Typography>
        {currentUser && (
          <Stack gap={4} css-mt={10}>
            <FollowTeamButton profile={profile} />
            {canEditTeam && (
              <>
                <Button variant="outline" onClick={() => setOpenedEdit(true)}>
                  Edit profile
                </Button>
                <TeamProfileForm
                  opened={openedEdit}
                  setOpened={setOpenedEdit}
                  profile={profile}
                />
              </>
            )}
            {currentUser.isSuperUser && (
              <SuperUserButton teamId={profile.team.id} />
            )}
          </Stack>
        )}
      </Stack>
      {hasCoverPhoto && (
        <Img
          src={toMediaPath(profile.coverPhoto)}
          css={`
            background-size: cover;
            border-radius: var(--radius-4);
            grid-area: cover;
            height: 100%;
            margin-left: auto;
            max-width: 578px;
            width: 100%;
            &::before {
              content: '';
              display: block;
              padding-top: 75%;
            }
            ${mediaQuery(
              'md>',
              css`
                border-radius: 2.1875rem;
              `,
            )}
          `}
        />
      )}
    </div>
  );
};
