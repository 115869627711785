import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from 'react-hook-form';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {
  Button,
  Form,
  FormSubmit,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {GetTeamProfile, UpdateMeTeamProfile} from '@shared/api-types';
import {useUpdateMeTeamProfile} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {useNetworkError} from '@/lib/use-network-error';
import {uploadCoverPhoto} from './(controls)/_ControlCoverPhoto';
import {TeamProfileForm_FormTabs} from './_FormTabs';

export type ProfileFormData = UpdateMeTeamProfile['request']['body'];

export type TeamProfileFormState = {
  control: Control<ProfileFormData>;
  errors: FieldErrors<ProfileFormData>;
  profile?: GetTeamProfile['response'];
  register: UseFormRegister<ProfileFormData>;
  setValue: UseFormSetValue<ProfileFormData>;
  watch: UseFormWatch<ProfileFormData>;
};

type Props = {
  opened: boolean;
  profile: GetTeamProfile['response'];
  setOpened: (value: boolean) => void;
};

export const TeamProfileForm: FunctionalComponent<Props> = ({
  opened,
  profile,
  setOpened,
}) => {
  const {currentUser} = useAuthenticatedContext();
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const [updateMeTeamProfile] = useUpdateMeTeamProfile();

  const {
    control,
    formState: {errors},
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
  } = useForm<ProfileFormData>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: sanitizeFormData(profile),
  });

  const onSubmit = async (formData: ProfileFormData) => {
    setNetworkError(null);
    setSubmiting(true);

    if (formData.tmpCoverPhotoStoragePath) {
      const path =
        (await uploadCoverPhoto({
          arrayBuffer: formData.tmpCoverPhotoStoragePath as any,
          currentUser,
        })) ?? null;
      setValue('tmpCoverPhotoStoragePath', path ?? null);
      formData.tmpCoverPhotoStoragePath = path;
    }
    try {
      await updateMeTeamProfile({
        params: {
          teamId: profile.team.id,
        },
        body: {
          ...sanitizeFormData(formData),
          tmpCoverPhotoStoragePath: formData.tmpCoverPhotoStoragePath,
        },
        updateCacheParams: {
          slug: profile.team.slug,
        },
      });
      setOpened(false);
    } catch (error: unknown) {
      setNetworkError(error);
    }

    setSubmiting(false);
  };

  const handleCancel = () => {
    reset();
    setOpened(false);
  };

  useEffect(() => {
    if (!opened) {
      reset({
        ...sanitizeFormData(profile),
        tmpCoverPhotoStoragePath: null,
      });
    }
  }, [profile, opened]);

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      position={{mode: 'centered'}}
      noAutoFocus
      withBackdrop
      pushHistoryState
      cancelOnOutsideClick
      cancelOnEscKey
      disableBodyScroll
      animation="scale-in"
      css={`
        background: var(--bg-overlay);
        border-radius: var(--radius-3);
        box-shadow: var(--shadow-3);
        max-height: 90%;
        max-width: 60rem;
        overflow: hidden;
        width: 100%;
      `}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          alignItems="center"
          css={`
            background: inherit;
            gap: var(--gap-1);
            padding: 0 var(--gap-4);
            position: sticky;
            top: 0;
            min-height: 4.75rem;
            z-index: 1;
            ${mediaQuery(
              'md>',
              css`
                gap: var(--gap-4);
                padding: 0 var(--gap-8) 0;
              `,
            )}
          `}
        >
          <Typography
            textTransform="uppercase"
            fontStyle="italic"
            css={`
              font-size: var(--font-size-subtitle-2);
              font-weight: var(--font-weight-extra-bold);
              letter-spacing: -0.0625rem;
              ${mediaQuery(
                'md>',
                css`
                  font-size: var(--font-size-h5);
                `,
              )}
            `}
          >
            Edit profile
          </Typography>
          <Button variant="muted" onClick={handleCancel} css-ml="auto">
            Cancel
          </Button>
          <FormSubmit
            variant="primary"
            submitting={submiting}
            disabled={hasErrors}
          >
            Save changes
          </FormSubmit>
        </Stack>
        {networkError && (
          <ToastMessage severity="error" css-m={[0, 5, 3] as any}>
            {networkError}
          </ToastMessage>
        )}
        <TeamProfileForm_FormTabs
          formState={{control, errors, profile, register, setValue, watch}}
        />
      </Form>
    </Overlay>
  );
};

// TODO: Move this to shared, it's a profileSanitizer, so it runs both here and there, making sure the data is consistent,
// only returns given
function sanitizeFormData(p: GetTeamProfile['response'] | ProfileFormData) {
  return {
    // General
    bio: p.bio || null,
    city: p.city || null,
    state: p.state || null,
    socialLinks: p.socialLinks || null,
  };
}
