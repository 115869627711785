import {ComponentChildren, FunctionalComponent} from 'preact';
import {Responsive, Stack, Typography} from 'pylon/ui';

type Props = {
  title?: string;
  children: ComponentChildren;
};

export const AuthLayout: FunctionalComponent<Props> = ({title, children}) => {
  return (
    <Responsive
      size="md"
      css={`
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        max-width: 50rem;
      `}
    >
      {!!title && (
        <Typography
          variant="h3"
          tagName="h1"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={8}
        >
          {title}
        </Typography>
      )}
      {children}
    </Responsive>
  );
};
