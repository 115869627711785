import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Controller, useForm} from 'react-hook-form';
import {
  Button,
  Form,
  FormInput,
  FormSubmit,
  FormTextarea,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {Event} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {
  validateEventExternalLink,
  validatePostContent,
} from '@shared/validators';
import {useCreateMeEvent, useUpdateMeEvent} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {handleFormValidate} from '@/lib/error-messages';
import {useNetworkError} from '@/lib/use-network-error';

type FormData = Pick<
  Event,
  'title' | 'location' | 'description' | 'startDate' | 'externalLink'
>;

type Props = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  defaultEvent?: Event;
};

export const EventForm: FunctionalComponent<Props> = ({
  opened,
  defaultEvent,
  setOpened,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const [createMeEvent] = useCreateMeEvent();
  const [updateMeEvent] = useUpdateMeEvent();
  const {currentTeam} = useAuthenticatedContext();

  const {
    control,
    setValue,
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      title: defaultEvent ? defaultEvent.title : '',
      location: defaultEvent ? defaultEvent.location : '',
      description: defaultEvent ? defaultEvent.description : '',
      startDate: defaultEvent ? defaultEvent.startDate : undefined,
      externalLink: defaultEvent ? defaultEvent.externalLink : '',
    },
  });

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      const body = {
        ...formData,
      };
      if (defaultEvent) {
        await updateMeEvent({
          params: {
            eventId: defaultEvent.id,
          },
          body: {
            ...body,
          },
        });
      } else {
        await createMeEvent({
          body: {
            ...body,
            teamId: currentTeam ? currentTeam.id : undefined,
          },
        });
      }
      setOpened(false);
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
  };

  const handleCancel = () => {
    setOpened(false);
  };

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      closeOthers
      cancelOnEscKey
      cancelOnOutsideClick
      animation="scale-in"
      withBackdrop
      position={{
        mode: 'centered',
      }}
      css={`
        ${overlayPopupCss}
      `}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h5"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={1}
        >
          {defaultEvent ? 'Edit' : 'Add'} event
        </Typography>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        <Stack direction="column" gap={6} css-mt={3}>
          <FormInput
            {...register('title', {required: 'Title is required'})}
            id="title"
            label="Title"
            defaultValue={defaultEvent?.title ?? ''}
            invalid={!!errors.title}
            invalidText={errors?.title?.message}
            fullWidth
          />
          <FormInput
            {...register('location', {required: 'Location is required'})}
            id="location"
            label="Location"
            defaultValue={defaultEvent?.location ?? ''}
            invalid={!!errors.location}
            invalidText={errors?.location?.message}
            fullWidth
          />
          <Controller
            control={control}
            name="description"
            rules={{
              validate: (value) =>
                handleFormValidate(validatePostContent(value ?? '')),
            }}
            render={({field: {onChange, onBlur, value}}) => (
              <FormTextarea
                id="description"
                label="Description"
                defaultValue={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                invalid={!!errors.description}
                invalidText={errors?.description?.message}
                fullWidth
              />
            )}
          />
          <FormInput
            {...register('externalLink', {
              validate: (value) =>
                handleFormValidate(validateEventExternalLink(value)),
            })}
            id="externalLink"
            label="Link"
            defaultValue={defaultEvent?.externalLink ?? ''}
            invalid={!!errors.externalLink}
            invalidText={errors?.externalLink?.message}
            fullWidth
          />
          <Controller
            control={control}
            name="startDate"
            rules={{required: 'Start Date is required'}}
            render={({field: {onChange, onBlur, value}}) => (
              <FormInput
                id="startDate"
                label="Start Date"
                type="date"
                defaultValue={(value as any) ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                invalid={!!errors.startDate}
                invalidText={errors?.startDate?.message}
                fullWidth
              />
            )}
          />
        </Stack>
        <Stack gap={4} css-mt={8}>
          <FormSubmit submitting={submiting} disabled={submiting}>
            {defaultEvent ? 'Save' : 'Create'}
          </FormSubmit>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Form>
    </Overlay>
  );
};
