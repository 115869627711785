import {FunctionalComponent} from 'preact';
import {Controller} from 'react-hook-form';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {FormDecimalSelect, FormInput, FormSelect, Stack} from 'pylon/ui';
import {PlayerHand, PlayerLevel, PlayerPosition} from '@shared/database-types';
import {beautifyPlayerPosition, capitalize} from '@/lib/utils';
import {ProfileFormState} from '../ProfileForm';

export enum RegisteredNames {
  BATTING_HAND = 'battingHand',
  GRAD_YEAR = 'gradYear',
  HEIGHT = 'height',
  LEVEL = 'level',
  MOST_RECENT_TEAM = 'mostRecentTeam',
  POSITION1 = 'position1',
  POSITION2 = 'position2',
  THROWING_HAND = 'throwingHand',
  WEIGHT = 'weight',
}

export const ProfileForm_ControlPlayerDetails: FunctionalComponent<
  ProfileFormState
> = ({register, control, errors}) => {
  const gradYearOptions = Array.from({length: 41}, (_, i) => 2000 + i).map(
    (year) => ({
      value: year,
      label: year.toString(),
    }),
  );
  const weightOptions = Array.from({length: 251}, (_, i) => 50 + i).map(
    (height) => ({
      value: height,
      label: height.toString() + 'lbs',
    }),
  );

  return (
    <>
      <FormInput
        {...register(RegisteredNames.MOST_RECENT_TEAM, {required: false})}
        id="mostRecentTeam"
        label="Most recent team"
        invalid={!!errors[RegisteredNames.MOST_RECENT_TEAM]}
        invalidText={errors?.[RegisteredNames.MOST_RECENT_TEAM]?.message}
        fullWidth
      />
      <Stack
        gap={8}
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <Controller
          name={RegisteredNames.LEVEL}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.LEVEL}
                label="Current playing level"
                options={Object.keys(PlayerLevel).map((key) => ({
                  label: capitalize(key),
                  value: key,
                }))}
                invalid={!!errors[RegisteredNames.LEVEL]}
                invalidText={errors?.[RegisteredNames.LEVEL]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
        <Controller
          name={RegisteredNames.GRAD_YEAR}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.GRAD_YEAR}
                label="HS graduation year"
                options={gradYearOptions}
                invalid={!!errors[RegisteredNames.GRAD_YEAR]}
                invalidText={errors?.[RegisteredNames.GRAD_YEAR]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
      </Stack>
      <Stack
        gap={8}
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <Controller
          name={RegisteredNames.WEIGHT}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.WEIGHT}
                label="Weight"
                options={weightOptions}
                invalid={!!errors[RegisteredNames.WEIGHT]}
                invalidText={errors?.[RegisteredNames.WEIGHT]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
        <Controller
          name={RegisteredNames.HEIGHT}
          control={control}
          render={({field: {onChange, value}}) => (
            <FormDecimalSelect
              id={RegisteredNames.HEIGHT}
              label="Height"
              minWholeNumber={3}
              maxWholeNumber={7}
              maxFractionalNumber={11}
              invalid={!!errors[RegisteredNames.HEIGHT]}
              invalidText={errors?.[RegisteredNames.HEIGHT]?.message}
              value={value}
              onChange={onChange}
              fullWidth
            />
          )}
        />
      </Stack>
      <Stack
        gap={8}
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <Controller
          name={RegisteredNames.POSITION1}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.POSITION1}
                label="Primary position"
                options={Object.keys(PlayerPosition).map((key) => ({
                  label: beautifyPlayerPosition(key),
                  value: key,
                }))}
                invalid={!!errors[RegisteredNames.POSITION1]}
                invalidText={errors?.[RegisteredNames.POSITION1]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
        <Controller
          name={RegisteredNames.POSITION2}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.POSITION2}
                label="Secondary position"
                options={Object.keys(PlayerPosition).map((key) => ({
                  label: beautifyPlayerPosition(key),
                  value: key,
                }))}
                invalid={!!errors[RegisteredNames.POSITION2]}
                invalidText={errors?.[RegisteredNames.POSITION2]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
      </Stack>
      <Stack
        gap={8}
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <Controller
          name={RegisteredNames.THROWING_HAND}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.THROWING_HAND}
                label="Throwing hand"
                options={Object.keys(PlayerHand).map((key) => ({
                  label: capitalize(key),
                  value: key,
                }))}
                invalid={!!errors[RegisteredNames.THROWING_HAND]}
                invalidText={errors?.[RegisteredNames.THROWING_HAND]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
        <Controller
          name={RegisteredNames.BATTING_HAND}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.BATTING_HAND}
                label="Batting hand"
                options={Object.keys(PlayerHand).map((key) => ({
                  label: capitalize(key),
                  value: key,
                }))}
                invalid={!!errors[RegisteredNames.BATTING_HAND]}
                invalidText={errors?.[RegisteredNames.BATTING_HAND]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
      </Stack>
    </>
  );
};
