import {UserPreview} from '@shared/common-types';
import {SocialLinkEnum} from '@shared/constants';
import {AccountType, PlayerPosition} from '@shared/database-types';
import {usStates} from '@shared/us-states';

export function capitalize(str: string) {
  const words = str.toLowerCase().split('_');
  if (words.length > 0) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }
  return words.join(' ');
}

export function getYouTubeEmbedCode(text: string) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
  const matches = text.match(regex);

  if (matches && matches.length > 0) {
    // Extracting the video ID from the first match
    const videoIdMatch = matches[0].match(
      /(?:youtube\.com.*(?:\/v\/|\/embed\/|\/watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    );
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
    return videoId;
  }

  return null;
}

export function getYoutubeImagePreviewFromId(id: string) {
  return `https://img.youtube.com/vi/${id}/0.jpg`;
}

export function beautifyPlayerPosition(position?: string | null) {
  if (!position) return '';

  const mods: {
    [key in PlayerPosition]?: string;
  } = {
    [PlayerPosition.FIRST_B]: '1B',
    [PlayerPosition.SECOND_B]: '2B',
    [PlayerPosition.THRID_B]: '3B',
  };

  return mods[position as PlayerPosition] ?? position.toUpperCase();
}

export function beautifySocialLinkName(url: string) {
  if (!url) return '';

  const mods: {
    [key in SocialLinkEnum]?: string;
  } = {
    [SocialLinkEnum.TWO_D_SPORTS]: '2D sports',
  };

  return mods[url as SocialLinkEnum] ?? capitalize(url);
}

type UserInfoDisplayProps = Pick<
  UserPreview,
  'accountType' | 'position1' | 'gradYear' | 'state'
>;

export function userInfoDisplay(user: UserInfoDisplayProps) {
  const result = [];

  if (user.accountType === AccountType.PLAYER) {
    result.push(
      user.position1
        ? [user.position1 ? beautifyPlayerPosition(user.position1) : null]
            .filter(Boolean)
            .join(' ')
        : null,
      user.gradYear ? `${user.gradYear}` : null,
    );
  }

  if (user.state) {
    result.push(usStates[user.state] ?? user.state);
  }

  return result.filter(Boolean).join(' | ');
}

export function toNumber(value?: string | null) {
  if (value === null || value === undefined) {
    return null;
  }
  return parseInt(value, 10);
}
