import {FunctionalComponent} from 'preact';
import {Typography} from 'pylon/ui';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {PDAvatar} from '../PDAvatar';
import {StyledNavItem} from './AppDrawer';

type Props = {};

export const AppDrawer_YourTeams: FunctionalComponent<Props> = () => {
  const {currentUser} = useAuthenticatedContext();
  const teams = currentUser?.teams ?? [];

  let content = null;

  if (teams.length > 0) {
    content = currentUser?.teams.map((team) => {
      return (
        <StyledNavItem
          title={team.name}
          icon={<PDAvatar team={team} size="sm" />}
          href={ROUTES.PUBLIC_TEAM_PROFILE.replace(':slug?', team.slug)}
          $isActive={window.location.pathname.includes(
            ROUTES.PUBLIC_TEAM_PROFILE.replace(':slug?', team.slug),
          )}
        >
          <PDAvatar
            team={team}
            size="md"
            css={`
              height: 26px;
              margin-right: var(--gap-3);
              width: 26px;
            `}
          />
          {team.name}
        </StyledNavItem>
      );
    });
  } else {
    content = (
      <Typography
        variant="body4"
        fontStyle="italic"
        css-color="var(--fg-muted)"
        css-p={[0, 2]}
      >
        You're not a member of any teams yet.
      </Typography>
    );
  }

  return (
    <div>
      <Typography
        variant="body4"
        weight="bold"
        textTransform="uppercase"
        css-color="var(--fg-muted)"
        css-p={[2.5]}
      >
        Your Teams
      </Typography>
      {content}
    </div>
  );
};
