import {useLayoutEffect, useState} from 'preact/hooks';
import {initializeApp} from '@firebase/app';
import {User, connectAuthEmulator, getAuth} from '@firebase/auth';
import {isDevelopment} from './environment';

export const firebaseConfig = {
  apiKey: 'AIzaSyCTziIIbXxIlFQVdNqBNv0rgfO6O6doN2I',
  authDomain: 'prospect-dugout.firebaseapp.com',
  projectId: 'prospect-dugout',
  storageBucket: 'prospect-dugout.appspot.com',
  messagingSenderId: '1028626616719',
  appId: '1:1028626616719:web:eaef7d49eb671d896efbfa',
  /* measurementId: 'G-V8HGFSMWNG', */
};

const app = initializeApp(firebaseConfig);

if (isDevelopment) {
  // This will only work if you have the Firebase emulator running locally
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
}

export const useFirebase = () => {
  const currentUser = getAuth().currentUser;
  const [loading, setLoading] = useState<boolean>(!currentUser);
  const [user, setUser] = useState<User | null>(currentUser);

  useLayoutEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {user, app, loading};
};
