import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Img, Responsive} from 'pylon/ui';
import bgImage from '@/assets/get-onboarded-bg.avif';
import {GetStartedProcess} from '@/components/GetStartedProcess';

type Props = {};

export const AuthedGetStartedRoute: FunctionalComponent<Props> = () => {
  return (
    <div
      css={`
        height: 100vh;
        width: 100%;
        ${mediaQuery(
          'md>',
          css`
            display: grid;
            grid-template-rows: 1fr max-content;
          `,
        )}
      `}
    >
      <div
        css={`
          height: 100%;
          min-height: 0;
          overflow-y: auto;
        `}
      >
        <Responsive
          size="md"
          css={`
            align-items: center;
            display: flex;
            min-height: 100%;
            justify-content: center;
            padding-bottom: var(--gap-10);
            padding-top: var(--gap-10);
            width: 100%;
          `}
        >
          <GetStartedProcess />
        </Responsive>
      </div>
      <footer
        css={`
          aspect-ratio: 7.5/1;
          height: auto;
          min-height: 6.25rem;
          overflow: hidden;
          position: relative;
          width: 100%;
          ${mediaQuery(
            '<md',
            css`
              display: none;
            `,
          )}
          &::before {
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: translateY(-100%);
            width: 100%;
            z-index: 1;
            box-shadow:
              0 208px 58px rgba(0, 0, 0, 0.01),
              0 133px 53px rgba(0, 0, 0, 0.07),
              0 75px 45px rgba(0, 0, 0, 0.25),
              0 33px 33px rgba(0, 0, 0, 0.43),
              0 8px 18px rgba(0, 0, 0, 0.49),
              0 0 0 rgba(0, 0, 0, 0.5);
          }
        `}
      >
        <Img
          src={bgImage}
          position="center"
          preload
          sizing="cover"
          css={`
            height: 100%;
            width: 100%;
          `}
        />
      </footer>
    </div>
  );
};
