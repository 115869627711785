export const ROUTES = {
  PUBLIC_HOME: '/',
  PUBLIC_TERMS: '/terms',
  PUBLIC_PRIVACY: '/privacy',

  PUBLIC_USER_PROFILE: '/:username',
  PUBLIC_USER_PROFILE_EDIT: '/:username?edit=1',
  PUBLIC_USER_PROFILE_POSTS: '/:username/posts',
  PUBLIC_USER_PROFILE_POST: '/:username/posts/:postId',
  PUBLIC_USER_PROFILE_ALBUMS: '/:username/albums',
  PUBLIC_USER_PROFILE_ALBUM: '/:username/albums/:albumId',

  PUBLIC_TEAM_PROFILE: '/teams/:slug?',
  PUBLIC_TEAM_PROFILE_EDIT: '/teams/:slug?edit=1',

  PUBLIC_CONFIRM_EMAIL: '/confirm-email',
  PUBLIC_EMPTY_SEARCH_ROUTE: '/search',
  PUBLIC_SEARCH_ROUTE: '/search/:searchValue',

  // GUEST
  GUEST_SIGN_IN: '/sign-in',
  GUEST_SIGN_UP: '/sign-up',
  GUEST_FORGOT_PASSWORD: '/forgot-password',

  // AUTHED
  AUTHED_GET_STARTED: '/get-started',
  AUTHED_SETTINGS: '/settings',
  AUTHED_SETTINGS_ACCOUNT: '/settings/account',
  AUTHED_SETTINGS_NOTIFICATIONS: '/settings/notifications',
  AUTHED_SETTINGS_ORGANIZATION_AND_TEAMS: '/settings/organization-and-teams',

  AUTHED_TEAM_SETTINGS: '/team-settings',
  AUTHED_TEAM_SETTINGS_GENERAL: '/team-settings/general',
  AUTHED_TEAM_SETTINGS_MEMBERS: '/team-settings/members',
  AUTHED_TEAM_SETTINGS_INVITE_MEMBER: '/team-settings/invite-member',

  AUTHED_NOTIFICATIONS: '/notifications',
  AUTHED_FOLLOWING: '/following',
  AUTHED_PROSPECT_PLANNER: '/prospect-planner',
};
