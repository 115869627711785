import {FunctionalComponent} from 'preact';
import {useLocation} from 'preact-iso';
import {useState} from 'preact/hooks';
import {getAuth} from 'firebase/auth';
import {css} from 'styled-components';
import {breakpoints, useWindowSize} from 'pylon/lib';
import {
  Button,
  Divider,
  DropdownMenu,
  IconButton,
  Stack,
  Typography,
  menuItemVariant,
} from 'pylon/ui';
import {
  canAddEvents,
  canAddHighlights,
  canCreateTeam,
} from '@shared/permissions';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {EventForm} from '../(event)/EventForm';
import {PostForm} from '../(post)/PostForm';
import {PDAvatar} from '../PDAvatar';
import {PDIcon} from '../PDIcon';
import {NotificationsButton} from './NotificationsButton';

type Props = {};

export const AppToolbar_AuthedControls: FunctionalComponent<Props> = ({
  ...props
}) => {
  const location = useLocation();
  const windowSize = useWindowSize();
  const {currentUser, currentTeam, setCurrentTeam} = useAuthenticatedContext();
  const [openedPostForm, setOpenedPostForm] = useState(false);
  const [openedEventsForm, setOpenedEventsForm] = useState(false);

  const handleSignOut = () => {
    getAuth().signOut();
    location.route(ROUTES.PUBLIC_HOME);
  };

  const handlePostClick = () => {
    setOpenedPostForm(true);
  };

  const _canCreateTeams = canCreateTeam(currentUser.accountType);
  const _canAddHighlights = canAddHighlights(currentUser.accountType);
  const _canAddEvents = currentTeam || canAddEvents(currentUser.accountType);

  return (
    <Stack gap={4} alignItems="center" {...props}>
      {(_canAddHighlights || _canAddEvents) && (
        <DropdownMenu>
          {windowSize.width > breakpoints.md ? (
            <Button
              variant="primary"
              iconLeading="plus"
              iconCss={css`
                & svg {
                  height: 20px;
                  width: 20px;
                }
              `}
            >
              Share
            </Button>
          ) : (
            <IconButton
              variant="primary-soft"
              icon="plus"
              css={`
                border-radius: var(--radius-round);
              `}
            />
          )}
          {_canAddHighlights && (
            <Button
              customVariant={menuItemVariant}
              onClick={() => {
                setOpenedPostForm(true);
              }}
            >
              Share highlight
            </Button>
          )}
          {_canAddEvents && (
            <Button
              customVariant={menuItemVariant}
              onClick={() => {
                setOpenedEventsForm(true);
              }}
            >
              Share event
            </Button>
          )}
        </DropdownMenu>
      )}
      {openedPostForm && (
        <PostForm opened={openedPostForm} setOpened={setOpenedPostForm} />
      )}
      {openedEventsForm && (
        <EventForm opened={openedEventsForm} setOpened={setOpenedEventsForm} />
      )}
      <NotificationsButton />
      <DropdownMenu
        overlayPosition={{
          mode: 'fixed',
          verticalOffset: 6,
        }}
      >
        <Button
          variant="unstyled"
          css={`
            &:hover {
              border-radius: 50%;
              box-shadow: 0 0 0 3px var(--border-muted);
            }
          `}
        >
          <Stack
            gap={2}
            alignItems="center"
            css={
              currentTeam
                ? `
                  background: var(--bg-muted);
                  border-radius: 20px;
                  padding-left: var(--gap-2);
                  outline: 6px solid var(--bg-muted);
                  &:hover {
                    filter: brightness(1.1);
                  }
            `
                : ``
            }
          >
            {!!currentTeam && (
              <Typography
                variant="body4"
                css-color="var(--fg-muted)"
                css={`
                  line-height: 1.2;
                `}
              >
                {currentTeam.name}
              </Typography>
            )}
            <PDAvatar team={currentTeam} user={currentUser} />
          </Stack>
        </Button>
        <Button
          customVariant={menuItemVariant}
          href={
            currentTeam
              ? ROUTES.PUBLIC_TEAM_PROFILE.replace(':slug?', currentTeam.slug)
              : ROUTES.PUBLIC_USER_PROFILE.replace(
                  ':username',
                  currentUser.username,
                )
          }
        >
          Your profile
        </Button>
        {!currentTeam ? (
          <Button
            customVariant={menuItemVariant}
            href={ROUTES.AUTHED_SETTINGS_ACCOUNT}
          >
            Settings
          </Button>
        ) : (
          <Button
            customVariant={menuItemVariant}
            href={ROUTES.AUTHED_TEAM_SETTINGS}
          >
            Team settings
          </Button>
        )}
        {!currentTeam && _canCreateTeams && (
          <Button
            customVariant={menuItemVariant}
            href={ROUTES.AUTHED_SETTINGS_ORGANIZATION_AND_TEAMS}
          >
            Manage your teams
          </Button>
        )}
        {_canCreateTeams && currentUser.teams.length > 0 && (
          <>
            <Divider
              css={`
                margin: var(--gap-2) 0;
                background: var(--bg-muted);
              `}
            />
            <Typography
              variant="body4"
              css={`
                color: var(--fg-muted);
                padding: var(--gap-3) var(--gap-4) var(--gap-2);
              `}
            >
              Use ProspectDugout as
            </Typography>
            {currentTeam && (
              <Button
                customVariant={menuItemVariant}
                onClick={() => {
                  setCurrentTeam(null);
                }}
              >
                {currentUser.firstName} {currentUser.lastName}
              </Button>
            )}
            {currentUser.teams
              .filter((team) => team.id !== currentTeam?.id)
              .map((team) => (
                <Button
                  customVariant={menuItemVariant}
                  onClick={() => {
                    setCurrentTeam(String(team.id));
                  }}
                >
                  {team.name}
                </Button>
              ))}
          </>
        )}
        <Divider
          css={`
            margin: var(--gap-2) 0;
            background: var(--bg-muted);
          `}
        />
        <Button customVariant={menuItemVariant} onClick={handleSignOut}>
          Sign out
        </Button>
      </DropdownMenu>
    </Stack>
  );
};
