import {FunctionalComponent} from 'preact';
import {Anchor, Card, Stack, Typography} from 'pylon/ui';
import {TeamSettingsGeneralForm} from '@/components/(team-settings)/TeamSettingsGeneralForm';

type Props = {};

export const TeamSettingsGeneralRoute: FunctionalComponent<Props> = () => {
  return (
    <Stack direction="column" gap={6}>
      <Card>
        <TeamSettingsGeneralForm />
      </Card>
      <Card>
        <Typography variant="body2" weight="bold" css-mb={4}>
          Delete team
        </Typography>
        <Typography css-color="var(--fg-muted)">
          If you want to delete this team, please send an email to{' '}
          <Anchor href="mailto:support@prospectdugout.com" variant="underline">
            support@prospectdugout.com
          </Anchor>
        </Typography>
      </Card>
    </Stack>
  );
};
