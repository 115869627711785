import {FunctionalComponent} from 'preact';
import {Controller, useFieldArray} from 'react-hook-form';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {
  Button,
  FormInput,
  FormSelect,
  Grid,
  IconButton,
  Label,
  Stack,
  Typography,
} from 'pylon/ui';
import {SocialLinkEnum} from '@shared/constants';
import {validateUrl} from '@shared/validators';
import {handleFormValidate} from '@/lib/error-messages';
import {beautifySocialLinkName} from '@/lib/utils';
import {TeamProfileFormState} from '../TeamProfileForm';

export enum RegisteredNames {
  SOCIAL_LINKS = 'socialLinks',
}

export const ProfileForm_ControlSocialLinks: FunctionalComponent<
  TeamProfileFormState
> = ({register, control, errors}) => {
  const {fields, append, remove} = useFieldArray({
    name: RegisteredNames.SOCIAL_LINKS,
    control,
  });

  return (
    <div>
      <Label
        css={`
          display: block;
          margin-bottom: var(--gap-3);
        `}
      >
        Social profiles
      </Label>
      <Stack gap={3} direction="column">
        {fields.length < 1 && (
          <Typography css-color="var(--fg-muted)">
            Link your social profiles
          </Typography>
        )}
        {fields.map((field, idx) => (
          <Grid
            key={field.id}
            alignItems="center"
            cols="1fr 1fr auto"
            gap={3}
            css={`
              width: 100%;
            `}
          >
            <Controller
              name={`${RegisteredNames.SOCIAL_LINKS}.${idx}.name`}
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <FormSelect
                  options={Object.keys(SocialLinkEnum).map((key) => ({
                    label: beautifySocialLinkName(key),
                    value: key,
                  }))}
                  invalid={!!errors.socialLinks?.[idx]?.name}
                  fullWidth
                  {...(field as any)}
                />
              )}
            />
            <FormInput
              {...register(`${RegisteredNames.SOCIAL_LINKS}.${idx}.url`, {
                validate: (value) => handleFormValidate(validateUrl(value)),
              })}
              placeholder="URL"
              invalid={!!errors.socialLinks?.[idx]?.url}
            />
            <IconButton
              variant="muted"
              icon="trash"
              buttonSize="md"
              onClick={() => remove(idx)}
              css={`
                margin: 0 var(--gap-3) 0 0;
                ${mediaQuery(
                  'md>',
                  css`
                    margin: 0 var(--gap-8) 0 var(--gap-3);
                  `,
                )}
              `}
            />
          </Grid>
        ))}
      </Stack>
      <Button
        variant="primary-soft"
        iconSize={20}
        iconLeading="plus"
        onClick={() => append({url: '', name: ''})}
        css-mt={6}
        css={`
          padding-left: var(--gap-4);
        `}
      >
        Add
      </Button>
    </div>
  );
};
