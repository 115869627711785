import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {
  Loading,
  Redirect,
  Responsive,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {TeamPreview, UserPreview} from '@shared/common-types';
import {AccountType} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {AppLayout} from '@/components/AppLayout';
import {TeamCard} from '@/components/TeamCard';
import {UserCard} from '@/components/UserCard';
import {useMeFollowing} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';

type Props = {};

export const AuthedFollowingRoute: FunctionalComponent<Props> = (props) => {
  const {currentUser, currentTeam} = useAuthenticatedContext();
  const {data, error, loading} = useMeFollowing({
    params: {offset: 0, limit: 50},
  });

  if (currentTeam) {
    return <Redirect to={ROUTES.PUBLIC_HOME} />;
  }

  if (currentUser.accountType === AccountType.SCOUT) {
    return <Redirect to={ROUTES.AUTHED_PROSPECT_PLANNER} />;
  }

  let content = null;

  if (loading) {
    content = (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  } else {
    const {totalCount, rows} = data;
    if (totalCount === 0) {
      content = (
        <Stack
          direction="column"
          alignItems="center"
          gap={1}
          css-pt={4}
          css-pb={4}
        >
          <Typography weight="medium">You are not following anyone</Typography>
        </Stack>
      );
    } else {
      content = (
        <>
          <div
            css={`
              display: flex;
              flex-direction: column;
              gap: var(--gap-4);
              ${mediaQuery(
                'md>',
                css`
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
                `,
              )}
            `}
          >
            {rows.map((following) => {
              return !!(following as any).accountType ? (
                <UserCard user={following as UserPreview} />
              ) : (
                <TeamCard team={following as TeamPreview} />
              );
            })}
          </div>
        </>
      );
    }
  }

  return (
    <AppLayout>
      <Responsive size="xl">
        <Typography
          variant="h4"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={6}
        >
          Following
        </Typography>
        {content}
      </Responsive>
    </AppLayout>
  );
};
