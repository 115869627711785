export const IMAGES_STORAGE_PATH = 'i';
export const IMAGES_TMP_STORAGE_PATH = 'i/tmp';
export const VIDEOS_STORAGE_PATH = 'v';
export const VIDEOS_TMP_STORAGE_PATH = 'v/tmp';
export const IMAGE_STORAGE_TEMP_FORMAT = 'jpg';
export const IMAGE_STORAGE_FINAL_FORMAT = 'jpg';

export enum SocialLinkEnum {
  INSTAGRAM = 'INSTAGRAM',
  X = 'X',
  TIK_TOK = 'TIK_TOK',
  YOUTUBE = 'YOUTUBE',
  PERFECT_GAME = 'PERFECT_GAME',
  PREP_BASEBALL_REPORT = 'PREP_BASEBALL_REPORT',
  FIVE_TOOL_BASEBALL = 'FIVE_TOOL_BASEBALL',
  TWO_D_SPORTS = 'TWO_D_SPORTS',
}

export enum CoachEmailsEnum {
  SCHOOL_EDU = '@school.edu',
  MLBTEAM = '@mlbteam.com',
}
