export const isDevelopment = process.env.NODE_ENV === 'development';

export const FIREBASE_PROJECT_ID = 'prospect-dugout';

export const FUNCTIONS_URL = isDevelopment
  ? 'http://127.0.0.1:5001/prospect-dugout/us-central1/v2App/api/v1'
  : 'https://us-central1-prospect-dugout.cloudfunctions.net/v2App/api/v1';

export function toMediaPath(filePath?: string | null) {
  if (!filePath) return '';
  if (isDevelopment) {
    return `/dev-storage/v0/b/${FIREBASE_PROJECT_ID}.appspot.com/o/${encodeURIComponent(
      filePath,
    )}?alt=media`;
  } else {
    return `https://firebasestorage.googleapis.com/v0/b/${FIREBASE_PROJECT_ID}.appspot.com/o/${encodeURIComponent(
      filePath,
    )}?alt=media`;
    /* return `${includeOrigin ? 'https://prospectdugout.tv' : ''}/media/${key}`; */
  }
}
