import {FunctionalComponent} from 'preact';
import {useEffect, useRef} from 'preact/hooks';
import {CheckboxGroup, Input} from 'pylon/ui';
import {SearchFiltersType} from './SearchFilters';

type Props = {
  currentValuesRef: {current: SearchFiltersType};
  defaultValues: SearchFiltersType;
  handleChange: (value: (string | number)[], key: string) => void;
};

export const SearchFilters_GradYear: FunctionalComponent<Props> = ({
  currentValuesRef,
  defaultValues,
  handleChange,
}) => {
  const gradYearRef = useRef<number | null>(null);

  const handleGradYearInputChange = (evt: Event) => {
    const value = parseInt((evt.target as HTMLInputElement).value, 10);
    if (typeof value === 'number' && value >= 2000) {
      gradYearRef.current = value;
    } else {
      gradYearRef.current = null;
    }

    const _gradYear = [
      ...currentValuesRef.current.gradYear!.filter((v) =>
        gradYearOptions.some((o) => parseInt(o.value, 10) === v),
      ),
    ].map((v: any) => parseInt(v, 10));

    if (
      typeof gradYearRef.current === 'number' &&
      !_gradYear.includes(gradYearRef.current)
    ) {
      _gradYear.push(gradYearRef.current);
    }

    handleChange(_gradYear, 'gradYear');
  };

  return (
    <div>
      <CheckboxGroup
        options={gradYearOptions}
        defaultChecked={defaultValues?.gradYear?.map((v) => v.toString())}
        onChange={(values) => {
          const _values = values.map((v) => parseInt(v, 10));
          if (
            typeof gradYearRef.current === 'number' &&
            !_values.includes(gradYearRef.current)
          ) {
            _values.push(gradYearRef.current);
          }
          handleChange(_values, 'gradYear');
        }}
        css={`
          margin-bottom: var(--gap-2);
        `}
      />
      <Input
        type="number"
        min="1980"
        max="2100"
        defaultValue={getInputDefaultValue(defaultValues)}
        placeholder="E.g. 2029"
        onChange={handleGradYearInputChange}
      />
    </div>
  );
};

const getInputDefaultValue = (defaultValues: SearchFiltersType) => {
  const gradYears = defaultValues?.gradYear ?? [];
  const missingDefaultValue = gradYears.find(
    (gradYear) =>
      !gradYearOptions.some((option) => option.value === String(gradYear)),
  );
  return missingDefaultValue ? String(missingDefaultValue) : '';
};

const gradYearOptions = Array.from({length: 7}, (_, i) => 2022 + i).map(
  (year) => ({
    value: year.toString(),
    label: year.toString(),
  }),
);
