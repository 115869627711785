import {FunctionalComponent} from 'preact';
import {ErrorBoundary, Route, Router} from 'preact-iso';
import {Loading, Responsive, TabMenu, ToastMessage} from 'pylon/ui';
import {UserProfileHeader} from '@/components/(profile)/UserProfileHeader';
import {AppLayout} from '@/components/AppLayout';
import {useUsersProfile} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';
import {PublicUserProfileAlbumRoute} from './PublicUserProfileAlbumRoute';
import {PublicUserProfileAlbumsRoute} from './PublicUserProfileAlbumsRoute';
import {PublicUserProfileOverviewRoute} from './PublicUserProfileOverviewRoute';
import {PublicUserProfilePostRoute} from './PublicUserProfilePostRoute';
import {PublicUserProfilePostsRoute} from './PublicUserProfilePostsRoute';

type Props = {
  params: {
    username: string;
  };
  path: string;
};

export const PublicUserProfileRoute: FunctionalComponent<Props> = ({
  params: {username},
  path,
}) => {
  const {data: profile, loading} = useUsersProfile({
    params: {
      username,
    },
  });

  const isPostPath = path.match(/\/posts\/\d+$/);
  const isAlbumPath = path.match(/\/albums\/\d+$/);

  let content = null;

  if (loading) {
    content = <Loading centered />;
  } else if (profile) {
    content = (
      <div>
        {!isPostPath && !isAlbumPath && (
          <>
            <UserProfileHeader profile={profile} />
            <TabMenu
              noAnimation
              direction="row"
              items={[
                {
                  content: 'Overview',
                  href: ROUTES.PUBLIC_USER_PROFILE.replace(
                    ':username',
                    username,
                  ),
                },
                {
                  content: 'Highlights',
                  href: ROUTES.PUBLIC_USER_PROFILE_POSTS.replace(
                    ':username',
                    username,
                  ),
                },
                {
                  content: 'Albums',
                  href: ROUTES.PUBLIC_USER_PROFILE_ALBUMS.replace(
                    ':username',
                    username,
                  ),
                },
              ]}
              css-mt={18}
              css-mb={10}
            />
          </>
        )}

        <ErrorBoundary>
          <Router>
            <Route path={'/'} component={PublicUserProfileOverviewRoute} />
            <Route
              path={ROUTES.PUBLIC_USER_PROFILE_POSTS.replace(
                ROUTES.PUBLIC_USER_PROFILE,
                '',
              )}
              userId={profile.user.id}
              component={PublicUserProfilePostsRoute}
            />
            <Route
              path={ROUTES.PUBLIC_USER_PROFILE_POST.replace(
                ROUTES.PUBLIC_USER_PROFILE,
                '',
              )}
              component={PublicUserProfilePostRoute}
            />
            <Route
              path={ROUTES.PUBLIC_USER_PROFILE_ALBUMS.replace(
                ROUTES.PUBLIC_USER_PROFILE,
                '',
              )}
              userId={profile.user.id}
              component={PublicUserProfileAlbumsRoute}
            />
            <Route
              path={ROUTES.PUBLIC_USER_PROFILE_ALBUM.replace(
                ROUTES.PUBLIC_USER_PROFILE,
                '',
              )}
              component={PublicUserProfileAlbumRoute}
            />
          </Router>
        </ErrorBoundary>
      </div>
    );
  } else {
    content = <ToastMessage severity="error">User not found.</ToastMessage>;
  }

  return (
    <AppLayout>
      <Responsive size="xxl">{content}</Responsive>
    </AppLayout>
  );
};
