import {ComponentChild, FunctionalComponent} from 'preact';
import {css, styled} from 'styled-components';
import {breakpoints, useWindowSize} from 'pylon/lib';
import {Anchor, Divider, IconButton, Overlay, Typography} from 'pylon/ui';
import {AccountType} from '@shared/database-types';
import {canAddToPlanner, canBelongToTeam, canFollow} from '@shared/permissions';
import {AppContextType, useAppContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {PDAvatar} from '../PDAvatar';
import {PDIcon, PDIconName} from '../PDIcon';
import {AppDrawer_YourTeams} from './_YourTeams';

type Props = {
  openedDrawer: boolean;
  setOpenedDrawer: (value: boolean) => void;
};

export const AppDrawer: FunctionalComponent<Props> = ({
  openedDrawer,
  setOpenedDrawer,
  ...props
}) => {
  const windowSize = useWindowSize();
  const {currentTeam, currentUser} = useAppContext();
  const navItems = getNavItems({
    currentUser,
    currentTeam,
    currentPathname: window.location.pathname,
  });

  let content = (
    <StyledDrawer $openedDrawer={openedDrawer} {...props}>
      {navItems.map((item) => {
        const isActive =
          item.isActive ?? window.location.pathname === item.href;

        return (
          <StyledNavItem $isActive={isActive} href={item.href}>
            <div
              css={`
                display: inline-flex;
                margin-right: var(--gap-3);
              `}
            >
              {!!item.iconContent && item.iconContent}
              {isActive && !!item.activeIcon && (
                <PDIcon icon={item.activeIcon} size={26} />
              )}
              {!isActive && !!item.icon && (
                <PDIcon icon={item.icon} size={26} />
              )}
            </div>
            {item.title}
          </StyledNavItem>
        );
      })}
      {currentUser &&
        canBelongToTeam(currentUser.accountType) &&
        !currentTeam && (
          <>
            <Divider css-mt={4} css-mb={4} />
            <AppDrawer_YourTeams />
          </>
        )}
      <Divider
        css={`
          background: var(--bg-minimal);
          width: calc(100% - var(--gap-2));
          margin: var(--gap-4) auto;
        `}
      />
      <Typography
        variant="body4"
        weight="medium"
        css-color="var(--fg-muted)"
        css-p={[0, 2]}
        css={`
          & > * {
            display: inline-block;
            margin-right: var(--gap-2);
            margin-top: var(--gap-1-5);
          }
        `}
      >
        <Anchor href={ROUTES.PUBLIC_PRIVACY} variant="hoverUnderline">
          Privacy Policy
        </Anchor>
        <Anchor href={ROUTES.PUBLIC_TERMS} variant="hoverUnderline">
          Terms
        </Anchor>
        <div>@2024 Prospect Dugout</div>
      </Typography>
    </StyledDrawer>
  );

  if (windowSize.width < breakpoints.md) {
    content = (
      <Overlay
        opened={openedDrawer}
        setOpened={setOpenedDrawer}
        animation="move-right"
        cancelOnEscKey
        cancelOnOutsideClick
        withBackdrop
        disableBodyScroll
        position={{mode: 'fixed'}}
        rootCss={css`
          margin: 0;
        `}
        css={`
          background: var(--bg-overlay);
          bottom: 0;
          left: 0;
          max-width: 18rem;
          top: 0;
          width: 100%;
        `}
      >
        <div
          css={`
            padding: var(--gap-6) var(--gap-4) 0;
          `}
        >
          <IconButton icon="close" onClick={() => setOpenedDrawer(false)} />
        </div>
        {content}
      </Overlay>
    );
  }

  return content;
};

const StyledDrawer = styled.div<{$openedDrawer: boolean}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--gap-6) var(--gap-3);
  width: 100%;
`;

export const StyledNavItem = styled.a<{$isActive: boolean}>`
  all: unset;
  //
  align-items: center;
  border-radius: var(--radius-2);
  color: ${(p) => (p.$isActive ? 'var(--primary40)' : 'var(--fg-default)')};
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-subtitle-2);
  font-weight: var(--font-weight-bold);
  padding: var(--gap-3);
  transition: background 0.2s ease;
  &:hover {
    background: var(--bg-minimal);
  }
`;

type AppDrawerNavItem = {
  activeIcon?: PDIconName;
  href: string;
  icon?: PDIconName;
  iconContent?: ComponentChild;
  isActive?: boolean;
  title: string;
};

const getNavItems = ({
  currentUser,
  currentTeam,
  currentPathname,
}: {
  currentUser: AppContextType['currentUser'];
  currentTeam: AppContextType['currentTeam'];
  currentPathname: string;
}): AppDrawerNavItem[] => {
  const result = [
    {
      title: 'Home',
      icon: 'home-outline',
      activeIcon: 'home',
      href: ROUTES.PUBLIC_HOME,
    },

    {
      title: 'Search',
      icon: 'search-outline',
      activeIcon: 'search',
      href: ROUTES.PUBLIC_EMPTY_SEARCH_ROUTE,
    },
  ] as AppDrawerNavItem[];

  if (currentTeam) {
    result.push({
      title: currentTeam.name,
      iconContent: (
        <PDAvatar
          team={currentTeam}
          size="md"
          css={`
            width: 1.625rem;
            height: 1.625rem;
          `}
        />
      ),
      href: ROUTES.PUBLIC_TEAM_PROFILE.replace(':slug?', currentTeam.slug),
      isActive:
        currentPathname.indexOf(
          ROUTES.PUBLIC_TEAM_PROFILE.replace(':slug?', currentTeam.slug),
        ) === 0,
    });
  }

  if (currentUser && !currentTeam) {
    if (canFollow(currentUser.accountType)) {
      result.push({
        title: 'Following',
        icon: 'star-outline',
        activeIcon: 'star',
        href: ROUTES.AUTHED_FOLLOWING,
      });
    }

    if (canAddToPlanner(currentUser.accountType)) {
      result.push({
        title: 'Planner',
        icon: 'users-outline',
        activeIcon: 'users',
        href: ROUTES.AUTHED_PROSPECT_PLANNER,
      });
    }
    result.push({
      title: 'Profile',
      iconContent: (
        <PDAvatar
          user={currentUser}
          size="md"
          css={`
            width: 26px;
            height: 26px;
          `}
        />
      ),
      href: ROUTES.PUBLIC_USER_PROFILE.replace(
        ':username',
        currentUser.username,
      ),
      isActive:
        currentPathname.indexOf(
          ROUTES.PUBLIC_USER_PROFILE.replace(':username', currentUser.username),
        ) === 0,
    });
  }

  return result;
};
