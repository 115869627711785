import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {
  Button,
  FormSubmit,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {useAcceptMeInvite, useRejectMeInvite} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {useNetworkError} from '@/lib/use-network-error';

type Props = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
};

export const PendingInviteOverlay: FunctionalComponent<Props> = ({
  opened,
  setOpened,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const {currentUser} = useAuthenticatedContext();
  const [acceptMeInvite] = useAcceptMeInvite();
  const [rejectMeInvite] = useRejectMeInvite();

  const onSubmit = async () => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await acceptMeInvite({
        params: {
          inviteId: currentUser.pendingInvites[0].id,
        },
      });
      setOpened(false);
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
  };

  const handleCancel = async () => {
    setNetworkError(null);
    try {
      await rejectMeInvite({
        params: {
          inviteId: currentUser.pendingInvites[0].id,
        },
      });
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setOpened(false);
  };

  const invite = currentUser.pendingInvites[0];

  if (!invite) {
    return null;
  }

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      closeOthers
      withBackdrop
      animation="scale-in"
      position={{
        mode: 'centered',
      }}
      css={`
        ${overlayPopupCss}
      `}
    >
      <Typography
        variant="h5"
        fontStyle="italic"
        textTransform="uppercase"
        css-mb={4}
      >
        Pending invite
      </Typography>
      <Stack direction="column">
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {networkError}
          </ToastMessage>
        )}
        <Typography>
          You have been invited to join the team{' '}
          <b>{invite.team?.name ?? ''}</b>. Do you want to accept the invite?
        </Typography>
        <Stack css-mt={6} gap={4}>
          <FormSubmit
            variant="primary"
            value="Accept invite"
            submitting={submiting}
            disabled={submiting}
            onClick={onSubmit}
          />
          <Button variant="error-soft" onClick={handleCancel}>
            Reject
          </Button>
        </Stack>
      </Stack>
    </Overlay>
  );
};
