import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {
  Card,
  Form,
  FormCheckbox,
  FormSubmit,
  Stack,
  ToastMessage,
} from 'pylon/ui';
import {
  GetMeEmailNotificationSettings,
  UpdateMeEmailNotificationSettings,
} from '@shared/api-types';
import {ErrorCode} from '@shared/error-code';
import {useUpdateMeEmailNotificationSettings} from '@/fetch';

type FormData = UpdateMeEmailNotificationSettings['request']['body'];

type Props = {
  data: GetMeEmailNotificationSettings['response'];
};

export const UserSettingsEmailNotificationsForm: FunctionalComponent<Props> = ({
  data,
}) => {
  const [updateMeEmailNotificationSettings] =
    useUpdateMeEmailNotificationSettings();
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [submiting, setSubmiting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const {register, handleSubmit} = useForm<FormData>({
    defaultValues: data,
  });

  const onSubmit = async (data: FormData) => {
    setSubmiting(true);
    try {
      await updateMeEmailNotificationSettings({
        body: data,
      });
      setCompleted(true);
    } catch (err: any) {
      setNetworkError(err?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmiting(false);
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        {completed && (
          <ToastMessage
            severity="success"
            css-mb={6}
            onClickClose={() => setCompleted(false)}
          >
            Your notification preferences have been updated.
          </ToastMessage>
        )}
        <Stack
          direction="column"
          gap={6}
          css={`
            font-size: var(--font-size-body-2);
          `}
        >
          <FormCheckbox
            {...register('announcements')}
            id="announcements"
            label="Announcements and update emails"
            helpText={`Receive occasional emails about product launches and new features from ProspectDugout.tv
          `}
            fullWidth
          />
          <FormCheckbox
            {...register('weeklyNewsletter')}
            id="weekly-newsletter"
            label="Weekly newsletter"
            helpText={`Stay updated with our weekly newsletter, featuring the best and most popular content on our platform.`}
            fullWidth
          />
          <FormCheckbox
            {...register('accountActivity')}
            id="account-activity"
            label="Account activity"
            helpText={`Get notified about key actions on your account like new followers, comments, and other interactions to stay updated and engaged.`}
            fullWidth
          />
        </Stack>
        <Stack css-mt={8}>
          <FormSubmit
            variant="primary"
            value="Update email notifications"
            submitting={submiting}
            disabled={submiting}
          />
        </Stack>
      </Form>
    </Card>
  );
};
