import {FunctionalComponent} from 'preact';
import {Controller} from 'react-hook-form';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {
  Fieldset,
  FormDecimalSelect,
  FormSelect,
  Grid,
  Select,
  Stack,
} from 'pylon/ui';
import {playerHasPopTime} from '@shared/helpers';
import {ProfileFormState} from '../ProfileForm';
import {RegisteredNames as PlayerDetailsRegisterdNames} from './_ControlPlayerDetails';

export enum RegisteredNames {
  THROWING_VELOCITY = 'throwingVelocity',
  EXIT_VELOCITY = 'exitVelocity',
  RUNNING_SPEED_30 = 'runningSpeed30',
  RUNNING_SPEED_60 = 'runningSpeed60',
  POP_TIME = 'popTime',
}

export const ProfileForm_ControlPlayerSkills: FunctionalComponent<
  ProfileFormState
> = ({register, control, errors, watch}) => {
  const position1 = watch(PlayerDetailsRegisterdNames.POSITION1);
  const position2 = watch(PlayerDetailsRegisterdNames.POSITION2);
  const velocityOptions = Array.from({length: 91}, (_, i) => 40 + i).map(
    (year) => ({
      value: year,
      label: year.toString() + 'mph',
    }),
  );

  return (
    <>
      <Stack
        gap={8}
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <Controller
          name={RegisteredNames.THROWING_VELOCITY}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.THROWING_VELOCITY}
                label="Throwing velocity"
                options={velocityOptions}
                invalid={!!errors[RegisteredNames.THROWING_VELOCITY]}
                invalidText={
                  errors?.[RegisteredNames.THROWING_VELOCITY]?.message
                }
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
        <Controller
          name={RegisteredNames.EXIT_VELOCITY}
          control={control}
          rules={{required: false}}
          render={({field}) => {
            return (
              <FormSelect
                id={RegisteredNames.EXIT_VELOCITY}
                label="Exit velocity"
                options={velocityOptions}
                invalid={!!errors[RegisteredNames.EXIT_VELOCITY]}
                invalidText={errors?.[RegisteredNames.EXIT_VELOCITY]?.message}
                fullWidth
                {...(field as any)}
              />
            );
          }}
        />
      </Stack>
      <Stack
        gap={8}
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <Controller
          name={RegisteredNames.RUNNING_SPEED_30}
          control={control}
          render={({field: {onChange, value}}) => (
            <FormDecimalSelect
              id={RegisteredNames.RUNNING_SPEED_30}
              label="30-yd running speed (sec)"
              maxWholeNumber={9}
              maxFractionalNumber={99}
              invalid={!!errors[RegisteredNames.RUNNING_SPEED_30]}
              invalidText={errors?.[RegisteredNames.RUNNING_SPEED_30]?.message}
              value={value}
              onChange={onChange}
              fullWidth
            />
          )}
        />
        <Controller
          name={RegisteredNames.RUNNING_SPEED_60}
          control={control}
          render={({field: {onChange, value}}) => (
            <FormDecimalSelect
              id={RegisteredNames.RUNNING_SPEED_60}
              label="60-yd running speed (sec)"
              maxWholeNumber={9}
              maxFractionalNumber={99}
              invalid={!!errors[RegisteredNames.RUNNING_SPEED_60]}
              invalidText={errors?.[RegisteredNames.RUNNING_SPEED_60]?.message}
              value={value}
              onChange={onChange}
              fullWidth
            />
          )}
        />
      </Stack>
      {playerHasPopTime(position1, position2) && (
        <Grid cols="1fr 1fr" gap={8}>
          <Controller
            name={RegisteredNames.POP_TIME}
            control={control}
            render={({field: {onChange, value}}) => (
              <FormDecimalSelect
                id={RegisteredNames.POP_TIME}
                label="Pop time (sec)"
                maxWholeNumber={5}
                maxFractionalNumber={99}
                invalid={!!errors[RegisteredNames.POP_TIME]}
                invalidText={errors?.[RegisteredNames.POP_TIME]?.message}
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
          <div />
        </Grid>
      )}
    </>
  );
};
