import {FunctionalComponent} from 'preact';
import {useRef, useState} from 'preact/hooks';
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadBytes,
} from '@firebase/storage';
import {Controller, useForm} from 'react-hook-form';
import {makeid} from 'pylon/lib';
import {
  Button,
  DescriptionList,
  DescriptionListItem,
  Form,
  FormInput,
  FormSubmit,
  ImageInput,
  Img,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {UpdateMeTeam} from '@shared/api-types';
import {IMAGES_TMP_STORAGE_PATH} from '@shared/constants';
import {ErrorCode} from '@shared/error-code';
import {useUpdateMeTeam} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {isDevelopment, toMediaPath} from '@/lib/environment';
import {PDAvatar} from '../PDAvatar';

type FormData = UpdateMeTeam['request']['body'];

type Props = {};

export const TeamSettingsGeneralForm: FunctionalComponent<Props> = () => {
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [updateMeTeam] = useUpdateMeTeam();
  const {currentUser, currentTeam} = useAuthenticatedContext();
  const tmpImageArrayBuffer = useRef<ArrayBuffer | null>(null);
  const [tmpImageBase64, setTmpImageBase64] = useState<string | null>(null);
  const {
    register,
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      name: currentTeam?.name,
    },
  });

  const uploadToStorate = async () => {
    if (tmpImageArrayBuffer.current) {
      const storage = getStorage();
      if (isDevelopment) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      const resp = await uploadBytes(
        ref(
          storage,
          `${IMAGES_TMP_STORAGE_PATH}/${currentUser.id}/${makeid(10)}.jpg`,
        ),
        tmpImageArrayBuffer.current,
        {contentType: 'image/jpeg'},
      );
      return resp.metadata.fullPath;
    } else {
      return null;
    }
  };

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmitting(true);
    try {
      let tmpAvatarStoragePath: string = '';
      if (tmpImageArrayBuffer.current) {
        const path = await uploadToStorate();
        if (path) {
          tmpAvatarStoragePath = path;
        }
      }
      await updateMeTeam({
        body: {
          teamId: currentTeam!.id,
          name: formData.name.trim(),
          tmpAvatarStoragePath,
        },
      });
      setEditing(false);
      setTmpImageBase64(null);
      tmpImageArrayBuffer.current = null;
    } catch (error: any) {
      setNetworkError(error?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmitting(false);
  };

  const content = (
    <>
      <Typography variant="body2" weight="bold" css-mb={4}>
        General
      </Typography>
      <DescriptionList expandWidthSize={6}>
        <DescriptionListItem title="Team name">
          {editing ? (
            <FormInput
              {...register('name', {required: 'Team Name is required'})}
              id="name"
              defaultValue={currentTeam!.name ?? ''}
              invalid={!!errors.name}
              invalidText={errors?.name?.message}
              fullWidth
              size="sm"
            />
          ) : (
            <>{currentTeam!.name}</>
          )}
        </DescriptionListItem>
        <DescriptionListItem
          title="Verified"
          tooltipProps={{
            text: `Wether or not your team has been verified by ProspectDugout.tv. Verified teams can be identified by the verified badge on their profile`,
          }}
        >
          {currentTeam!.verified ? 'Yes' : 'No'}
        </DescriptionListItem>
        <DescriptionListItem title="Avatar">
          <Stack alignItems="center" gap={4}>
            {tmpImageBase64 ? (
              <Img
                src={tmpImageBase64 ?? toMediaPath(currentTeam!.avatarLarge)}
                css={`
                  background-size: cover;
                  border-radius: 50%;
                  display: block;
                  height: 5.25rem;
                  margin-right: var(--gap-2);
                  width: 5.25rem;
                `}
              />
            ) : (
              <PDAvatar team={currentTeam} size="xl" />
            )}
            {editing && (
              <Controller
                name={`tmpAvatarStoragePath`}
                control={control}
                render={({field: {ref}}) => (
                  <ImageInput
                    ref={ref}
                    aspectFn={() => 1}
                    buttonProps={{
                      buttonSize: 'md',
                      variant: 'outline',
                    }}
                    onSubmit={({base64, arrayBuffer}) => {
                      if (!base64 || !arrayBuffer) return;
                      tmpImageArrayBuffer.current = arrayBuffer;
                      setTmpImageBase64(base64);
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </DescriptionListItem>
      </DescriptionList>
    </>
  );

  if (editing) {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        {content}
        <Stack gap={4} css-mt={8}>
          <FormSubmit
            value="Save"
            buttonSize="md"
            variant="primary"
            submitting={submitting}
          />
          <Button
            buttonSize="md"
            variant="default"
            onClick={() => setEditing(false)}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    );
  } else {
    return (
      <>
        {content}
        <Button
          buttonSize="md"
          variant="default"
          css-mt={8}
          onClick={() => setEditing(true)}
        >
          Edit settings
        </Button>
      </>
    );
  }
};
