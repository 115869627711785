import {FunctionalComponent} from 'preact';
import {ErrorBoundary, Route, Router} from 'preact-iso';
import {Responsive, TabMenu, Typography} from 'pylon/ui';
import {AppLayout} from '@/components/AppLayout';
import {ROUTES} from '@/lib/app-routes';
import {TeamSettingsGeneralRoute} from './TeamSettingsGeneralRoute';
import TeamSettingsInviteMemberRoute from './TeamSettingsInviteMemberRoute';
import {TeamSettingsMembersRoute} from './TeamSettingsMembersRoute';

type Props = {};

export const TeamSettingsRoute: FunctionalComponent<Props> = (props) => {
  return (
    <AppLayout>
      <Responsive size="lg">
        {props.path !== '/team-settings/invite-member' && (
          <>
            <Typography
              variant="h4"
              textTransform="uppercase"
              fontStyle="italic"
              css-mb={6}
              css-pt={12}
            >
              Team settings
            </Typography>
            <TabMenu
              noAnimation
              direction="row"
              items={[
                {
                  content: 'General',
                  href: ROUTES.AUTHED_TEAM_SETTINGS_GENERAL,
                },
                {
                  content: 'Members',
                  href: ROUTES.AUTHED_TEAM_SETTINGS_MEMBERS,
                },
              ]}
              css-mb={8}
            />
          </>
        )}
        <ErrorBoundary>
          <Router>
            <Route
              path={ROUTES.AUTHED_TEAM_SETTINGS_GENERAL.replace(
                ROUTES.AUTHED_TEAM_SETTINGS,
                '',
              )}
              component={TeamSettingsGeneralRoute}
            />
            <Route
              path={ROUTES.AUTHED_TEAM_SETTINGS_MEMBERS.replace(
                ROUTES.AUTHED_TEAM_SETTINGS,
                '',
              )}
              component={TeamSettingsMembersRoute}
            />
            <Route
              path={ROUTES.AUTHED_TEAM_SETTINGS_INVITE_MEMBER.replace(
                ROUTES.AUTHED_TEAM_SETTINGS,
                '',
              )}
              component={TeamSettingsInviteMemberRoute}
            />
          </Router>
        </ErrorBoundary>
      </Responsive>
    </AppLayout>
  );
};
