import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {
  Button,
  DropdownMenu,
  EmptyMessage,
  FormSubmit,
  IconButton,
  Overlay,
  Stack,
  Table,
  TableColumn,
  ToastMessage,
  Typography,
  menuItemVariant,
  overlayPopupCss,
} from 'pylon/ui';
import {GetMeTeamMembers} from '@shared/api-types';
import {UserTeamRole} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {useDeleteMeInvite} from '@/fetch';
import {useAuthenticatedAsTeamContext} from '@/lib/app-context';
import {timeAgo} from '@/lib/dayjs';
import {capitalize} from '@/lib/utils';

type Invite = GetMeTeamMembers['response']['invites'][0];

type Props = {
  currentTeamInvites: Invite[];
};

export const TeamSettingsInvitesTable: FunctionalComponent<Props> = ({
  currentTeamInvites,
}) => {
  const {currentTeam} = useAuthenticatedAsTeamContext();
  const [deleteInvite] = useDeleteMeInvite();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [errorDeleting, setErrorDeleting] = useState<string | null>(null);
  const [inviteToDelete, setInviteToDelete] = useState<Invite | null>(null);

  const handleDeleteInvite = async (invite: Invite) => {
    setDeleting(true);
    try {
      await deleteInvite({
        params: {
          inviteId: invite.id,
        },
      });
      setInviteToDelete(null);
    } catch (err: any) {
      setErrorDeleting(err?.message ?? ErrorCode.SERVER_ERROR);
    }
    setDeleting(false);
  };

  useEffect(() => {
    if (!inviteToDelete) {
      setErrorDeleting(null);
    }
  }, [inviteToDelete]);

  if (!currentTeamInvites?.length) {
    return (
      <EmptyMessage>
        Click 'Add member' to invite someone to your team.
      </EmptyMessage>
    );
  }

  const columns: TableColumn<Invite>[] = [
    {
      accessor: 'toEmail',
      type: 'string',
      title: 'Email',
      cellCssFn: () => `vertical-align: middle;`,
    },
    {
      accessor: 'accountType',
      type: 'string',
      title: 'Account type',
      cellCssFn: () => `vertical-align: middle;`,
      formatter: capitalize,
    },
    {
      accessor: 'createdAt',
      title: 'Create at',
      type: 'date',
      cellCssFn: () => `vertical-align: middle;`,
      formatter: timeAgo,
    },
    ...(currentTeam.role === UserTeamRole.OWNER
      ? ([
          {
            accessor: 'actions',
            noSortable: true,
            cellCssFn: () => `vertical-align: middle;`,
            renderContent: ({d}) => (
              <DropdownMenu>
                <IconButton variant="muted" icon="ellipsis-horizontal" />
                <Button
                  customVariant={menuItemVariant}
                  onClick={() => setInviteToDelete(d)}
                >
                  Delete invite
                </Button>
              </DropdownMenu>
            ),
          },
        ] as TableColumn<Invite>[])
      : []),
  ];

  return (
    <>
      <Table
        columns={columns}
        data={currentTeamInvites}
        propertyForKey="toEmail"
        sortBy="-createdAt"
      />
      {inviteToDelete && (
        <Overlay
          opened
          setOpened={() => setInviteToDelete(null)}
          withBackdrop
          cancelOnEscKey
          cancelOnOutsideClick
          position={{
            mode: 'centered',
          }}
          css={`
            ${overlayPopupCss}
          `}
        >
          <Typography variant="body1" weight="bold" css-mb={4}>
            Are you sure you want to delete the invite to{' '}
            {inviteToDelete.toEmail}?
          </Typography>
          {errorDeleting && (
            <ToastMessage severity="error">{errorDeleting}</ToastMessage>
          )}
          <Stack css-mt={6} gap={4}>
            <FormSubmit
              variant="primary"
              submitting={deleting}
              onClick={() => handleDeleteInvite(inviteToDelete)}
            >
              Delete invite
            </FormSubmit>
            <Button
              variant="muted"
              onClick={() => {
                setInviteToDelete(null);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Overlay>
      )}
    </>
  );
};
