import {
  GoogleAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopup,
} from '@firebase/auth';
import {ROUTES} from './app-routes';

export const AFTER_LOGIN_REDIRECT = ROUTES.PUBLIC_HOME;

export const signInWithEmail = async () => {
  const firebaseAuth = getAuth();
  const email = getEmail();

  if (!email) {
    throw new Error('No email provided');
  }

  if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
    const userCredential = await signInWithEmailLink(
      firebaseAuth,
      email,
      window.location.href,
    );
    return userCredential.user;
  }

  throw new Error('Invalid sign-in link');
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/plus.login');
  await signInWithPopup(getAuth(), provider);
};

function getEmail(): string | null {
  let email = window.localStorage.getItem('emailForSignIn');
  const params = new URLSearchParams(window.location.search);
  const emailFromUrl = params.get('email');

  if (emailFromUrl) {
    email = emailFromUrl;
  }

  /* window.localStorage.removeItem('emailForSignIn'); */

  if (!email) {
    // User opened the link on a different device. To prevent session fixation attacks,
    // ask the user to provide the associated email again.
    email = window.prompt('Please provide your email') ?? '';
  }
  return email;
}
