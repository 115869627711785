export const ENDPOINTS = {
  // Private
  ME: '/me',
  ME_USER_PROFILE_NOTES: '/me/:targetUserId/notes',
  ME_USER_FOLLOW: '/me/users/:userId/follow',
  ME_TEAMS: '/me/teams',
  ME_TEAM_MEMBERS: '/me/teams/:teamId/members',
  ME_TEAM_MEMBER: '/me/teams/:teamId/members/:userId',
  ME_TEAM_FOLLOW: '/me/teams/:teamId/follow',
  ME_TEAM_PROFILE: '/me/teams/:teamId/profile',
  ME_SETTINGS_EMAIL_NOTIFICATIONS: '/me/settings/email-notifications',
  ME_PROSPECTS: '/me/prospects/:offset/:limit',
  ME_PROSPECT_TAGS: '/me/prospect-tags',
  ME_PROSPECT: '/me/prospects',
  ME_PROFILE: '/me/profile',
  ME_POSTS: '/me/posts/:postId?',
  ME_POST_LIKE: '/me/posts/:postId/like',
  ME_POST_COMMENTS: '/me/posts/:postId/comments',
  ME_POST_COMMENT: '/me/posts/:postId/comments/:commentId',
  ME_ORGANIZATIONS: '/me/organizations',
  ME_NOTIFICATIONS: '/me/notifications/:offset/:limit',
  ME_NOTIFICATIONS_UNREAD_COUNT: '/me/notifications/unread-count',
  ME_NOTIFICATIONS_MARK_AS_READ: '/me/notifications/mark-as-read',
  ME_NOTES: '/me/notes',
  ME_INVITES: '/me/invites',
  ME_INVITE: '/me/invites/:inviteId',
  ME_INVITE_ACCEPT: '/me/invites/:inviteId/accept',
  ME_FOLLOWING: '/me/following/:offset/:limit',
  ME_ALBUMS: '/me/albums',
  ME_ALBUM: '/me/albums/:albumId',
  ME_EVENTS: '/me/events',
  ME_EVENT: '/me/events/:eventId',

  // Public
  ALBUM: '/albums/:albumId',
  SEARCH: '/search',
  TEAM_PROFILE: '/teams/:slug/profile',
  TEAM_MEMBERS: '/teams/:teamId/members',
  USER_ALBUMS: '/users/:userId/albums',
  USER_POST: '/users/:username/posts/:postId',
  USER_POSTS: '/users/:userId/posts',
  POST_COMMENTS: '/posts/:postId/comments',
  USER_PROFILE: '/users/:username/profile',

  POSTS: '/posts/:postId?',
  DISCOVER_RECENT_PROFILES: '/discover/recent-profiles/:offset/:limit',
  DISCOVER_RECENT_POSTS: '/discover/recent-posts/:offset/:limit',
  DISCOVER_RECENT_TEAMS: '/discover/recent-teams/:offset/:limit',

  SUPER_USER: '/super-user',
};
