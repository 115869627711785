import {FunctionalComponent} from 'preact';
import {Responsive, Typography} from 'pylon/ui';
import {AppLayout} from '@/components/AppLayout';

type Props = {};

export const PublicTermsRoute: FunctionalComponent<Props> = () => {
  return (
    <AppLayout>
      <Responsive size="xl">
        <Typography
          variant="h4"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={4}
        >
          Terms of Service
        </Typography>
        <Typography>Coming soon...</Typography>
      </Responsive>
    </AppLayout>
  );
};
