import {FunctionalComponent} from 'preact';
import {useLocation} from 'preact-iso';
import {useEffect, useState} from 'preact/hooks';
import {css} from 'styled-components';
import {Icon, Input} from 'pylon/ui';
import {ROUTES} from '@/lib/app-routes';

type Props = {
  containerCss?: ReturnType<typeof css>;
};

export const SearchInput: FunctionalComponent<Props> = ({containerCss}) => {
  const location = useLocation();
  const [defaultSearch, setDefaultSearch] = useState<string | null>(null);

  useEffect(() => {
    const searchValue = window.location.pathname.match(/\/search\/(.*)/);
    setDefaultSearch(searchValue?.[1] ?? null);
  }, []);

  const handleSearchKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      const searchValue = (evt.target as HTMLInputElement).value;
      const queryParams = window.location.search;

      location.route(
        `${ROUTES.PUBLIC_SEARCH_ROUTE.replace(':searchValue', searchValue)}${queryParams ? `${queryParams}` : ''}`,
      );
    }
  };

  return (
    <Input
      type="search"
      placeholder="Search"
      variant="search"
      defaultValue={defaultSearch ?? ''}
      startAdornment={
        <Icon icon="search" size={20} css-color="var(--fg-muted)" />
      }
      onKeyDown={handleSearchKeyDown}
      containerCss={containerCss}
    />
  );
};
