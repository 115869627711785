export enum ErrorCode {
  BAD_REQUEST = 'BAD_REQUEST',
  FORBIDDEN = 'FORBIDDEN',
  SERVER_ERROR = 'SERVER_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  //
  INVALID_ALBUM_NAME = 'INVALID_ALBUM_NAME',
  INVALID_CITY = 'INVALID_CITY',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_GRAD_YEAR = 'INVALID_GRAD_YEAR',
  INVALID_ORGANIZATION_NAME = 'INVALID_ORGANIZATION_NAME',
  INVALID_ORGANIZATION_SLUG = 'INVALID_ORGANIZATION_SLUG',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  INVALID_PLAYER_HAND = 'INVALID_PLAYER_HAND',
  INVALID_PLAYER_LEVEL = 'INVALID_PLAYER_LEVEL',
  INVALID_PLAYER_POSITION = 'INVALID_PLAYER_POSITION',
  INVALID_POST_CONTENT = 'INVALID_POST_CONTENT',
  INVALID_PROFILE_BIO = 'INVALID_PROFILE_BIO',
  INVALID_STATE = 'INVALID_STATE',
  INVALID_TEAM_NAME = 'INVALID_TEAM_NAME',
  INVALID_URL = 'INVALID_URL',
  INVALID_USERNAME = 'INVALID_USERNAME',
  INVALID_VALUE = 'INVALID_VALUE',
  INVITE_ACCOUNT_TYPE_MISMATCH = 'INVITE_ACCOUNT_TYPE_MISMATCH',
  INVITE_NOT_FOUND = 'INVITE_NOT_FOUND',
  MISSING_ACCOUNT_TYPE = 'MISSING_ACCOUNT_TYPE',
  MISSING_FIELD = 'MISSING_FIELD',
  MISSING_VIDEO = 'MISSING_VIDEO',
  MUST_AGREE_TO_TERMS = 'MUST_AGREE_TO_TERMS',
  OFFSET_AND_LIMIT_REQUIRED = 'OFFSET_AND_LIMIT_REQUIRED',
  POST_NOT_FOUND = 'POST_NOT_FOUND',
  PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  TEAM_NOT_FOUND = 'TEAM_NOT_FOUND',
  USER_ALREADY_BELONGS_TO_TEAM = 'USER_ALREADY_BELONGS_TO_TEAM',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  USER_ALREADY_INVITED = 'USER_ALREADY_INVITED',
  USER_ALREADY_OWN_ORGANIZATION = 'USER_ALREADY_OWN_ORGANIZATION',
  USER_DOES_NOT_BELONG_TO_TEAM = 'USER_DOES_NOT_BELONG_TO_TEAM',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USERNAME_ALREADY_EXISTS = 'USERNAME_ALREADY_EXISTS',
}
