import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {
  Anchor,
  Button,
  Loading,
  Responsive,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {GetMeNotifications} from '@shared/api-types';
import {NotificationType} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {AppLayout} from '@/components/AppLayout';
import {PDAvatar} from '@/components/PDAvatar';
import {useCreateMeNotificationMarkAsRead, useMeNotifications} from '@/fetch';
import {AppContextType, useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {timeAgo} from '@/lib/dayjs';

type Props = {};

export const AuthedNotificationsRoute: FunctionalComponent<Props> = () => {
  const {currentUser} = useAuthenticatedContext();
  const [pagination, setPagination] = useState({limit: 50, offset: 0});
  const [createMeNotificationMarkAsRead] = useCreateMeNotificationMarkAsRead();
  const {data, error, loading} = useMeNotifications({
    params: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  useEffect(() => {
    let invokeJob: NodeJS.Timeout;
    if (data?.rows?.length) {
      const hasUnreads = data.rows.some((notification) => !notification.isRead);

      invokeJob = setTimeout(async () => {
        if (hasUnreads) {
          try {
            await createMeNotificationMarkAsRead({
              body: {
                ids: data.rows.map((notification) => notification.id),
              },
            });
          } catch (err) {
            console.log(err);
          }
        }
      }, 1000);
    }

    return () => {
      clearTimeout(invokeJob);
    };
  }, [data]);

  let content;

  if (loading) {
    content = (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  } else {
    const {rows} = data;

    if (rows.length === 0) {
      content = (
        <Stack
          direction="column"
          alignItems="center"
          gap={1}
          css-pt={4}
          css-pb={4}
        >
          <Typography weight="medium">All caught up</Typography>
          <Typography css-color="var(--fg-muted)">
            You have no notifications
          </Typography>
        </Stack>
      );
    } else {
      content = (
        <>
          <Stack direction="column" gap={6}>
            {rows.map((notification) => {
              return (
                <Stack key={notification.id} gap={4} alignItems="center">
                  <Anchor
                    variant="unstyled"
                    href={toUserProfileUrl(notification.triggeredByUserId)}
                  >
                    <PDAvatar user={notification.triggeredBy} size="md" />
                  </Anchor>
                  <span>{notificationToText(notification, currentUser)}</span>
                  <span
                    css={`
                      color: var(--fg-muted);
                      margin-left: auto;
                    `}
                  >
                    {timeAgo(notification.createdAt)}
                  </span>
                </Stack>
              );
            })}
          </Stack>
          {rows.length === pagination.limit && (
            <Stack css-mt={10}>
              <Button
                onClick={() => {
                  setPagination((old) => ({
                    ...old,
                    limit: old.limit + 25,
                  }));
                }}
              >
                Load more
              </Button>
            </Stack>
          )}
        </>
      );
    }
  }

  return (
    <AppLayout>
      <Responsive size="xl">
        <Typography
          variant="h4"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={8}
        >
          Notifications
        </Typography>
        {content}
      </Responsive>
    </AppLayout>
  );
};

const notificationToText = (
  notification: GetMeNotifications['response']['rows'][0],
  currentUser: NonNullable<AppContextType['currentUser']>,
) => {
  const {
    recipientUserId,
    triggeredBy,
    notificationType,
    relatedId,
    relatedObject,
  } = notification;
  /*
  if (notificationType === NotificationType.POST_COMMENT) {
    return (
      <p>
        <Anchor href={toUserProfileUrl(triggeredBy.username)} variant="primary">
          {triggeredBy.firstName} {triggeredBy.lastName}
        </Anchor>{' '}
        commented your post{' '}
        <Anchor href={toPostUrl(currentUser.username, relatedId!)} variant="primary">
          {relatedObject?.content}
        </Anchor>{' '}
      </p>
    );
  } */
  if (notificationType === NotificationType.POST_LIKE) {
    return (
      <p>
        <Anchor href={toUserProfileUrl(triggeredBy.username)} variant="primary">
          {triggeredBy.firstName} {triggeredBy.lastName}
        </Anchor>{' '}
        liked your{' '}
        <Anchor
          href={toPostUrl(currentUser.username, relatedId!)}
          variant="primary"
        >
          post {relatedObject?.content}
        </Anchor>{' '}
      </p>
    );
  }
  if (notificationType === NotificationType.FOLLOW) {
    return (
      <p>
        <Anchor href={toUserProfileUrl(triggeredBy.username)} variant="primary">
          {triggeredBy.firstName} {triggeredBy.lastName}
        </Anchor>{' '}
        followed you
      </p>
    );
  }

  return 'Unknown notification';
};

const toUserProfileUrl = (username: string) => {
  return ROUTES.PUBLIC_USER_PROFILE.replace(':username', username);
};

const toPostUrl = (username: string, postId: number) => {
  return ROUTES.PUBLIC_USER_PROFILE_POST.replace(':username', username).replace(
    ':postId',
    String(postId),
  );
};
