import {FunctionalComponent} from 'preact';
import {Responsive} from 'pylon/ui';
import {AppLayout} from '@/components/AppLayout';
import {RecentMembers} from '@/components/RecentMembers';
import {RecentPosts} from '@/components/RecentPosts';
import {RecentTeams} from '@/components/RecentTeams';

type Props = {};

export const PublicHomeRoute: FunctionalComponent<Props> = () => {
  return (
    <AppLayout>
      <Responsive size="xl">
        <RecentPosts />
        <RecentMembers
          css={`
            margin-top: var(--gap-8);
          `}
        />
        <RecentTeams
          css={`
            margin-top: var(--gap-8);
          `}
        />
      </Responsive>
    </AppLayout>
  );
};
