import {ComponentType} from 'preact';
import {LocationHook} from 'preact-iso';
import {Loading, Redirect, Stack} from 'pylon/ui';
import {useAppContext} from './app-context';
import {ROUTES} from './app-routes';

export const withAuth = (
  Component: ComponentType<any>,
  mode?:
    | 'onlyAuthenticated'
    | 'onlyNotAuthenticated'
    | 'onlyAuthenticatedAsUser'
    | 'onlyAuthenticatedAsTeam',
) => {
  return function WithAuthComponent(props: LocationHook) {
    const {
      currentFirebaseUser,
      currentTeam,
      currentUser,
      loadingFirebaseUser,
      loadingUser,
    } = useAppContext();

    const content = <Component {...props} />;

    if (loadingFirebaseUser || loadingUser) {
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          css={`
            height: 100vh;
            width: 100vw;
          `}
        >
          <Loading centered />
        </Stack>
      );
    }

    if (
      currentFirebaseUser &&
      !currentUser &&
      !window.location.pathname.includes(ROUTES.AUTHED_GET_STARTED)
    ) {
      // Redirect to onboarding if user is authenticated but has no data
      return <Redirect to={ROUTES.AUTHED_GET_STARTED} replace />;
    }

    /* prettier-ignore */
    switch (mode) {
      case 'onlyAuthenticated':
        return currentFirebaseUser ? content : <Redirect to={ROUTES.PUBLIC_HOME} />;
      case 'onlyNotAuthenticated':
        return currentFirebaseUser ? <Redirect to={ROUTES.PUBLIC_HOME} /> : content;
      case 'onlyAuthenticatedAsTeam':
        return (currentFirebaseUser && currentTeam) ? content : <Redirect to={ROUTES.PUBLIC_HOME} />;
      case 'onlyAuthenticatedAsUser':
        return (currentFirebaseUser && !currentTeam) ? content : <Redirect to={ROUTES.PUBLIC_HOME} />;
      default:
        return content;
    }
  };
};
