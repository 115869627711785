import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {IconButton, Tooltip} from 'pylon/ui';
import {User} from '@shared/database-types';
import {useMeUserProfileNotes} from '@/fetch';
import {PDIcon} from '../PDIcon';
import {NotesButton_FormOverlay} from './_FormOverlay';

type Props = {
  targetUserId: User['id'];
};

export const NotesButton: FunctionalComponent<Props> = ({targetUserId}) => {
  const [opened, setOpened] = useState(false);
  const {data, error, loading} = useMeUserProfileNotes({
    params: {
      targetUserId,
    },
  });

  if (error) {
    return null;
  }

  return (
    <div>
      <Tooltip text="Notes">
        <IconButton
          disabled={loading}
          onClick={() => {
            if (!loading) {
              setOpened(true);
            }
          }}
          icon={<PDIcon icon="document-text-outline" />}
        />
      </Tooltip>
      {!loading && (
        <NotesButton_FormOverlay
          opened={opened}
          setOpened={setOpened}
          targetUserId={targetUserId}
          defaultNote={data || null}
        />
      )}
    </div>
  );
};
