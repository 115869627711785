import {createFetcher, createUseMutation, createUseQuery} from 'pylon/lib';
import {FUNCTIONS_URL} from '@/lib/environment';
import {useFirebase} from '@/lib/use-firebase';

export const fetcher = createFetcher(FUNCTIONS_URL);
export const useQuery = createUseQuery(fetcher, useFirebase);
export const useMutation = createUseMutation(fetcher, useFirebase);

export * from './queries';
export * from './mutations';
