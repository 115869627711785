import {FunctionalComponent} from 'preact';
import {
  Button,
  DropdownMenu,
  IconButton,
  Typography,
  menuItemVariant,
} from 'pylon/ui';
import {SuperUserAction} from '@shared/api-types';
import {Post, Team, User} from '@shared/database-types';
import {useSuperUser} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {PDIcon} from '../PDIcon';

type Props = {
  postId?: Post['id'];
  teamId?: Team['id'];
  userId?: User['id'];
};

export const SuperUserButton: FunctionalComponent<Props> = ({
  postId,
  teamId,
  userId,
}) => {
  const {currentUser} = useAuthenticatedContext();
  const [runAction] = useSuperUser();

  if (!currentUser.isSuperUser) {
    return null;
  }

  const submit = async (action: SuperUserAction, objectId: number | string) => {
    await runAction({
      body: {
        action,
        objectId,
      },
    });
    window.location.reload();
  };

  return (
    <DropdownMenu>
      <IconButton variant="error-soft" icon={<PDIcon icon="superadmin" />} />
      <Typography
        variant="body3"
        weight="bold"
        css-p={[2, 5, 1]}
        css-color="var(--error-dark)"
      >
        SuperUser Actions
      </Typography>
      {!!userId && (
        <>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.VERIFY_USER, userId)}
          >
            Verify user
          </Button>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.UNVERIFY_USER, userId)}
          >
            Unverify user
          </Button>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.ADD_SUPER_USER, userId)}
          >
            Add SuperUser
          </Button>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.REMOVE_SUPER_USER, userId)}
          >
            Remove SuperUser
          </Button>
        </>
      )}
      {!!teamId && (
        <>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.VERIFY_TEAM, teamId)}
          >
            Verify team
          </Button>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.UNVERIFY_TEAM, teamId)}
          >
            Unverify team
          </Button>
        </>
      )}
      {!!postId && (
        <>
          <Button
            customVariant={menuItemVariant}
            onClick={() => submit(SuperUserAction.REMOVE_POST, postId)}
          >
            Remove post
          </Button>
        </>
      )}
    </DropdownMenu>
  );
};
