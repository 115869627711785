import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {css} from 'styled-components';
import {Anchor, Button, Card, Stack, ToastMessage, Typography} from 'pylon/ui';
import {UserSettingsCreateTeamFormOverlay} from '@/components/(user-settings)/UserSettingsCreateTeamFormOverlay';
import {OrganizationForm} from '@/components/OrganizationForm';
import {useAuthenticatedContext} from '@/lib/app-context';
import {UserSettingsTeamsTable} from '../../../components/(user-settings)/UserSettingsTeamsTable';

type Props = {};

export const UserSettingsOrganizationAndTeamsRoute: FunctionalComponent<
  Props
> = () => {
  const [showCreateTeamForm, setShowCreateTeamForm] = useState(false);
  const {currentUser} = useAuthenticatedContext();

  return (
    <Stack direction="column" gap={4}>
      <Card>
        <Typography variant="body2" weight="bold" css-mb={4}>
          Organization
        </Typography>
        {!currentUser.organization ? (
          <>
            <Typography css-mb={6}>
              Before creating any teams, please register your organization's
              name.
            </Typography>
            <OrganizationForm />
          </>
        ) : (
          <>
            <Typography>
              You are the owner of the organization{' '}
              <b>{currentUser.organization.name}</b>.
              <p>
                If you need to change your organization's name or delete it,
                please contact{' '}
                <Anchor
                  href="mailto:support@prospectdugout.com"
                  variant="underline"
                >
                  support@prospectdugout.com
                </Anchor>
              </p>
            </Typography>
          </>
        )}
      </Card>
      {!!currentUser.organization && (
        <>
          <Card>
            <Typography variant="body2" weight="bold" css-mb={4}>
              Teams
            </Typography>
            {currentUser.teams.length < 1 ? (
              <ToastMessage
                contentCss={css`
                  justify-content: center;
                `}
              >
                You're not a member of any teams yet.
              </ToastMessage>
            ) : (
              <UserSettingsTeamsTable teams={currentUser.teams} />
            )}
            <Button
              variant="default"
              buttonSize="md"
              css-mt={6}
              onClick={() => setShowCreateTeamForm(true)}
            >
              Create team
            </Button>
            <UserSettingsCreateTeamFormOverlay
              opened={showCreateTeamForm}
              setOpened={setShowCreateTeamForm}
            />
          </Card>
        </>
      )}
    </Stack>
  );
};
