import {FunctionalComponent} from 'preact';
import {Card, Redirect, Responsive} from 'pylon/ui';
import {Post} from '@/components/(post)/Post';
import {useUserPost} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';

type Props = {
  params: {
    postId: string;
    username: string;
  };
};

export const PublicUserProfilePostRoute: FunctionalComponent<Props> = ({
  params: {postId, username},
}: Props) => {
  const {
    data: post,
    error,
    loading,
  } = useUserPost({
    params: {
      username,
      postId: Number(postId),
    },
  });

  if (loading) {
    return null;
  } else if (error) {
    return (
      <Redirect
        to={ROUTES.PUBLIC_USER_PROFILE.replace(':username', username)}
      />
    );
  }

  return (
    <Responsive size="lg">
      <div
        css={`
          display: flex;
          justify-content: center;
        `}
      >
        <Card
          css={`
            display: inline-flex;
            padding: 0;
          `}
        >
          <Post
            post={post}
            viewport={{
              height: 800,
              width: window.innerWidth,
            }}
          />
        </Card>
      </div>
    </Responsive>
  );
};
