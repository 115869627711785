import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {css} from 'styled-components';
import {mediaQuery, useSearchParams} from 'pylon/lib';
import {Button, Img, Stack, Typography} from 'pylon/ui';
import {GetUserProfile} from '@shared/api-types';
import {canAddNotes} from '@shared/permissions';
import {useAppContext} from '@/lib/app-context';
import {toMediaPath} from '@/lib/environment';
import {userInfoDisplay} from '@/lib/utils';
import {FollowUserButton} from '../(follow)/FollowUserButton';
import {NotesButton} from '../NotesButton';
import {PDAvatar} from '../PDAvatar';
import {PDIcon} from '../PDIcon';
import {ProfileForm} from '../ProfileForm';
import {ProspectButton} from '../ProspectButton';
import {SuperUserButton} from '../SuperUserButton/SuperUserButton';

type Props = {
  profile: GetUserProfile['response'];
};

export const UserProfileHeader: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  const {currentUser} = useAppContext();
  const [openedEdit, setOpenedEdit] = useState(false);
  const {queryParams, removeQP} = useSearchParams(['edit']);
  const hasCoverPhoto = !!profile.coverPhoto;
  const isVerified = profile.user.verified;

  useEffect(() => {
    if (queryParams.edit) {
      setOpenedEdit(true);
      removeQP('edit');
    }
  }, [queryParams.edit]);

  return (
    <div
      css={`
        display: grid;
        gap: var(--gap-10);
        ${hasCoverPhoto &&
        css`
          grid-template-rows: auto 1fr;
          grid-template-areas: 'cover' 'info';
          ${mediaQuery(
            'md>',
            css`
              grid-template-areas: 'info cover';
              grid-template-columns: 1fr 1fr;
              grid-template-rows: auto;
            `,
          )}
        `}
      `}
      {...props}
    >
      <Stack direction="column" alignItems="flex-start">
        <div
          css={`
            position: relative;
          `}
        >
          <PDAvatar
            user={profile.user}
            size="lg"
            css={`
              grid-area: info;
              font-size: var(--font-size-h5);
              height: 4.25rem;
              width: 4.25rem;
              ${mediaQuery(
                'md>',
                css`
                  height: 5.25rem;
                  width: 5.25rem;
                `,
              )}
            `}
          />
          {isVerified && (
            <PDIcon
              icon="verified"
              size={32}
              css={`
                background: white;
                border-radius: 50%;
                color: var(--yellow30);
                position: absolute;
                right: -4px;
                top: -4px;
              `}
            />
          )}
        </div>
        <Typography
          variant="h2"
          textTransform="uppercase"
          fontStyle="italic"
          css-mt={8}
          css={`
            letter-spacing: -0.035em;
            position: relative;
          `}
        >
          {profile.user.firstName} {profile.user.lastName}
        </Typography>
        <Typography variant="h5" css-mt={2}>
          {userInfoDisplay({
            ...profile,
            accountType: profile.user.accountType,
          })}
        </Typography>
        <Typography
          css-mt={6}
          css-color="var(--fg-muted)"
          css={`
            display: flex;
            align-items: center;
            gap: var(--gap-6);
          `}
        >
          <span>{profile.followingCount} following</span>
          <span>{profile.followersCount} followers</span>
        </Typography>
        {currentUser && (
          <Stack gap={4} css-mt={10}>
            <ProspectButton profile={profile} />
            <FollowUserButton profile={profile} />
            {currentUser.id === profile.user.id && (
              <>
                <Button variant="outline" onClick={() => setOpenedEdit(true)}>
                  Edit profile
                </Button>
                <ProfileForm
                  opened={openedEdit}
                  setOpened={setOpenedEdit}
                  profile={profile}
                />
              </>
            )}
            {canAddNotes(currentUser.accountType) &&
              currentUser.id !== profile.user.id && (
                <NotesButton targetUserId={profile.user.id} />
              )}
            {currentUser.isSuperUser && (
              <SuperUserButton userId={profile.user.id} />
            )}
          </Stack>
        )}
      </Stack>
      {hasCoverPhoto && (
        <Img
          src={toMediaPath(profile.coverPhoto)}
          css={`
            background-size: cover;
            border-radius: var(--radius-4);
            grid-area: cover;
            height: 100%;
            margin-left: auto;
            max-width: 578px;
            width: 100%;
            &::before {
              content: '';
              display: block;
              padding-top: 75%;
            }
            ${mediaQuery(
              'md>',
              css`
                border-radius: 2.1875rem;
              `,
            )}
          `}
        />
      )}
    </div>
  );
};
