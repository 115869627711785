import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {css} from 'styled-components';
import {mediaQuery, toFloatOrNull} from 'pylon/lib';
import {
  Button,
  Form,
  FormSubmit,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {GetUserProfile, UpdateMeProfile} from '@shared/api-types';
import {ErrorCode} from '@shared/error-code';
import {ProfileForm_ControlPlayerDetails} from '@/components/ProfileForm/(controls)/_ControlPlayerDetails';
import {useUpdateMeProfile} from '@/fetch';
import {StepComponent} from '../types';

type FormData = UpdateMeProfile['request']['body'];

type Props = StepComponent;

export const GetStartedProcess_StepPlayerDetails: FunctionalComponent<
  Props
> = ({onNext, onSkip, profile}) => {
  const [submiting, setSubmiting] = useState(false);
  const [updateMeProfile] = useUpdateMeProfile();
  const [networkError, setNetworkError] = useState<string | null>(null);

  const {
    formState: {errors},
    handleSubmit,
    register,
    control,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: sanitizeFormData(profile),
  });

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await updateMeProfile({
        body: {
          ...formData,
          ...sanitizeFormData(formData),
        },
      });
      onNext();
    } catch (error: any) {
      setNetworkError(error?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmiting(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h3"
        tagName="h1"
        textTransform="uppercase"
        fontStyle="italic"
        css-mb={4}
      >
        Your Playing Profile
      </Typography>
      <Typography css-mb={12} css-color="var(--fg-muted)">
        Share your playing level, positions, and team information to highlight
        your journey.
      </Typography>
      {networkError && (
        <ToastMessage severity="error" css-mb={5}>
          {ErrorCode.SOMETHING_WENT_WRONG}
        </ToastMessage>
      )}
      <Stack direction="column" gap={8}>
        <ProfileForm_ControlPlayerDetails
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
      </Stack>
      <Stack
        css-mt={8}
        gap={2}
        justifyContent="flex-start"
        direction="column"
        css={`
          ${mediaQuery(
            'md>',
            css`
              flex-direction: row-reverse;
              gap: var(--gap-8);
            `,
          )}
        `}
      >
        <FormSubmit
          variant="primary"
          value="Continue"
          buttonSize="lg"
          submitting={submiting}
          disabled={submiting}
        />
        <Button variant="muted" buttonSize="lg" onClick={onSkip}>
          Skip for now
        </Button>
      </Stack>
    </Form>
  );
};

function sanitizeFormData(formData: GetUserProfile['response'] | FormData) {
  return {
    battingHand: formData.battingHand ?? null,
    gradYear: toFloatOrNull(formData.gradYear),
    level: formData.level ?? null,
    mostRecentTeam: formData.mostRecentTeam ?? null,
    position1: formData.position1 ?? null,
    position2: formData.position2 ?? null,
    throwingHand: formData.throwingHand ?? null,
  };
}
