import {FunctionalComponent} from 'preact';
import {ErrorBoundary, Route, Router} from 'preact-iso';
import {Redirect, Responsive, TabMenu, Typography} from 'pylon/ui';
import {canCreateTeam} from '@shared/permissions';
import {AppLayout} from '@/components/AppLayout';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {UserSettingsAccountRoute} from './UserSettingsAccountRoute';
import {UserSettingsEmailNotificationsRoute} from './UserSettingsEmailNotificationsRoute';
import {UserSettingsOrganizationAndTeamsRoute} from './UserSettingsOrganizationAndTeamsRoute';

type Props = {};

export const UserSettingsRoute: FunctionalComponent<Props> = () => {
  const {currentUser} = useAuthenticatedContext();
  const _canCreateTeam = canCreateTeam(currentUser.accountType);
  const tabMenuItems = [
    {
      content: 'Account',
      href: ROUTES.AUTHED_SETTINGS_ACCOUNT,
    },
    {
      content: 'Email notifications',
      href: ROUTES.AUTHED_SETTINGS_NOTIFICATIONS,
    },
    ...(_canCreateTeam
      ? [
          {
            content: 'Teams',
            href: ROUTES.AUTHED_SETTINGS_ORGANIZATION_AND_TEAMS,
          },
        ]
      : []),
  ];

  return (
    <AppLayout>
      <Responsive size="lg">
        <Typography
          variant="h4"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={6}
        >
          Settings
        </Typography>
        <TabMenu
          direction="row"
          noAnimation
          items={tabMenuItems}
          css={`
            margin-bottom: var(--gap-8);
          `}
        />
        <ErrorBoundary>
          <Router>
            <Route
              path={ROUTES.AUTHED_SETTINGS_ACCOUNT.replace(
                ROUTES.AUTHED_SETTINGS,
                '',
              )}
              component={UserSettingsAccountRoute}
            />
            <Route
              path={ROUTES.AUTHED_SETTINGS_NOTIFICATIONS.replace(
                ROUTES.AUTHED_SETTINGS,
                '',
              )}
              component={UserSettingsEmailNotificationsRoute}
            />
            {_canCreateTeam ? (
              <Route
                path={ROUTES.AUTHED_SETTINGS_ORGANIZATION_AND_TEAMS.replace(
                  ROUTES.AUTHED_SETTINGS,
                  '',
                )}
                component={UserSettingsOrganizationAndTeamsRoute}
              />
            ) : (
              <></>
            )}
            <Route
              default
              component={() => <Redirect to={ROUTES.AUTHED_SETTINGS_ACCOUNT} />}
            />
          </Router>
        </ErrorBoundary>
      </Responsive>
    </AppLayout>
  );
};
