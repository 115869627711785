import {AccountType} from './database-types';

const PERMISSIONS: Record<string, AccountType[]> = {
  CAN_BE_SEARCHED: [
    AccountType.PLAYER,
    AccountType.INSTRUCTOR,
    AccountType.INFLUENCER,
    // 'TEAMS'
  ],
  CAN_CREATE_TEAM: [
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
  ],
  CAN_BELONG_TO_TEAM: [
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
    AccountType.PLAYER,
  ],
  CAN_ADD_HIGHLIGHTS: [
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
    AccountType.PLAYER,
    AccountType.INSTRUCTOR,
    AccountType.INFLUENCER,
  ],
  CAN_ADD_TO_PLANNER: [AccountType.SCOUT, AccountType.COLLEGE_COACH],
  CAN_BE_ADDED_TO_PLANNER: [AccountType.PLAYER],
  CAN_FOLLOW: [
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
    AccountType.PLAYER,
    AccountType.INSTRUCTOR,
    AccountType.INFLUENCER,
    AccountType.COLLEGE_COACH,
  ],
  CAN_BE_FOLLOWED: [
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
    AccountType.PLAYER,
    AccountType.INSTRUCTOR,
    AccountType.INFLUENCER,
  ],
  CAN_ADD_NOTES: [
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
    AccountType.SCOUT,
    AccountType.INSTRUCTOR,
  ],
  CAN_ADD_EVENTS: [
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
    AccountType.PLAYER,
    AccountType.INSTRUCTOR,
    AccountType.INFLUENCER,
    // 'TEAMS;
  ],
};

type PermissionType = keyof typeof PERMISSIONS;

const hasPermission = (
  permissionType: PermissionType,
  accountType: AccountType,
) => {
  return !!accountType && PERMISSIONS[permissionType].includes(accountType);
};

/* teams */
export const canCreateTeam = (accountType: AccountType) =>
  hasPermission('CAN_CREATE_TEAM', accountType);
export const canBelongToTeam = (accountType: AccountType) =>
  hasPermission('CAN_BELONG_TO_TEAM', accountType);

/* higlights */
export const canAddHighlights = (accountType: AccountType) =>
  hasPermission('CAN_ADD_HIGHLIGHTS', accountType);

/* planner */
export const canAddToPlanner = (accountType: AccountType) =>
  hasPermission('CAN_ADD_TO_PLANNER', accountType);
export const canBeAddedToPlanner = (accountType: AccountType) =>
  hasPermission('CAN_BE_ADDED_TO_PLANNER', accountType);

/* follow */
export const canFollow = (accountType: AccountType) =>
  hasPermission('CAN_FOLLOW', accountType);
export const canBeFollowed = (accountType: AccountType) =>
  hasPermission('CAN_BE_FOLLOWED', accountType);

/* notes */
export const canAddNotes = (accountType: AccountType) =>
  hasPermission('CAN_ADD_NOTES', accountType);

/* events */
export const canAddEvents = (accountType: AccountType) =>
  hasPermission('CAN_ADD_EVENTS', accountType);
