import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {applyActionCode, getAuth} from 'firebase/auth';
import {Button, Loading, Stack, Typography} from 'pylon/ui';
import {ROUTES} from '@/lib/app-routes';

type Props = {};

export const PublicConfirmEmail: FunctionalComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [counter, setCounter] = useState<number>(5);

  const handleVerifyEmail = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get('oobCode');
    if (oobCode) {
      try {
        await applyActionCode(getAuth(), oobCode);
        // Email verified successfully
      } catch (err) {
        setError(
          'Failed to verify email. Please try the verification link again.',
        );
      }
    } else {
      setError(
        'No verification code found. Please make sure you clicked on the correct verification link.',
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && !error) {
      const timeout = setTimeout(() => {
        window.location.href = ROUTES.PUBLIC_HOME;
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [loading, error]);

  useEffect(() => {
    if (loading || error) return;
    const interval = setInterval(() => {
      setCounter((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [loading, error]);

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      css={`
        height: 100vh;
        padding: 0 var(--gap-14);
        text-align: center;
        width: 100vw;
      `}
    >
      {loading && <Loading />}
      {!loading && error && (
        <>
          <Typography
            variant="h6"
            css={`
              max-width: 600px;
            `}
          >
            {error}
          </Typography>
          <Button
            href={ROUTES.PUBLIC_HOME}
            css-mt={8}
            variant="primary"
            buttonSize="lg"
          >
            Go to Home
          </Button>
        </>
      )}
      {!loading && !error && (
        <>
          <Typography variant="h4">
            Thank you confirming your email. 🎉
          </Typography>
          <Typography variant="body1" css-mt={4}>
            You will be redirected to the home page in {counter} seconds.
          </Typography>
          <Button
            href={ROUTES.PUBLIC_HOME}
            css-mt={8}
            variant="primary"
            buttonSize="lg"
          >
            Go to Home
          </Button>
        </>
      )}
    </Stack>
  );
};
