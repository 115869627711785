import {
  AccountType,
  Event,
  PlayerHand,
  PlayerLevel,
  PlayerPosition,
} from '../database-types';
import {ErrorCode} from '../error-code';
import {accountTypesForEmail} from '../helpers';
import {usStates} from '../us-states';
import {
  inSet,
  isValidEmail,
  isValidSlug,
  isValidUrl,
  nonEmpty,
  withStrLength,
} from './helpers';

type ValidationResponse = ErrorCode | true;

const invalidUsernames = [
  '404',
  'about',
  'account',
  'admin',
  'albums',
  'api',
  'app',
  'apps',
  'auth',
  'billing',
  'blog',
  'business',
  'careers',
  'checkout',
  'confirm-email',
  'contact',
  'dashboard',
  'download',
  'downloads',
  'features',
  'feedback',
  'following',
  'forgot-password',
  'help',
  'home',
  'index',
  'info',
  'jobs',
  'login',
  'logout',
  'marketplace',
  'notifications',
  'planner',
  'pricing',
  'privacy',
  'prospect-planner',
  'settings',
  'shop',
  'sign-in',
  'sign-out',
  'status',
  'store',
  'support',
  'team-settings',
  'teams',
  'terms',
];

// User
export function validateUsername(username: string | null): ValidationResponse {
  return (
    (isValidSlug(username) &&
      withStrLength(username, 3, 20) &&
      !invalidUsernames.includes(username ?? '')) ||
    ErrorCode.INVALID_USERNAME
  );
}

export function validateFirstLastName(str: string | null): ValidationResponse {
  return withStrLength(str, 1, 50) || ErrorCode.INVALID_USERNAME;
}

export function validateEmail(email: string): ValidationResponse {
  if (!nonEmpty(email)) {
    return ErrorCode.MISSING_FIELD;
  }
  if (isValidEmail(email)) {
    return true;
  } else {
    return ErrorCode.INVALID_EMAIL;
  }
}

export function validatePassword(password: string | null): ValidationResponse {
  return withStrLength(password, 6, 100) || ErrorCode.INVALID_PASSWORD;
}

export function validateAccountType(
  accountType: AccountType | null,
  email: string,
): ValidationResponse {
  return (
    inSet(accountType, accountTypesForEmail(email)) ||
    ErrorCode.MISSING_ACCOUNT_TYPE
  );
}

// Profile
export function validateProfileBio(bio: string | null): ValidationResponse {
  return withStrLength(bio, 0, 160) || ErrorCode.INVALID_PROFILE_BIO;
}

export function validateUsState(state: string | null): ValidationResponse {
  return (!!state && usStates[state] !== undefined) || ErrorCode.INVALID_STATE;
}

export function validateUsCity(city: string | null): ValidationResponse {
  return (!!city && withStrLength(city, 0, 40)) || ErrorCode.INVALID_CITY;
}

export function validateGradYear(gradYear: number | null): ValidationResponse {
  return (
    (!!gradYear && gradYear >= 1900 && gradYear <= 2100) ||
    ErrorCode.INVALID_GRAD_YEAR
  );
}

export function validatePlayerPosition(
  position: string | null,
): ValidationResponse {
  return (
    !!PlayerPosition[position as PlayerPosition] ||
    ErrorCode.INVALID_PLAYER_POSITION
  );
}

export function validatePlayerHand(hand: string | null): ValidationResponse {
  return !!PlayerHand[hand as PlayerHand] || ErrorCode.INVALID_PLAYER_HAND;
}

export function validatePlayerLevel(level: string | null): ValidationResponse {
  return !!PlayerLevel[level as PlayerLevel] || ErrorCode.INVALID_PLAYER_LEVEL;
}

// Team
export function validateTeamName(name: string | null): ValidationResponse {
  return withStrLength(name, 1, 50) || ErrorCode.INVALID_TEAM_NAME;
}

// Post
export function validatePostContent(
  content: string | null,
): ValidationResponse {
  return withStrLength(content, 0, 280) || ErrorCode.INVALID_POST_CONTENT;
}

// Organization
export function validateOrganizationName(
  name: string | null,
): ValidationResponse {
  return withStrLength(name, 1, 50) || ErrorCode.INVALID_ORGANIZATION_NAME;
}

export function validateOrganizationSlug(
  slug: string | null,
): ValidationResponse {
  return (
    (isValidSlug(slug) && withStrLength(slug, 3, 20)) ||
    ErrorCode.INVALID_ORGANIZATION_SLUG
  );
}

export function validateUrl(url: string | null): ValidationResponse {
  return isValidUrl(url) || ErrorCode.INVALID_URL;
}

// Album
export function validateAlbumName(value: string | null): ValidationResponse {
  return withStrLength(value, 1, 50) || ErrorCode.INVALID_ALBUM_NAME;
}

// Team Settings
export function validateTeamMemberAccountType(
  accountType: AccountType | null,
): ValidationResponse {
  const validAccountTypes = [
    AccountType.PLAYER,
    AccountType.COLLEGE_COACH,
    AccountType.HIGH_SCHOOL_COACH,
    AccountType.YOUTH_COACH,
  ];
  return inSet(accountType, validAccountTypes) || ErrorCode.INVALID_VALUE;
}

// events
export function validateEventExternalLink(
  externalLink: string | null,
): ValidationResponse {
  return !externalLink || isValidUrl(externalLink) || ErrorCode.INVALID_URL;
}

// comments
export function validatePostCommentContent(
  content: string | null,
): ValidationResponse {
  return withStrLength(content, 1, 280) || ErrorCode.INVALID_VALUE;
}