import {FunctionalComponent} from 'preact';
import {
  Anchor,
  Card,
  DescriptionList,
  DescriptionListItem,
  Typography,
} from 'pylon/ui';
import {GetTeamProfile, GetUserProfile} from '@shared/api-types';
import {capitalize} from '@/lib/utils';

type Props = {
  profile: GetUserProfile['response'] | GetTeamProfile['response'];
};

export const ProfileSocialLinksCard: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  if (!profile.socialLinks?.length) {
    return null;
  }

  return (
    <Card {...props}>
      <Typography weight="bold" css-mb={4}>
        Social links
      </Typography>
      <DescriptionList expandWidthSize={6}>
        {profile.socialLinks?.map((link) => (
          <DescriptionListItem title={capitalize(link.name)} cols="35% 1fr">
            <Anchor
              href={ensureHttps(link.url)}
              target="_blank"
              rel="noreferrer noopener"
              variant="primary"
            >
              {link.url}
            </Anchor>
          </DescriptionListItem>
        ))}
      </DescriptionList>
    </Card>
  );
};

function ensureHttps(url: string) {
  if (!url.startsWith('http://') || url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
}
