import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {Form, FormInput, FormSubmit, Stack, ToastMessage} from 'pylon/ui';
import {CreateMeOrganization} from '@shared/api-types';
import {ErrorCode} from '@shared/error-code';
import {validateOrganizationName} from '@shared/validators';
import {useCreateMeOrganization} from '@/fetch';
import {handleFormValidate} from '@/lib/error-messages';

type FormData = CreateMeOrganization['request']['body'];

type Props = {
  onCompleted?: () => void;
};

export const OrganizationForm: FunctionalComponent<Props> = ({onCompleted}) => {
  const [submiting, setSubmiting] = useState(false);
  const [createMeOrganization] = useCreateMeOrganization();
  const [networkError, setNetworkError] = useState<string | null>(null);

  const {
    formState: {errors},
    handleSubmit,
    register,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      createMeOrganization({
        body: formData,
      });
      onCompleted?.();
    } catch (error: any) {
      setNetworkError(error?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmiting(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {networkError && (
        <ToastMessage severity="error" css-mb={5}>
          {ErrorCode.SOMETHING_WENT_WRONG}
        </ToastMessage>
      )}
      <Stack direction="column" gap={6}>
        <FormInput
          {...register('name', {
            validate: (value) =>
              handleFormValidate(validateOrganizationName(value)),
          })}
          id="name"
          label="Name"
          invalid={!!errors.name}
          invalidText={errors?.name?.message}
          fullWidth
        />
      </Stack>
      <Stack css-mt={6} gap={4}>
        <FormSubmit
          variant="primary"
          value="Create organization"
          submitting={submiting}
          disabled={submiting}
        />
      </Stack>
    </Form>
  );
};
