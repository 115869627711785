export const NotificationType = {
  FOLLOW: 'FOLLOW',
  POST_LIKE: 'POST_LIKE',
  POST_COMMENT: 'POST_COMMENT',
} as const;
export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];
export const PlayerLevel = {
  YOUTH: 'YOUTH',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
  COLLEGE: 'COLLEGE',
  PROFESSIONAL: 'PROFESSIONAL',
  FREE_AGENT: 'FREE_AGENT',
} as const;
export type PlayerLevel = (typeof PlayerLevel)[keyof typeof PlayerLevel];
export const PlayerPosition = {
  RHP: 'RHP',
  LHP: 'LHP',
  C: 'C',
  FIRST_B: 'FIRST_B',
  SECOND_B: 'SECOND_B',
  THRID_B: 'THRID_B',
  SS: 'SS',
  LF: 'LF',
  CF: 'CF',
  RF: 'RF',
} as const;
export type PlayerPosition =
  (typeof PlayerPosition)[keyof typeof PlayerPosition];
export const AccountType = {
  INFLUENCER: 'INFLUENCER',
  INSTRUCTOR: 'INSTRUCTOR',
  PLAYER: 'PLAYER',
  SCOUT: 'SCOUT',
  COLLEGE_COACH: 'COLLEGE_COACH',
  HIGH_SCHOOL_COACH: 'HIGH_SCHOOL_COACH',
  YOUTH_COACH: 'YOUTH_COACH',
} as const;
export type AccountType = (typeof AccountType)[keyof typeof AccountType];
export const UserTeamRole = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
} as const;
export type UserTeamRole = (typeof UserTeamRole)[keyof typeof UserTeamRole];
export const PlayerHand = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTH: 'BOTH',
} as const;
export type PlayerHand = (typeof PlayerHand)[keyof typeof PlayerHand];
export const StorageService = {
  GCS: 'GCS',
} as const;
export type StorageService =
  (typeof StorageService)[keyof typeof StorageService];
export const VideoMimeType = {
  VIDEO_MP4: 'VIDEO_MP4',
} as const;
export type VideoMimeType = (typeof VideoMimeType)[keyof typeof VideoMimeType];
export const VideoRatio = {
  RATIO_16_9: 'RATIO_16_9',
  RATIO_9_16: 'RATIO_9_16',
  RATIO_1_1: 'RATIO_1_1',
} as const;
export type VideoRatio = (typeof VideoRatio)[keyof typeof VideoRatio];
