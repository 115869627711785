import {FunctionalComponent} from 'preact';
import {Controller} from 'react-hook-form';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {FormInput, FormSelect, Stack} from 'pylon/ui';
import {usStates} from '@shared/us-states';
import {validateUsCity, validateUsState} from '@shared/validators';
import {handleFormValidate} from '@/lib/error-messages';
import {TeamProfileFormState} from '../TeamProfileForm';

export enum RegisteredNames {
  STATE = 'state',
  CITY = 'city',
}

export const ProfileForm_ControlStateAndCity: FunctionalComponent<
  TeamProfileFormState
> = ({control, register, errors}) => {
  return (
    <Stack
      gap={8}
      direction="column"
      css={`
        ${mediaQuery(
          'md>',
          css`
            flex-direction: row;
          `,
        )}
      `}
    >
      <Controller
        name={RegisteredNames.STATE}
        control={control}
        rules={{
          validate: (value) => handleFormValidate(validateUsState(value)),
        }}
        render={({field}) => {
          return (
            <FormSelect
              id={RegisteredNames.STATE}
              label="State"
              searchable
              options={Object.keys(usStates).map((state) => ({
                label: state,
                value: state,
              }))}
              invalid={!!errors[RegisteredNames.STATE]}
              invalidText={errors?.[RegisteredNames.STATE]?.message}
              placeholder="Select state"
              fullWidth
              {...(field as any)}
            />
          );
        }}
      />
      <FormInput
        {...register(RegisteredNames.CITY, {
          validate: (value) => handleFormValidate(validateUsCity(value)),
        })}
        id={RegisteredNames.CITY}
        label="City"
        invalid={!!errors[RegisteredNames.CITY]}
        invalidText={errors?.[RegisteredNames.CITY]?.message}
        fullWidth
      />
    </Stack>
  );
};
