import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {
  Button,
  Form,
  FormInput,
  FormSubmit,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {CreateMeAlbum} from '@shared/api-types';
import {Album} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {useCreateMeAlbum} from '@/fetch';
import {handleFormValidate} from '@/lib/error-messages';
import {validateAlbumName} from '../../../../api/functions/shared/validators/validators';

type Props = {
  albumId?: Album['id'];
  opened: boolean;
  setOpened: (value: boolean) => void;
};

type FormData = CreateMeAlbum['request']['body'];

export const AlbumFormOverlay: FunctionalComponent<Props> = ({
  albumId,
  opened,
  setOpened,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [createMeAlbum] = useCreateMeAlbum();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({});

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await createMeAlbum({
        body: {
          name: formData.name,
        },
      });
      setOpened(false);
    } catch (error: any) {
      setNetworkError(error?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmiting(false);
  };

  const handleCancel = () => {
    setOpened(false);
  };

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      position={{mode: 'centered'}}
      closeOthers
      cancelOnEscKey
      cancelOnOutsideClick
      withBackdrop
      animation="scale-in"
      css={`
        ${overlayPopupCss}
      `}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h5"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={4}
        >
          New album
        </Typography>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        <FormInput
          {...register('name', {
            validate: (value) => handleFormValidate(validateAlbumName(value)),
          })}
          id="name"
          label="Title"
          invalid={!!errors.name}
          invalidText={errors?.name?.message}
          fullWidth
        />
        <Stack gap={4} css-mt={6} justifyContent="flex-end">
          <Button variant="muted" onClick={handleCancel}>
            Cancel
          </Button>
          <FormSubmit
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            submitting={submiting}
            disabled={submiting}
          >
            Create
          </FormSubmit>
        </Stack>
      </Form>
    </Overlay>
  );
};
