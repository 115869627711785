import {FunctionalComponent} from 'preact';
import {Card, DescriptionList, DescriptionListItem, Typography} from 'pylon/ui';
import {GetUserProfile} from '@shared/api-types';
import {playerHasPopTime} from '@shared/helpers';

type Props = {
  profile: GetUserProfile['response'];
};

export const UserProfilePlayerSkillsCard: FunctionalComponent<Props> = ({
  profile,
  ...props
}) => {
  return (
    <Card {...props}>
      <Typography weight="bold">Attributes & skill</Typography>
      <DescriptionList expandWidthSize={6}>
        <DescriptionListItem title="Height" cols="35% 1fr">
          {profile.height ?? '-'} in
        </DescriptionListItem>
        <DescriptionListItem title="Weight" cols="35% 1fr">
          {profile.weight ?? '-'} lbs
        </DescriptionListItem>
        <DescriptionListItem title="Throwing velocity" cols="35% 1fr">
          {profile.throwingVelocity ?? '-'} mph
        </DescriptionListItem>
        <DescriptionListItem title="Exit velocity" cols="35% 1fr">
          {profile.exitVelocity ?? '-'} mph
        </DescriptionListItem>
        <DescriptionListItem title="Running speed" cols="35% 1fr">
          30y in {profile.runningSpeed30 ?? '-'} sec / 60y in{' '}
          {profile.runningSpeed60 ?? '-'}
        </DescriptionListItem>
        {playerHasPopTime(profile.position1, profile.position2) && (
          <DescriptionListItem title="Pop Time" cols="35% 1fr">
            {profile.popTime ?? '-'} sec
          </DescriptionListItem>
        )}
      </DescriptionList>
    </Card>
  );
};
