import {FunctionalComponent} from 'preact';
import {Anchor, Card, Stack, Typography} from 'pylon/ui';
import {UserSettingsMeForm} from '@/components/(user-settings)/UserSettingsMeForm';

type Props = {};

export const UserSettingsAccountRoute: FunctionalComponent<Props> = () => {
  return (
    <Stack direction="column" gap={5}>
      <Card>
        <UserSettingsMeForm />
      </Card>
      <Card>
        <Typography weight="bold" css-mb={4}>
          Delete account
        </Typography>
        <Typography css-color="var(--fg-muted)">
          If you want to delete your account, please send an email to{' '}
          <Anchor href="mailto:support@prospectdugout.com" variant="underline">
            support@prospectdugout.com
          </Anchor>
        </Typography>
      </Card>
    </Stack>
  );
};
