import {FunctionalComponent} from 'preact';
import {Anchor, Stack, Typography} from 'pylon/ui';
import {GetDiscoverRecentProfiles, GetMeProspects} from '@shared/api-types';
import {TeamMember, UserPreview} from '@shared/common-types';
import {ProspectTag} from '@shared/database-types';
import {ROUTES} from '@/lib/app-routes';
import {capitalize, userInfoDisplay} from '@/lib/utils';
import {PDAvatar} from '../PDAvatar';

type Props = {
  user:
    | UserPreview
    | GetDiscoverRecentProfiles['response']['rows'][0]
    | GetMeProspects['response']['rows'][0];
};

export const UserCard: FunctionalComponent<Props> = ({user, ...props}) => {
  const tags = (user as any).tags as ProspectTag[];
  return (
    <Anchor
      variant="unstyled"
      href={ROUTES.PUBLIC_USER_PROFILE.replace(':username', user.username)}
      css={`
        align-items: center;
        border-radius: var(--radius-3);
        border: 1px solid var(--border-muted);
        display: inline-flex;
        gap: var(--gap-4);
        padding: var(--gap-4);
        &:hover {
          background-color: var(--bg-minimal);
        }
      `}
      {...props}
    >
      <PDAvatar user={user} size="xl" />
      <Stack direction="column" gap={1}>
        <Typography weight="bold" variant="body1">
          {user.firstName} {user.lastName}
        </Typography>
        <Typography css-color="var(--fg-muted)">
          {userInfoDisplay(user)}
          <br />
          {capitalize(user.accountType)}
          {tags && tags.length > 0 && (
            <Stack gap={1}>
              {tags.map((tag) => (
                <div
                  key={tag.id}
                  css={`
                    background: var(--bg-muted);
                    border-radius: var(--radius-4);
                    font-size: var(--font-size-body-4);
                    margin-top: var(--gap-1);
                    padding: 0 var(--gap-2);
                  `}
                >
                  {tag.tagName}
                </div>
              ))}
            </Stack>
          )}
        </Typography>
      </Stack>
    </Anchor>
  );
};
