import {FunctionalComponent} from 'preact';
import {useEffect, useRef} from 'preact/hooks';
import {css} from 'styled-components';
import {breakpoints, mediaQuery, useWindowSize} from 'pylon/lib';
import {Loading, Overlay, ToastMessage} from 'pylon/ui';
import {ErrorCode} from '@shared/error-code';
import {useUserPost} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';
import {errorMessages} from '@/lib/error-messages';
import {Post} from './Post';

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
  postId: string;
  username: string;
};

export const PostOverlay: FunctionalComponent<Props> = ({
  opened,
  setOpened,
  username,
  postId,
}) => {
  const windowSize = useWindowSize();
  const {
    data: post,
    loading,
    error,
  } = useUserPost({
    params: {
      username,
      postId: Number(postId),
    },
  });
  const MAX_HEIGHT_PCT = windowSize.width <= breakpoints.md ? 100 : 80;
  const MAX_WIDTH_PCT = windowSize.width <= breakpoints.md ? 100 : 80;
  const previousUrlRef = useRef<string | null>(null);

  useEffect(() => {
    if (opened) {
      previousUrlRef.current = window.location.href;
      const postUrl = ROUTES.PUBLIC_USER_PROFILE_POST.replace(
        ':username',
        username,
      ).replace(':postId', String(postId));
      window.history.pushState({}, '', postUrl);
    }

    return () => {
      if (previousUrlRef.current) {
        window.history.pushState({}, '', previousUrlRef.current);
      }
    };
  }, [opened]);

  let content;

  if ((loading || !post) && !error) {
    content = <Loading />;
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {error?.message || errorMessages[ErrorCode.SOMETHING_WENT_WRONG]}
      </ToastMessage>
    );
  } else {
    const viewport = {
      width: window.innerWidth * (MAX_WIDTH_PCT / 100),
      height: window.innerHeight * (MAX_HEIGHT_PCT / 100),
    };
    content = (
      <Post post={post} viewport={viewport} onClose={() => setOpened(false)} />
    );
  }

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      animation="scale-in"
      position={{
        mode: 'centered',
      }}
      withBackdrop
      cancelOnEscKey
      cancelOnOutsideClick
      disableBodyScroll
      style={{
        maxHeight: `${MAX_HEIGHT_PCT}%`,
        maxWidth: `${MAX_WIDTH_PCT}%`,
      }}
      css={`
        background: var(--bg-overlay);
        inset: 0;
        overflow: hidden;
        ${mediaQuery(
          '<md',
          css`
            width: 100%;
            height: 100%;
          `,
        )}
        ${mediaQuery(
          'md>',
          css`
            border-radius: var(--radius-4);
          `,
        )}
      `}
    >
      {content}
    </Overlay>
  );
};
