import {FunctionalComponent} from 'preact';
import {useEffect, useRef} from 'preact/hooks';
import JSConfetti from 'js-confetti';
import {Button, Stack, Typography} from 'pylon/ui';
import {StepComponent} from '../types';

type Props = StepComponent;

export const GetStartedProcess_StepAllDone: FunctionalComponent<Props> = ({
  onNext,
}) => {
  useEffect(() => {
    const confetti = new JSConfetti();
    confetti.addConfetti({
      confettiColors: [
        '#ff0000',
        '#00ff00',
        '#0000ff',
        '#ff00ff',
        '#00ffff',
        '#ffff00',
      ],
    });
  }, []);

  return (
    <div>
      <Typography
        variant="h3"
        tagName="h1"
        textTransform="uppercase"
        fontStyle="italic"
        css-mb={4}
      >
        You're all set!
      </Typography>
      <Typography css-mb={12} css-color="var(--fg-muted)">
        Your profile has been created successfully.
      </Typography>
      <Stack css-mt={8} justifyContent="flex-end">
        <Button variant="primary" buttonSize="lg" onClick={onNext}>
          Finish
        </Button>
      </Stack>
    </div>
  );
};
