import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {
  Loading,
  Redirect,
  Responsive,
  Select,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {AccountType} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {canAddToPlanner} from '@shared/permissions';
import {AppLayout} from '@/components/AppLayout';
import {UserCard} from '@/components/UserCard/UserCard';
import {useMeProspectTags, useMeProspects} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';

type Props = {};

export const AuthedProspectPlannerRoute: FunctionalComponent<Props> = (
  props,
) => {
  const {currentUser} = useAuthenticatedContext();
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const {
    data: tagsData,
    error: tagsError,
    loading: tagsLoading,
  } = useMeProspectTags();
  const {data, error, loading} = useMeProspects({
    params: {offset: 0, limit: 50},
    searchParams: {tagIds: tagsFilter},
  });

  if (!canAddToPlanner(currentUser?.accountType)) {
    return <Redirect to={ROUTES.AUTHED_FOLLOWING} />;
  }

  let content = null;

  if (loading) {
    content = (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error) {
    content = (
      <ToastMessage severity="error">
        {ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  } else {
    const {totalCount, rows} = data;
    if (totalCount === 0) {
      content = (
        <Stack
          direction="column"
          alignItems="center"
          gap={1}
          css-pt={4}
          css-pb={4}
        >
          <Typography weight="medium">You have no prospects</Typography>
          <Typography css-color="var(--fg-muted)">
            Keep track of your prospects by adding them to the planner
          </Typography>
        </Stack>
      );
    } else {
      content = (
        <div
          css={`
            display: flex;
            flex-direction: column;
            gap: var(--gap-4);
            ${mediaQuery(
              'md>',
              css`
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
              `,
            )}
          `}
        >
          {rows.map((user) => {
            return <UserCard user={user} />;
          })}
        </div>
      );
    }
  }

  return (
    <AppLayout>
      <Responsive size="xl">
        <Typography
          variant="h4"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={6}
        >
          Prospect planner
        </Typography>
        <Stack css-mb={6}>
          <div>
            <Select
              multiple
              disabled={tagsLoading || !tagsData?.length}
              onChange={(evt) => {
                setTagsFilter((evt.target as any).value);
              }}
              placeholder="Filter by tags"
              options={
                tagsData
                  ? tagsData.map((tag) => ({
                      label: tag.tagName,
                      value: String(tag.id),
                    }))
                  : []
              }
            />
          </div>
        </Stack>
        {content}
      </Responsive>
    </AppLayout>
  );
};
