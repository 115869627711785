import {FunctionalComponent} from 'preact';
import {IconButton, Tooltip} from 'pylon/ui';
import {useMeNotificationsUnreadCount} from '@/fetch';
import {ROUTES} from '@/lib/app-routes';
import {PDIcon} from '../PDIcon';

type Props = {};

export const NotificationsButton: FunctionalComponent<Props> = ({...props}) => {
  const {data, loading} = useMeNotificationsUnreadCount();
  const hasNotifications = !loading && data && data.count > 0;

  return (
    <Tooltip
      disabled={loading}
      text={
        hasNotifications
          ? `You have ${data?.count} unread notifications`
          : 'No unread notifications'
      }
    >
      <div
        css={`
          position: relative;
        `}
        {...props}
      >
        <IconButton
          variant={hasNotifications ? 'default' : 'muted'}
          icon={<PDIcon icon="notification-outline" />}
          href={ROUTES.AUTHED_NOTIFICATIONS}
        />
        {hasNotifications && (
          <div
            css={`
              width: 8px;
              height: 8px;
              background-color: var(--red50);
              border-radius: 50%;
              position: absolute;
              bottom: 8px;
              right: 6px;
              box-shadow: 0 0 0 3px var(--bg-default);
              z-index: 1;
            `}
          />
        )}
      </div>
    </Tooltip>
  );
};
