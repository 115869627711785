import {FunctionalComponent} from 'preact';
import {Card, DescriptionList, DescriptionListItem, Typography} from 'pylon/ui';
import {GetTeamProfile, GetUserProfile} from '@shared/api-types';
import {capitalize} from '@/lib/utils';

type Props = {
  profile: GetUserProfile['response'] | GetTeamProfile['response'];
};

export const ProfileBasicCard: FunctionalComponent<Props> = ({profile}) => {
  return (
    <Card>
      <Typography weight="bold" css-mb={4}>
        Profile
      </Typography>
      <DescriptionList expandWidthSize={6}>
        <DescriptionListItem title="State" cols="35% 1fr">
          {capitalize(profile.state ?? '-')}
        </DescriptionListItem>
        <DescriptionListItem title="City" cols="35% 1fr">
          {capitalize(profile.city ?? '-')}
        </DescriptionListItem>
      </DescriptionList>
    </Card>
  );
};
