import {FunctionalComponent} from 'preact';
import {Button, Card, Loading, Stack, ToastMessage, Typography} from 'pylon/ui';
import {UserTeamRole} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {TeamSettingsInvitesTable} from '@/components/(team-settings)/TeamSettingsInvitesTable';
import {TeamSettingsMembersTable} from '@/components/(team-settings)/TeamSettingsMembersTable';
import {useMeTeamMembers} from '@/fetch';
import {useAuthenticatedAsTeamContext} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';

type Props = {};

export const TeamSettingsMembersRoute: FunctionalComponent<Props> = () => {
  const {currentTeam} = useAuthenticatedAsTeamContext();
  const {data, loading, error} = useMeTeamMembers({
    params: {
      teamId: currentTeam.id,
    },
  });

  if (loading) {
    return (
      <Stack justifyContent="center">
        <Loading />
      </Stack>
    );
  } else if (error || !data) {
    return (
      <ToastMessage severity="error">
        {error?.message ?? ErrorCode.SOMETHING_WENT_WRONG}
      </ToastMessage>
    );
  }

  return (
    <Stack direction="column" gap={6}>
      <Card>
        <Typography weight="bold" css-mb={4}>
          Active members
        </Typography>
        <TeamSettingsMembersTable currentTeamMembers={data.teamMembers} />
        {currentTeam.role === UserTeamRole.OWNER && (
          <Button
            href={ROUTES.AUTHED_TEAM_SETTINGS_INVITE_MEMBER}
            variant="default"
            css-mt={8}
          >
            Add member
          </Button>
        )}
      </Card>
      <Card>
        <Typography weight="bold" css-mb={4}>
          Pending invites
        </Typography>
        <TeamSettingsInvitesTable currentTeamInvites={data.invites} />
      </Card>
    </Stack>
  );
};
