import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {VideoPlayer} from 'pylon/ui';
import {GetUserPost} from '@shared/api-types';
import {VideoRatio} from '@shared/database-types';
import {toMediaPath} from '@/lib/environment';

type Props = {
  post: GetUserPost['response'];
  viewport?: {width: number; height: number};
};

export const Post_Video: FunctionalComponent<Props> = ({
  post,
  viewport,
  ...props
}: Props) => {
  const aspectRatio = post.video!.ratio ?? VideoRatio.RATIO_1_1;
  const {widthRatio, heightRatio} = ratioSizes[aspectRatio];

  return (
    <VideoPlayer
      src={toMediaPath(post.video!.path) + '#t=0.001'}
      playsinline
      webkit-playsinline
      preload="metadata"
      controls={false}
      containerCss={css`
        aspect-ratio: ${widthRatio} / ${heightRatio};
        overflow: hidden;
      `}
      containerProps={{
        style: {
          margin: 'auto',
          width: '100%',
          ...(viewport && {
            maxHeight: `${viewport.height}px`,
            maxWidth: `${viewport.width}px`,
          }),
        },
      }}
      css={`
        height: calc(100% + 4px);
        margin-left: -2px;
        margin-top: -2px;
        max-height: calc(100% + 4px);
        max-width: calc(100% + 4px);
        object-fit: cover;
        width: calc(100% + 4px);
      `}
      {...props}
    />
  );
};

const ratioSizes = {
  [VideoRatio.RATIO_16_9]: {
    widthRatio: 16,
    heightRatio: 9,
  },
  [VideoRatio.RATIO_9_16]: {
    widthRatio: 9,
    heightRatio: 16,
  },
  [VideoRatio.RATIO_1_1]: {
    widthRatio: 1,
    heightRatio: 1,
  },
};

/*
function calculateAspectRatioDimension(
  aspectRatio: VideoRatio,
  dimension: number,
  dimensionType: 'width' | 'height',
) {
  const {heightRatio, widthRatio} = ratioSizes[aspectRatio];
  console.log({
    heightRatio,
    widthRatio,
  });

  if (dimensionType === 'width') {
    const maxHeight = dimension * (heightRatio / widthRatio);
    return maxHeight;
  } else if (dimensionType === 'height') {
    const maxWidth = dimension * (widthRatio / heightRatio);
    return maxWidth;
  } else {
    throw new Error('Invalid dimensionType provided. Use "width" or "height".');
  }
}
 */
