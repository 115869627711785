import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {
  Avatar,
  Button,
  DropdownMenu,
  IconButton,
  Stack,
  Table,
  TableColumn,
  menuItemVariant,
} from 'pylon/ui';
import {GetMeTeamMembers} from '@shared/api-types';
import {User, UserTeamRole} from '@shared/database-types';
import {useAuthenticatedAsTeamContext} from '@/lib/app-context';
import {capitalize} from '@/lib/utils';
import {DeleteMemberOverlay} from './DeleteMemberOverlay';

type TeamMember = GetMeTeamMembers['response']['teamMembers'][0];

type Props = {
  currentTeamMembers: TeamMember[];
};

export const TeamSettingsMembersTable: FunctionalComponent<Props> = ({
  currentTeamMembers,
}: Props) => {
  const {currentUser, currentTeam} = useAuthenticatedAsTeamContext();
  const [memberUserIdToDelete, setMemberUserIdToDelete] = useState<
    User['id'] | null
  >(null);

  const columns: TableColumn<TeamMember>[] = [
    {
      accessor: 'email',
      type: 'string',
      title: 'Email',
      cellCssFn: () => `vertical-align: middle;`,
      renderContent: ({d, value}) => {
        return (
          <Stack alignItems="center" gap={3}>
            <Avatar email={d.email} size="md" />
            {value}
          </Stack>
        );
      },
    },
    {
      accessor: 'accountType',
      type: 'string',
      title: 'Account type',
      cellCssFn: () => `vertical-align: middle;`,
      formatter: capitalize,
    },
    ...(currentTeam.role === UserTeamRole.OWNER
      ? ([
          {
            accessor: 'actions',
            noSortable: true,
            cellCssFn: () => `vertical-align: middle;`,
            renderContent: ({d}) =>
              currentUser.id === d.userId ? null : (
                <DropdownMenu>
                  <IconButton variant="muted" icon="ellipsis-horizontal" />
                  <Button
                    customVariant={menuItemVariant}
                    onClick={() => setMemberUserIdToDelete(d.userId)}
                  >
                    Remove member
                  </Button>
                </DropdownMenu>
              ),
          },
        ] as TableColumn<TeamMember>[])
      : []),
  ];

  return (
    <>
      <Table
        columns={columns}
        data={currentTeamMembers}
        propertyForKey="email"
      />
      {!!memberUserIdToDelete && (
        <DeleteMemberOverlay
          memberUserId={memberUserIdToDelete}
          teamId={currentTeam.id}
          opened
          setOpened={() => setMemberUserIdToDelete(null)}
        />
      )}
    </>
  );
};
