import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadBytes,
} from '@firebase/storage';
import {Controller} from 'react-hook-form';
import {makeid} from 'pylon/lib';
import {IconButton, ImageInput, Img, Label} from 'pylon/ui';
import {IMAGES_TMP_STORAGE_PATH} from '@shared/constants';
import {PDIcon} from '@/components/PDIcon';
import {isDevelopment, toMediaPath} from '@/lib/environment';
import {ProfileFormState} from '../ProfileForm';

export enum RegisteredNames {
  TMP_COVER_PHOTO_STORAGE_PATH = 'tmpCoverPhotoStoragePath',
}

export const ProfileForm_ControlCoverPhoto: FunctionalComponent<
  ProfileFormState
> = ({control, profile}) => {
  const [tmpImageBase64, setTmpImageBase64] = useState<string | null>(null);

  return (
    <Controller
      name={RegisteredNames.TMP_COVER_PHOTO_STORAGE_PATH}
      control={control}
      render={({field: {onChange}}) => {
        const imgSrc = tmpImageBase64
          ? tmpImageBase64
          : profile!.coverPhoto
            ? toMediaPath(profile!.coverPhoto)
            : '';

        return (
          <div
            css={`
              margin-bottom: var(--gap-2);
            `}
          >
            <Label>Cover photo</Label>
            <div
              css={`
                position: relative;
              `}
            >
              <Img
                src={imgSrc}
                css={`
                  background-size: cover;
                  border-radius: var(--radius-4);
                  display: block;
                  margin: 0 auto;
                  max-width: 18rem;
                  width: 100%;
                  &::before {
                    content: '';
                    display: block;
                    padding-top: 75%;
                  }
                `}
              />
              <ImageInput
                renderCustomButton={({onClick}) => (
                  <IconButton
                    icon={<PDIcon icon="camera" />}
                    onClick={onClick}
                    css={`
                      background: var(--bg-minimal);
                      border-radius: 50%;
                      padding: 1rem;
                    `}
                  />
                )}
                aspectFn={() => 10 / 7.5}
                onSubmit={({base64, arrayBuffer}) => {
                  if (!base64 || !arrayBuffer) return;
                  setTmpImageBase64(base64);
                  onChange(arrayBuffer);
                }}
                css={`
                  height: 3.25rem;
                  inset: 0;
                  margin: auto;
                  position: absolute;
                  width: 3.25rem;
                `}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export const uploadCoverPhoto = async ({
  arrayBuffer,
  currentUser,
}: {
  arrayBuffer: ArrayBuffer;
  currentUser: any;
}) => {
  if (!arrayBuffer) return null;
  const storage = getStorage();
  if (isDevelopment) {
    connectStorageEmulator(storage, 'localhost', 9199);
  }
  const resp = await uploadBytes(
    ref(
      storage,
      `${IMAGES_TMP_STORAGE_PATH}/${currentUser.id}/${makeid(10)}.jpg`,
    ),
    arrayBuffer,
    {contentType: 'image/jpeg'},
  );
  return resp.metadata.fullPath;
};
