import * as T from '@shared/api-types';
import {ENDPOINTS} from '@shared/endpoints';
import {
  useAuthenticatedAsTeamContext,
  useAuthenticatedContext,
} from '@/lib/app-context';
import {useMutation} from '.';

/* me-ctrl */
export const useCreateMe = () => {
  return useMutation<T.CreateMe['request'], T.CreateMe['response']>(
    () => ENDPOINTS.ME,
    {
      getCacheUpdateKeys: () => [ENDPOINTS.ME],
      waitForCacheRefresh: true,
    },
  );
};

/* me-ctrl */
export const useUpdateMe = () => {
  return useMutation<T.UpdateMe['request'], T.UpdateMe['response']>(
    () => ENDPOINTS.ME,
    {
      method: 'PUT',
      getCacheUpdateKeys: () => [ENDPOINTS.ME],
    },
  );
};

/* me-notifications-ctrl */
export const useCreateMeNotificationMarkAsRead = () => {
  return useMutation<
    T.CreateMeNotificationsMarkAsRead['request'],
    T.CreateMeNotificationsMarkAsRead['response']
  >(() => ENDPOINTS.ME_NOTIFICATIONS_MARK_AS_READ, {
    method: 'POST',
    getCacheUpdateKeys: () => [ENDPOINTS.ME_NOTIFICATIONS_UNREAD_COUNT],
  });
};

/* me-organizations-ctrl */
export const useCreateMeOrganization = () => {
  return useMutation<
    T.CreateMeOrganization['request'],
    T.CreateMeOrganization['response']
  >(() => ENDPOINTS.ME_ORGANIZATIONS, {
    method: 'POST',
    getCacheUpdateKeys: () => [ENDPOINTS.ME],
  });
};

/* me-posts-ctrl */
export const useCreateMePost = () => {
  const {currentUser} = useAuthenticatedContext();
  return useMutation<T.CreateMePost['request'], T.CreateMePost['response']>(
    () => ENDPOINTS.ME_POSTS.replace('/:postId?', ''),
    {
      getCacheUpdateKeys: () => [
        ENDPOINTS.USER_POSTS.replace(':userId', currentUser.id),
      ],
    },
  );
};

/* me-post-like-ctrl */
export const useCreateMePostLike = () => {
  return useMutation<
    T.CreateMePostLike['request'] & {updateCacheParams: {userId: string}},
    T.CreateMePostLike['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_POST_LIKE.replace(':postId', String(params.postId)),
    {
      method: 'POST',
      waitForCacheRefresh: true,
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.USER_POSTS.replace(':userId', updateCacheParams.userId),
        ENDPOINTS.DISCOVER_RECENT_POSTS.replace(':offset/:limit', '0/10'),
      ],
    },
  );
};

/* me-post-like-ctrl */
export const useDeleteMePostLike = () => {
  return useMutation<
    T.DeleteMePostLike['request'] & {updateCacheParams: {userId: string}},
    T.DeleteMePostLike['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_POST_LIKE.replace(':postId', String(params.postId)),
    {
      method: 'DELETE',
      waitForCacheRefresh: true,
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.USER_POSTS.replace(':userId', updateCacheParams.userId),
        ENDPOINTS.DISCOVER_RECENT_POSTS.replace(':offset/:limit', '0/10'),
      ],
    },
  );
};

/* me-posts-ctrl */
export const useUpdateMePost = () => {
  return useMutation<T.UpdateMePost['request'], T.UpdateMePost['response']>(
    ({params}) => ENDPOINTS.ME_POSTS.replace(':postId?', String(params.postId)),
    {
      method: 'PUT',
      getCacheUpdateKeys: ({params}) => [
        ENDPOINTS.ME_POSTS.replace(':postId?', String(params.postId)),
      ],
    },
  );
};

/* me-posts-ctrl */
export const useDeleteMePost = () => {
  const {currentUser} = useAuthenticatedContext();
  return useMutation<T.DeleteMePost['request'], T.DeleteMePost['response']>(
    ({params}) => ENDPOINTS.ME_POSTS.replace(':postId?', String(params.postId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: () => [
        ENDPOINTS.USER_POSTS.replace(':userId', currentUser.id).replace(
          ':postId?',
          '',
        ),
      ],
    },
  );
};

/* me-profile-ctrl */
export const useUpdateMeProfile = () => {
  const {currentUser} = useAuthenticatedContext();
  return useMutation<
    T.UpdateMeProfile['request'],
    T.UpdateMeProfile['response']
  >(() => ENDPOINTS.ME_PROFILE, {
    method: 'PUT',
    getCacheUpdateKeys: () => [
      ENDPOINTS.USER_PROFILE.replace(':username', currentUser.username),
    ],
    waitForCacheRefresh: true,
  });
};

/* me-settings-email-notifications-ctrl */
export const useUpdateMeEmailNotificationSettings = () => {
  return useMutation<
    T.UpdateMeEmailNotificationSettings['request'],
    T.UpdateMeEmailNotificationSettings['response']
  >(() => ENDPOINTS.ME_SETTINGS_EMAIL_NOTIFICATIONS, {
    method: 'PUT',
    getCacheUpdateKeys: () => [ENDPOINTS.ME_SETTINGS_EMAIL_NOTIFICATIONS],
  });
};

/* me-teams-ctrl */
export const useCreateMeTeam = () => {
  return useMutation<T.CreateMeTeam['request'], T.CreateMeTeam['response']>(
    () => ENDPOINTS.ME_TEAMS,
    {
      method: 'POST',
      getCacheUpdateKeys: () => [ENDPOINTS.ME],
      waitForCacheRefresh: true,
    },
  );
};

export const useUpdateMeTeam = () => {
  return useMutation<T.UpdateMeTeam['request'], T.UpdateMeTeam['response']>(
    () => ENDPOINTS.ME_TEAMS,
    {
      method: 'PUT',
      getCacheUpdateKeys: () => [ENDPOINTS.ME],
    },
  );
};

/* me-user-follow-ctrl */
export const useCreateMeUserFollow = () => {
  return useMutation<
    T.CreateMeUserFollow['request'] & {updateCacheParams: {username: string}},
    T.CreateMeUserFollow['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_USER_FOLLOW.replace(':userId', String(params.userId)),
    {
      method: 'POST',
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.USER_PROFILE.replace(':username', updateCacheParams.username),
      ],
    },
  );
};

export const useDeleteMeUserFollow = () => {
  return useMutation<
    T.DeleteMeUserFollow['request'] & {updateCacheParams: {username: string}},
    T.DeleteMeUserFollow['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_USER_FOLLOW.replace(':userId', String(params.userId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.USER_PROFILE.replace(':username', updateCacheParams.username),
        ENDPOINTS.ME_PROSPECTS.replace(':offset/:limit', '0/50'),
      ],
    },
  );
};

/* me-prospect-follow-ctrl */
export const useCreateMeProspect = () => {
  return useMutation<
    T.CreateMeProspect['request'] & {
      updateCacheParams: {username: string};
    },
    T.CreateMeProspect['response']
  >(() => ENDPOINTS.ME_PROSPECT, {
    method: 'POST',
    getCacheUpdateKeys: ({updateCacheParams}) => [
      ENDPOINTS.USER_PROFILE.replace(':username', updateCacheParams.username),
    ],
  });
};

export const useDeleteMeProspect = () => {
  return useMutation<
    T.DeleteMeProspect['request'] & {
      updateCacheParams: {username: string};
    },
    T.DeleteMeProspect['response']
  >(() => ENDPOINTS.ME_PROSPECT, {
    method: 'DELETE',
    getCacheUpdateKeys: ({updateCacheParams}) => [
      ENDPOINTS.USER_PROFILE.replace(':username', updateCacheParams.username),
      ENDPOINTS.ME_PROSPECTS.replace(':offset/:limit', '0/50'),
    ],
  });
};

/* me-invites-ctrl */
export const useCreateMeInvite = () => {
  const {currentTeam} = useAuthenticatedAsTeamContext();
  return useMutation<T.CreateMeInvite['request'], T.CreateMeInvite['response']>(
    () => ENDPOINTS.ME_INVITES,
    {
      method: 'POST',
      getCacheUpdateKeys: () => [
        ENDPOINTS.ME_TEAM_MEMBERS.replace(':teamId', String(currentTeam.id)),
      ],
    },
  );
};

export const useDeleteMeInvite = () => {
  const {currentTeam} = useAuthenticatedAsTeamContext();
  return useMutation<T.DeleteMeInvite['request'], T.DeleteMeInvite['response']>(
    ({params}) =>
      ENDPOINTS.ME_INVITE.replace(':inviteId', String(params.inviteId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: () => [
        ENDPOINTS.ME_TEAM_MEMBERS.replace(':teamId', String(currentTeam.id)),
      ],
    },
  );
};

export const useRejectMeInvite = () => {
  return useMutation<T.DeleteMeInvite['request'], T.DeleteMeInvite['response']>(
    ({params}) =>
      ENDPOINTS.ME_INVITE.replace(':inviteId', String(params.inviteId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: () => [ENDPOINTS.ME],
    },
  );
};

export const useAcceptMeInvite = () => {
  return useMutation<T.AcceptMeInvite['request'], T.AcceptMeInvite['response']>(
    ({params}) =>
      ENDPOINTS.ME_INVITE_ACCEPT.replace(':inviteId', String(params.inviteId)),
    {
      method: 'POST',
      getCacheUpdateKeys: () => [ENDPOINTS.ME],
    },
  );
};

export const useSearch = () => {
  return useMutation<T.Search['request'], T.Search['response']>(
    () => ENDPOINTS.SEARCH,
    {
      method: 'POST',
    },
  );
};

/* me-team-members */
export const useDeleteMeTeamMember = () => {
  return useMutation<
    T.DeleteMeTeamMember['request'],
    T.DeleteMeTeamMember['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_TEAM_MEMBER.replace(
        ':teamId',
        String(params.teamId),
      ).replace(':userId', String(params.userId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: ({params: {teamId}}) => [
        ENDPOINTS.ME_TEAM_MEMBERS.replace(':teamId', String(teamId)),
      ],
    },
  );
};

/* me-albums */
export const useCreateMeAlbum = () => {
  return useMutation<T.CreateMeAlbum['request'], T.CreateMeAlbum['response']>(
    () => ENDPOINTS.ME_ALBUMS,
    {
      method: 'POST',
      getCacheUpdateKeys: () => [ENDPOINTS.ME_ALBUMS],
    },
  );
};

export const useUpdateMeAlbum = () => {
  return useMutation<T.UpdateMeAlbum['request'], T.UpdateMeAlbum['response']>(
    ({params}) =>
      ENDPOINTS.ME_ALBUM.replace(':albumId', String(params.albumId)),
    {
      method: 'PUT',
      getCacheUpdateKeys: ({params}) => [ENDPOINTS.ME_ALBUMS],
    },
  );
};

export const useDeleteMeAlbum = () => {
  return useMutation<T.DeleteMeAlbum['request'], T.DeleteMeAlbum['response']>(
    ({params}) =>
      ENDPOINTS.ME_ALBUM.replace(':albumId', String(params.albumId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: () => [ENDPOINTS.ME_ALBUMS],
    },
  );
};

/* me-team-follow */
export const useCreateMeTeamFollow = () => {
  return useMutation<
    T.CreateMeTeamFollow['request'] & {updateCacheParams: {slug: string}},
    T.CreateMeTeamFollow['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_TEAM_FOLLOW.replace(':teamId', String(params.teamId)),
    {
      method: 'POST',
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.TEAM_PROFILE.replace(':slug', updateCacheParams.slug),
      ],
    },
  );
};

export const useDeleteMeTeamFollow = () => {
  return useMutation<
    T.DeleteMeTeamFollow['request'] & {updateCacheParams: {slug: string}},
    T.DeleteMeTeamFollow['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_TEAM_FOLLOW.replace(':teamId', String(params.teamId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.TEAM_PROFILE.replace(':slug', updateCacheParams.slug),
      ],
    },
  );
};

/* me-team-profile */
export const useUpdateMeTeamProfile = () => {
  return useMutation<
    T.UpdateMeTeamProfile['request'] & {updateCacheParams: {slug: string}},
    T.UpdateMeTeamProfile['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_TEAM_PROFILE.replace(':teamId', String(params.teamId)),
    {
      method: 'PUT',
      getCacheUpdateKeys: ({updateCacheParams}) => [
        ENDPOINTS.TEAM_PROFILE.replace(':slug', updateCacheParams.slug),
      ],
    },
  );
};

/* super-user */
export const useSuperUser = () => {
  return useMutation<
    T.SuperUserRunAction['request'],
    T.SuperUserRunAction['response']
  >(() => ENDPOINTS.SUPER_USER, {
    method: 'POST',
  });
};

/* me-notes */
export const useCreateMeNote = () => {
  return useMutation<T.CreateMeNote['request'], T.CreateMeNote['response']>(
    () => ENDPOINTS.ME_NOTES,
    {
      method: 'POST',
    },
  );
};

/* me-events */
export const useCreateMeEvent = () => {
  const {currentUser, currentTeam} = useAuthenticatedContext();
  return useMutation<T.CreateMeEvent['request'], T.CreateMeEvent['response']>(
    () => ENDPOINTS.ME_EVENTS,
    {
      method: 'POST',
      getCacheUpdateKeys: () => [
        ENDPOINTS.USER_PROFILE.replace(':username', currentUser.username),
        currentTeam
          ? ENDPOINTS.TEAM_PROFILE.replace(':slug', currentTeam.slug)
          : '',
      ],
    },
  );
};

export const useUpdateMeEvent = () => {
  const {currentUser, currentTeam} = useAuthenticatedContext();

  return useMutation<T.UpdateMeEvent['request'], T.UpdateMeEvent['response']>(
    ({params}) =>
      ENDPOINTS.ME_EVENT.replace(':eventId', String(params.eventId)),
    {
      method: 'PUT',
      getCacheUpdateKeys: () => [
        ENDPOINTS.USER_PROFILE.replace(':username', currentUser.username),
        currentTeam
          ? ENDPOINTS.TEAM_PROFILE.replace(':slug', currentTeam.slug)
          : '',
      ],
    },
  );
};

export const useDeleteMeEvent = () => {
  const {currentUser, currentTeam} = useAuthenticatedContext();
  return useMutation<T.DeleteMeEvent['request'], T.DeleteMeEvent['response']>(
    ({params}) =>
      ENDPOINTS.ME_EVENT.replace(':eventId', String(params.eventId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: () => [
        ENDPOINTS.USER_PROFILE.replace(':username', currentUser.username),
        currentTeam
          ? ENDPOINTS.TEAM_PROFILE.replace(':slug', currentTeam.slug)
          : '',
      ],
    },
  );
};

/* me-post-comment */
export const useCreateMePostComment = () => {
  return useMutation<
    T.CreateMePostComment['request'] & {updateCacheParams: {username: string}},
    T.CreateMePostComment['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_POST_COMMENTS.replace(':postId', String(params.postId)),
    {
      method: 'POST',
      getCacheUpdateKeys: ({updateCacheParams, params}) => [
        ENDPOINTS.USER_POST.replace(
          ':username',
          updateCacheParams.username,
        ).replace(':postId', String(params.postId)),
        ENDPOINTS.POST_COMMENTS.replace(':postId', String(params.postId)),
      ],
    },
  );
};

export const useDeleteMePostComment = () => {
  return useMutation<
    T.DeleteMePostComment['request'] & {updateCacheParams: {username: string}},
    T.DeleteMePostComment['response']
  >(
    ({params}) =>
      ENDPOINTS.ME_POST_COMMENT.replace(
        ':postId',
        String(params.postId),
      ).replace(':commentId', String(params.commentId)),
    {
      method: 'DELETE',
      getCacheUpdateKeys: ({updateCacheParams, params}) => [
        ENDPOINTS.USER_POST.replace(
          ':username',
          updateCacheParams.username,
        ).replace(':postId', String(params.postId)),
        ENDPOINTS.POST_COMMENTS.replace(':postId', String(params.postId)),
      ],
    },
  );
};
