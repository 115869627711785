import {FunctionalComponent} from 'preact';
import {css} from 'styled-components';
import {mediaQuery} from 'pylon/lib';
import {Loading, Stack} from 'pylon/ui';
import {AccountType} from '@shared/database-types';
import {PostHorizontalList} from '@/components/(post)/PostHorizontalList';
import {ProfileBasicCard} from '@/components/(profile)/ProfileBasicCard';
import {ProfileBioCard} from '@/components/(profile)/ProfileBioCard';
import {ProfileEventsCard} from '@/components/(profile)/ProfileEventsCard';
import {ProfileSocialLinksCard} from '@/components/(profile)/ProfileSocialLinksCard';
import {UserProfilePlayerProfileCard} from '@/components/(profile)/UserProfilePlayerProfileCard';
import {UserProfilePlayerSkillsCard} from '@/components/(profile)/UserProfilePlayerSkillsCard';
import {useUserPosts, useUsersProfile} from '@/fetch';

type Props = {
  params: {
    username: string;
  };
};

export const PublicUserProfileOverviewRoute: FunctionalComponent<Props> = ({
  params: {username},
}) => {
  const {data: profile, loading} = useUsersProfile({
    params: {
      username,
    },
  });
  const {data: posts, loading: loadingPosts} = useUserPosts(
    {
      params: {
        userId: profile?.user.id,
      },
    },
    {
      skip: !profile,
    },
  );

  if (loading) {
    return <Loading centered />;
  }

  return (
    <Stack direction="column" gap={12}>
      <PostHorizontalList loading={loadingPosts} posts={posts} />
      {profile.user.accountType === AccountType.PLAYER ? (
        <div
          css={`
            display: flex;
            flex-direction: column;
            gap: var(--gap-8);
            grid-template-columns: 1fr;
            ${mediaQuery(
              'md>',
              css`
                align-items: flex-start;
                display: grid;
                grid-template-columns: 1fr 1fr;
              `,
            )}
          `}
        >
          <Stack direction="column" gap={8}>
            <ProfileBioCard profile={profile} />
            <UserProfilePlayerProfileCard profile={profile} />
          </Stack>
          <Stack direction="column" gap={8}>
            <UserProfilePlayerSkillsCard profile={profile} />
            <ProfileSocialLinksCard profile={profile} />
            <ProfileEventsCard profile={profile} />
          </Stack>
        </div>
      ) : (
        <>
          <div
            css={`
              display: flex;
              flex-direction: column;
              gap: var(--gap-8);
              grid-template-columns: 1fr;
              ${mediaQuery(
                'md>',
                css`
                  align-items: flex-start;
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                `,
              )}
            `}
          >
            <Stack direction="column" gap={8}>
              <ProfileBioCard profile={profile} />
              <ProfileBasicCard profile={profile} />
            </Stack>
            <ProfileSocialLinksCard profile={profile} />
            <ProfileEventsCard profile={profile} />
          </div>
        </>
      )}
    </Stack>
  );
};
