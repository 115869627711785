import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Controller, useForm} from 'react-hook-form';
import {
  Button,
  Form,
  FormSubmit,
  FormTextarea,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {Note, User} from '@shared/database-types';
import {ErrorCode} from '@shared/error-code';
import {useCreateMeNote} from '@/fetch';
import {useNetworkError} from '@/lib/use-network-error';

type FormData = Pick<Note, 'content'>;

type Props = {
  opened: boolean;
  targetUserId: User['id'];
  setOpened: (opened: boolean) => void;
  defaultNote: Note | null;
};

export const NotesButton_FormOverlay: FunctionalComponent<Props> = ({
  opened,
  targetUserId,
  defaultNote,
  setOpened,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const [createMeNote] = useCreateMeNote();

  const {
    control,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      content: defaultNote ? defaultNote.content : '',
    },
  });

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await createMeNote({
        body: {
          targetUserId,
          content: formData.content,
          noteId: defaultNote ? defaultNote.id : undefined,
        },
      });
      setOpened(false);
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
  };

  const handleCancel = () => {
    setOpened(false);
  };

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      closeOthers
      cancelOnEscKey
      cancelOnOutsideClick
      animation="scale-in"
      withBackdrop
      position={{
        mode: 'centered',
      }}
      css={`
        ${overlayPopupCss}
      `}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h5"
          textTransform="uppercase"
          fontStyle="italic"
          css-mb={1}
        >
          Notes
        </Typography>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        <Typography css-mb={5}>
          Write notes about this player. These notes are private and only you
          can see them.
        </Typography>
        <Controller
          name="content"
          control={control}
          render={({field: {onChange, onBlur, value}}) => (
            <FormTextarea
              id="bio"
              defaultValue={value ?? ''}
              onChange={onChange}
              onBlur={onBlur}
              invalid={!!errors.content}
              invalidText={errors.content?.message}
              fullWidth
            />
          )}
        />
        <Stack gap={4} css-mt={6}>
          <FormSubmit submitting={submiting} disabled={submiting}>
            Save
          </FormSubmit>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Form>
    </Overlay>
  );
};
