import {FunctionalComponent} from 'preact';
import {ErrorBoundary, LocationProvider, Route, Router} from 'preact-iso';
import {useLocalStorage} from 'pylon/lib';
import {Redirect} from 'pylon/ui';
import {useMe} from '@/fetch';
import {AppContext, AppContextType} from '@/lib/app-context';
import {ROUTES} from '@/lib/app-routes';
import {useFirebase} from '@/lib/use-firebase';
import {withAuth} from '@/lib/with-auth';
import {TeamSettingsRoute} from './(authed)/(team-settings)/TeamSettingsRoute';
import {UserSettingsRoute} from './(authed)/(user-settings)/UserSettingsRoute';
import {AuthedFollowingRoute} from './(authed)/AuthedFollowingRoute';
import {AuthedGetStartedRoute} from './(authed)/AuthedGetStartedRoute';
import {AuthedNotificationsRoute} from './(authed)/AuthedNotificationsRoute';
import {AuthedProspectPlannerRoute} from './(authed)/AuthedProspectPlannerRoute';
import {GuestForgotPassword} from './(guest)/GuestForgotPassword';
import {GuestSignInRoute} from './(guest)/GuestSignInRoute';
import {GuestSignUpRoute} from './(guest)/GuestSignUpRoute';
import {PublicTeamProfileRoute} from './(public)/(team-profile)/PublicTeamProfileRoute';
import {PublicUserProfileRoute} from './(public)/(user-profile)/PublicUserProfileRoute';
import {PublicConfirmEmail} from './(public)/PublicConfirmEmail';
import {PublicHomeRoute} from './(public)/PublicHomeRoute';
import {PublicPrivacyRoute} from './(public)/PublicPrivacyRoute';
import {PublicSearchRoute} from './(public)/PublicSearchRoute';
import {PublicTermsRoute} from './(public)/PublicTermsRoute';

export const AppRouter: FunctionalComponent = () => {
  const {user: firebaseUser, loading: loadingFirebaseUser} = useFirebase();
  const [currentTeamId, setCurrentTeamId] = useLocalStorage<string | null>(
    'currentTeamId',
    null,
  );
  const {data: user, loading: loadingUser} = useMe(null, {
    skip: !firebaseUser,
  });

  const setCurrentTeam: AppContextType['setCurrentTeam'] = (teamId) => {
    const teamExists = user?.teams.some((t) => String(t.id) === teamId);
    setCurrentTeamId(teamExists ? String(teamId) : null);
  };

  const currentTeam = currentTeamId
    ? user?.teams.find((t) => String(t.id) === currentTeamId) || null
    : null;

  return (
    <LocationProvider>
      <ErrorBoundary>
        <AppContext.Provider
          value={{
            currentUser: user,
            currentFirebaseUser: firebaseUser,
            currentTeam,
            setCurrentTeam,
            loadingUser,
            loadingFirebaseUser,
          }}
        >
          <Router>
            {/* GUEST */}
            <Route
              path={ROUTES.GUEST_SIGN_IN}
              component={withAuth(GuestSignInRoute, 'onlyNotAuthenticated')}
            />
            <Route
              path={ROUTES.GUEST_SIGN_UP}
              component={withAuth(GuestSignUpRoute, 'onlyNotAuthenticated')}
            />
            <Route
              path={ROUTES.GUEST_FORGOT_PASSWORD}
              component={withAuth(GuestForgotPassword, 'onlyNotAuthenticated')}
            />
            {/* AUTHED */}
            <Route
              path={ROUTES.AUTHED_NOTIFICATIONS}
              component={withAuth(
                AuthedNotificationsRoute,
                'onlyAuthenticated',
              )}
            />
            <Route
              path={ROUTES.AUTHED_FOLLOWING}
              component={withAuth(AuthedFollowingRoute, 'onlyAuthenticated')}
            />
            <Route
              path={ROUTES.AUTHED_PROSPECT_PLANNER}
              component={withAuth(
                AuthedProspectPlannerRoute,
                'onlyAuthenticated',
              )}
            />
            <Route
              path={ROUTES.PUBLIC_CONFIRM_EMAIL}
              component={withAuth(PublicConfirmEmail)}
            />
            <Route
              path={ROUTES.AUTHED_GET_STARTED}
              component={withAuth(
                AuthedGetStartedRoute,
                'onlyAuthenticatedAsUser',
              )}
            />
            <Route
              path={ROUTES.AUTHED_SETTINGS}
              component={() => <Redirect to={ROUTES.AUTHED_SETTINGS_ACCOUNT} />}
            />
            <Route
              path={`${ROUTES.AUTHED_SETTINGS}/*`}
              component={withAuth(UserSettingsRoute, 'onlyAuthenticatedAsUser')}
            />
            <Route
              path={ROUTES.AUTHED_TEAM_SETTINGS}
              component={() => (
                <Redirect to={ROUTES.AUTHED_TEAM_SETTINGS_GENERAL} />
              )}
            />
            <Route
              path={`${ROUTES.AUTHED_TEAM_SETTINGS}/*`}
              component={withAuth(TeamSettingsRoute, 'onlyAuthenticatedAsTeam')}
            />
            {/* PUBLIC */}
            <Route
              path={ROUTES.PUBLIC_HOME}
              component={withAuth(PublicHomeRoute)}
            />
            <Route
              path={ROUTES.PUBLIC_TERMS}
              component={withAuth(PublicTermsRoute)}
            />
            <Route
              path={ROUTES.PUBLIC_PRIVACY}
              component={withAuth(PublicPrivacyRoute)}
            />
            <Route
              path={ROUTES.PUBLIC_EMPTY_SEARCH_ROUTE}
              component={withAuth(PublicSearchRoute)}
            />
            <Route
              path={ROUTES.PUBLIC_SEARCH_ROUTE}
              component={withAuth(PublicSearchRoute)}
            />
            <Route
              path={ROUTES.PUBLIC_TEAM_PROFILE}
              component={withAuth(PublicTeamProfileRoute)}
            />
            <Route
              path={`${ROUTES.PUBLIC_TEAM_PROFILE}/*`}
              component={withAuth(PublicTeamProfileRoute)}
            />

            <Route
              path={ROUTES.PUBLIC_USER_PROFILE}
              component={withAuth(PublicUserProfileRoute)}
            />
            <Route
              path={`${ROUTES.PUBLIC_USER_PROFILE}/*`}
              component={withAuth(PublicUserProfileRoute)}
            />
          </Router>
        </AppContext.Provider>
      </ErrorBoundary>
    </LocationProvider>
  );
};
