import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {css} from 'styled-components';
import {breakpoints, mediaQuery, useWindowSize} from 'pylon/lib';
import {scrollBarMixin} from 'pylon/style/mixins';
import {Divider, Grid, Select, Stack, TabMenu} from 'pylon/ui';
import {AccountType} from '@shared/database-types';
import {useAuthenticatedContext} from '@/lib/app-context';
import {
  RegisteredNames as BioRegisteredNames,
  ProfileForm_ControlBio,
} from './(controls)/_ControlBio';
import {
  RegisteredNames as CoverPhotoRegisteredNames,
  ProfileForm_ControlCoverPhoto,
} from './(controls)/_ControlCoverPhoto';
import {
  RegisteredNames as PlayerDetailsRegisterdNames,
  ProfileForm_ControlPlayerDetails,
} from './(controls)/_ControlPlayerDetails';
import {
  RegisteredNames as PlayerSkillsRegisteredNames,
  ProfileForm_ControlPlayerSkills,
} from './(controls)/_ControlPlayerSkills';
import {
  ProfileForm_ControlSocialLinks,
  RegisteredNames as SocialLinksRegisteredNames,
} from './(controls)/_ControlSocialLinks';
import {
  ProfileForm_ControlStateAndCity,
  RegisteredNames as StateAndCityRegisteredNames,
} from './(controls)/_ControlStateAndCity';
import {ProfileFormState} from './ProfileForm';

enum Tab {
  GENERAL = 'general',
  PLAYER_DETAILS = 'player-details',
  PLAYER_SKILLS = 'player-skills',
  SOCIAL_PROFILES = 'social-profiles',
}

type Props = {
  formState: ProfileFormState;
};

export const ProfileForm_Tabs: FunctionalComponent<Props> = ({formState}) => {
  const windowSize = useWindowSize();
  const {currentUser} = useAuthenticatedContext();
  const tabsForAccountType = tabs.filter(
    (tab) =>
      !tab.onlyFor ||
      tab.onlyFor.some(
        (accountType) => accountType === currentUser?.accountType,
      ),
  );
  const [currentTab, setCurrentTab] = useState<Tab>(
    tabsForAccountType[0].value as Tab,
  );
  const currentTabControls = tabsConfig[currentTab].controls;
  const tabsWithErrors = tabsForAccountType
    .filter((tab) =>
      tabsConfig[tab.value as Tab].registeredNames.some(
        (name) => formState.errors[name],
      ),
    )
    .map((tab) => tab.value as Tab);

  return (
    <div
      css={`
        padding: 0 var(--gap-4) var(--gap-4);
        ${mediaQuery(
          'md>',
          css`
            display: grid;
            gap: var(--gap-14);
            grid-template-columns: 10rem 1fr;
            padding: var(--gap-5) var(--gap-8) var(--gap-8) var(--gap-4);
          `,
        )}
      `}
    >
      {windowSize.width > breakpoints.md && (
        <TabMenu
          direction="column"
          items={tabsForAccountType}
          defaultValue={currentTab}
          tabsWithErrors={tabsWithErrors}
          onChange={(tab) => setCurrentTab(tab.value as Tab)}
        />
      )}
      <Stack
        direction="column"
        gap={8}
        css={`
          height: 40rem;
          max-height: calc(80vh - 6.25rem);
          overflow-y: auto;
          ${scrollBarMixin}
        `}
      >
        {windowSize.width <= breakpoints.md && (
          <Stack direction="column" css-mb={4}>
            <Select
              defaultValue={currentTab}
              options={tabsForAccountType.map((t) => ({
                label: t.content,
                value: t.value,
              }))}
              onChange={(evt) => setCurrentTab(evt.currentTarget!.value as Tab)}
            />
            <Divider css-mt={4} />
          </Stack>
        )}
        {currentTabControls.map(
          (Control: FunctionalComponent<ProfileFormState>, idx: number) => (
            <Control key={idx} {...formState} />
          ),
        )}
      </Stack>
    </div>
  );
};

const tabs: {
  content: string;
  value: Tab;
  onlyFor?: AccountType[];
}[] = [
  {
    content: 'General',
    value: Tab.GENERAL,
  },
  {
    content: 'Player details',
    value: Tab.PLAYER_DETAILS,
    onlyFor: [AccountType.PLAYER],
  },
  {
    content: 'Player skills',
    value: Tab.PLAYER_SKILLS,
    onlyFor: [AccountType.PLAYER],
  },
  {
    content: 'Social profiles',
    value: Tab.SOCIAL_PROFILES,
  },
];

const tabsConfig = {
  [Tab.GENERAL]: {
    registeredNames: [
      ...Object.values(CoverPhotoRegisteredNames),
      ...Object.values(StateAndCityRegisteredNames),
      ...Object.values(BioRegisteredNames),
    ],
    controls: [
      ProfileForm_ControlCoverPhoto,
      ProfileForm_ControlStateAndCity,
      ProfileForm_ControlBio,
    ],
  },
  [Tab.PLAYER_DETAILS]: {
    registeredNames: [...Object.values(PlayerDetailsRegisterdNames)],
    controls: [ProfileForm_ControlPlayerDetails],
  },
  [Tab.PLAYER_SKILLS]: {
    registeredNames: [...Object.values(PlayerSkillsRegisteredNames)],
    controls: [ProfileForm_ControlPlayerSkills],
  },
  [Tab.SOCIAL_PROFILES]: {
    registeredNames: [...Object.values(SocialLinksRegisteredNames)],
    controls: [ProfileForm_ControlSocialLinks],
  },
};
