import {ROUTES} from '@/lib/app-routes';
import {SearchFiltersType} from './SearchFilters';

export function searchFiltersToUrl(params: SearchFiltersType) {
  return (
    ROUTES.PUBLIC_EMPTY_SEARCH_ROUTE +
    '?' +
    new URLSearchParams(params as any).toString()
  );
}
