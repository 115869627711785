import {FunctionalComponent} from 'preact';
import {Controller} from 'react-hook-form';
import {FormTextarea} from 'pylon/ui';
import {validateProfileBio} from '@shared/validators';
import {handleFormValidate} from '@/lib/error-messages';
import {TeamProfileFormState} from '../TeamProfileForm';

export enum RegisteredNames {
  BIO = 'bio',
}

export const ProfileForm_ControlBio: FunctionalComponent<
  TeamProfileFormState
> = ({control, errors}) => {
  return (
    <Controller
      name={RegisteredNames.BIO}
      control={control}
      rules={{
        validate: (value) => handleFormValidate(validateProfileBio(value)),
      }}
      render={({field: {onChange, onBlur, value}}) => (
        <FormTextarea
          id="bio"
          label="Bio"
          defaultValue={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          invalid={!!errors[RegisteredNames.BIO]}
          invalidText={errors?.[RegisteredNames.BIO]?.message}
          fullWidth
        />
      )}
    />
  );
};
