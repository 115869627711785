import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {Button} from 'pylon/ui';
import {GetTeamProfile} from '@shared/api-types';
import {canBeFollowed, canFollow} from '@shared/permissions';
import {useCreateMeTeamFollow, useDeleteMeTeamFollow} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';

type Props = {
  profile: GetTeamProfile['response'];
};

export const FollowTeamButton: FunctionalComponent<Props> = ({profile}) => {
  const {currentUser, currentTeam} = useAuthenticatedContext();
  const [submitting, setSubmitting] = useState(false);
  const {isFollowing, team} = profile!;
  const [createMeTeamFollow] = useCreateMeTeamFollow();
  const [deleteMeTeamFollow] = useDeleteMeTeamFollow();

  const handleFollow = async () => {
    setSubmitting(true);
    try {
      if (isFollowing) {
        await deleteMeTeamFollow({
          params: {
            teamId: team.id,
          },
          updateCacheParams: {
            slug: team.slug,
          },
        });
      } else {
        await createMeTeamFollow({
          params: {
            teamId: team.id,
          },
          updateCacheParams: {
            slug: team.slug,
          },
        });
      }
    } catch (err) {
      console.warn(err);
    }
    setSubmitting(false);
  };

  if (currentTeam || !canFollow(currentUser.accountType)) {
    return null;
  }

  return (
    <Button
      variant={isFollowing ? 'outline' : 'primary'}
      onClick={handleFollow}
      disabled={submitting}
      {...(!isFollowing && {iconLeading: 'plus'})}
    >
      <span>{isFollowing ? 'Unfollow' : 'Follow'}</span>
    </Button>
  );
};
