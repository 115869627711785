import {FunctionalComponent} from 'preact';
import {MutableRef, useRef, useState} from 'preact/hooks';
import styled, {css} from 'styled-components';
import {useWindowSize} from 'pylon/lib';
import {IconButton} from 'pylon/ui';
import {GetUserPosts} from '@shared/api-types';
import {PostCard} from './PostCard';

type Props = {
  loading: boolean;
  posts: GetUserPosts['response'];
};

export const PostHorizontalList: FunctionalComponent<Props> = ({
  posts,
  loading,
}) => {
  useWindowSize();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const containerRef = useRef() as MutableRef<HTMLDivElement>;

  const checkScroll = () => {
    if (containerRef.current) {
      const {scrollLeft, scrollWidth, clientWidth} = containerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollHorizontally = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const currentScroll = containerRef.current.scrollLeft;
      const scrollAmount = direction === 'left' ? -300 : 300;
      containerRef.current.scrollTo({
        left: currentScroll + scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  if (loading || !posts.length) {
    return null;
  }

  return (
    <div
      css={`
        display: grid;
        position: relative;
      `}
    >
      <StyledArrowIcon
        icon="chevron-forward"
        variant="unstyled"
        onClick={() => scrollHorizontally('left')}
        iconSize={20}
        $direction="back"
        $disabled={!canScrollLeft}
      />
      <StyledArrowIcon
        icon="chevron-forward"
        variant="unstyled"
        $direction="forward"
        iconSize={20}
        onClick={() => scrollHorizontally('right')}
        $disabled={!canScrollRight}
      />
      <div
        ref={(el: HTMLDivElement | null) => {
          if (el) {
            containerRef.current = el;
          }
          checkScroll();
        }}
        onScroll={checkScroll}
        css={`
          display: flex;
          gap: var(--gap-4);
          max-width: 100%;
          overflow-x: auto;
          scrollbar-width: none;
        `}
      >
        {posts.map((post) => (
          <PostCard key={post.id} post={post} height={180} />
        ))}
      </div>
    </div>
  );
};

const StyledArrowIcon = styled(IconButton)<{
  $direction: 'back' | 'forward';
  $disabled: boolean;
}>`
  align-items: center;
  background: var(--fg-default);
  border-radius: var(--radius-round);
  box-shadow: var(--shadow-1);
  color: var(--bg-subtle);
  display: flex;
  height: 34px;
  justify-content: center;
  opacity: 1;
  position: absolute;
  top: 50%;
  transition: opacity 0.2s ease-in-out;
  width: 34px;
  z-index: 10;
  ${(p) =>
    p.$disabled &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
  ${(p) =>
    p.$direction === 'back'
      ? css`
          left: 0.625rem;
          transform: translateY(-50%) rotate(180deg);
          padding-left: 0.125rem;
        `
      : css`
          right: 10px;
          transform: translateY(-50%);
        `};
`;
