import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import {
  Button,
  DropdownMenu,
  IconButton,
  Stack,
  menuItemVariant,
} from 'pylon/ui';
import {GetUserPosts} from '@shared/api-types';
import {VideoRatio} from '@shared/database-types';
import {useAppContext} from '@/lib/app-context';
import {toMediaPath} from '@/lib/environment';
import {DeletePostOverlay} from './DeletePostOverlay';
import {LikePostButton} from './LikePostButton';
import {PostOverlay} from './PostOverlay';

type Props = {
  post: GetUserPosts['response'][0];
  height?: number;
  width?: number;
};

export const PostCard: FunctionalComponent<Props> = ({
  post,
  height = 270,
  width,
}) => {
  const [openPost, setOpenPost] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const {currentUser} = useAppContext();

  const handleMouseEnter = (event: MouseEvent) => {
    const video = event.target as HTMLVideoElement;
    video.play();
  };

  const handleMouseLeave = (event: MouseEvent) => {
    const video = event.target as HTMLVideoElement;
    video.pause();
    video.currentTime = 0;
  };

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenPost(true);
  };

  const ratioSizeMap: {
    [key in VideoRatio]: number;
  } = {
    [VideoRatio.RATIO_16_9]: (19 / 12) * height,
    [VideoRatio.RATIO_9_16]: (12 / 16) * height,
    [VideoRatio.RATIO_1_1]: height,
  };

  return (
    <Stack direction="column" gap={3}>
      <div
        aria-label="Watch in full screen"
        role="button"
        onClick={handleClick}
        css={`
          border-radius: var(--radius-3);
          cursor: pointer;
          height: ${height}px;
          max-width: 100%;
          overflow: hidden;
          position: relative;
          width: ${width || ratioSizeMap[post.video!.ratio]}px;
          &:after {
            bottom: 0;
            content: '';
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            background: -webkit-gradient(
              linear,
              left top,
              left bottom,
              from(transparent),
              color-stop(8.1%, transparent),
              color-stop(15.5%, rgba(0, 0, 0, 0.001)),
              color-stop(22.5%, rgba(0, 0, 0, 0.003)),
              color-stop(29%, rgba(0, 0, 0, 0.005)),
              color-stop(35.3%, rgba(0, 0, 0, 0.008)),
              color-stop(41.2%, rgba(0, 0, 0, 0.011)),
              color-stop(47.1%, rgba(0, 0, 0, 0.014)),
              color-stop(52.9%, rgba(0, 0, 0, 0.016)),
              color-stop(58.8%, rgba(0, 0, 0, 0.019)),
              color-stop(64.7%, rgba(0, 0, 0, 0.022)),
              color-stop(71%, rgba(0, 0, 0, 0.025)),
              color-stop(77.5%, rgba(0, 0, 0, 0.027)),
              color-stop(84.5%, rgba(0, 0, 0, 0.029)),
              color-stop(91.9%, rgba(0, 0, 0, 0.03)),
              to(rgba(0, 0, 0, 0.03))
            );
          }
          &:hover .hover-opacity {
            opacity: 1;
          }
        `}
      >
        <div
          className="hover-opacity"
          css={`
            align-items: end;
            background: linear-gradient(
              180deg,
              transparent 62%,
              rgba(0, 0, 0, 0.00345888) 63.94%,
              rgba(0, 0, 0, 0.014204) 65.89%,
              rgba(0, 0, 0, 0.0326639) 67.83%,
              rgba(0, 0, 0, 0.0589645) 69.78%,
              rgba(0, 0, 0, 0.0927099) 71.72%,
              rgba(0, 0, 0, 0.132754) 73.67%,
              rgba(0, 0, 0, 0.177076) 75.61%,
              rgba(0, 0, 0, 0.222924) 77.56%,
              rgba(0, 0, 0, 0.267246) 79.5%,
              rgba(0, 0, 0, 0.30729) 81.44%,
              rgba(0, 0, 0, 0.341035) 83.39%,
              rgba(0, 0, 0, 0.367336) 85.33%,
              rgba(0, 0, 0, 0.385796) 87.28%,
              rgba(0, 0, 0, 0.396541) 89.22%,
              rgba(0, 0, 0, 0.4) 91.17%
            );
            display: flex;
            inset: 0;
            justify-content: start;
            opacity: 0;
            padding: var(--gap-3);
            pointer-events: none;
            position: absolute;
            transition: opacity 300ms ease;
            z-index: 1;
          `}
        />
        <video
          src={toMediaPath(post.video!.path) + '#t=0.001'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          muted
          controls={false}
          loop
          playsinline
          webkit-playsinline
          preload="metadata"
          css={`
            height: calc(100% + 2px);
            left: -1px;
            object-fit: cover;
            position: absolute;
            top: -1px;
            width: calc(100% + 2px);
          `}
        />
        <Stack
          gap={1}
          css={`
            bottom: 0.5rem;
            position: absolute;
            right: 0.75rem;
          `}
        >
          {currentUser?.id === post.user.id && (
            <DropdownMenu
              overlayPosition={{
                horizontalAlign: 'center',
              }}
            >
              <IconButton
                className="hover-opacity"
                icon="ellipsis-horizontal"
                buttonSize="sm"
                css={`
                  position: relative;
                  z-index: 2;
                  color: var(--white);
                  opacity: 0;
                  &:hover {
                    opacity: 1;
                    color: var(--white);
                    background: var(--gray80);
                  }
                `}
              />
              <Button
                customVariant={menuItemVariant}
                iconTrailing="trash"
                onClick={() => setOpenDelete(true)}
              >
                Delete
              </Button>
            </DropdownMenu>
          )}
          {currentUser && (
            <LikePostButton
              post={post}
              css={`
                position: relative;
                z-index: 2;
              `}
            />
          )}
        </Stack>
      </div>
      {openDelete && (
        <DeletePostOverlay
          opened={openDelete}
          setOpened={setOpenDelete}
          postId={post.id}
        />
      )}
      {openPost && (
        <PostOverlay
          opened={openPost}
          setOpened={setOpenPost}
          username={post.user.username}
          postId={String(post.id)}
        />
      )}
    </Stack>
  );
};
