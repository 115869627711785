export enum SuperUserAction {
  VERIFY_TEAM = 'verify-team',
  VERIFY_USER = 'verify-user',
  UNVERIFY_TEAM = 'unverify-team',
  UNVERIFY_USER = 'unverify-user',
  REMOVE_POST = 'remove-post',
  REMOVE_COMMENT = 'remove-comment',
  ADD_SUPER_USER = 'make-super-user',
  REMOVE_SUPER_USER = 'remove-super-user',
}

export type SuperUserRunAction = {
  request: {
    body: {
      action: SuperUserAction;
      objectId: number | string;
    };
  };
  response: {
    success: boolean;
  };
};
