import {FunctionalComponent} from 'preact';
import {useRef, useState} from 'preact/hooks';
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadBytes,
} from '@firebase/storage';
import {Controller, useForm} from 'react-hook-form';
import {breakpoints, makeid, useWindowSize} from 'pylon/lib';
import {
  Button,
  DescriptionList,
  DescriptionListItem,
  Form,
  FormInput,
  FormSubmit,
  ImageInput,
  Img,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {UpdateMe} from '@shared/api-types';
import {IMAGES_TMP_STORAGE_PATH} from '@shared/constants';
import {ErrorCode} from '@shared/error-code';
import {validateUsername} from '@shared/validators';
import {useUpdateMe} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {isDevelopment} from '@/lib/environment';
import {handleFormValidate} from '@/lib/error-messages';
import {capitalize} from '@/lib/utils';
import {PDAvatar} from '../PDAvatar';

type FormData = UpdateMe['request']['body'];

type Props = {};

export const UserSettingsMeForm: FunctionalComponent<Props> = () => {
  const windowSize = useWindowSize();
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [updateMe] = useUpdateMe();
  const {currentUser} = useAuthenticatedContext();
  const tmpImageArrayBuffer = useRef<ArrayBuffer | null>(null);
  const [tmpImageBase64, setTmpImageBase64] = useState<string | null>(null);

  const {
    register,
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    },
  });

  const uploadToStorate = async () => {
    if (tmpImageArrayBuffer.current) {
      const storage = getStorage();
      if (isDevelopment) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      const resp = await uploadBytes(
        ref(
          storage,
          `${IMAGES_TMP_STORAGE_PATH}/${currentUser.id}/${makeid(10)}.jpg`,
        ),
        tmpImageArrayBuffer.current,
        {contentType: 'image/jpeg'},
      );
      return resp.metadata.fullPath;
    } else {
      return null;
    }
  };

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmitting(true);
    try {
      let tmpAvatarStoragePath: string = '';
      if (tmpImageArrayBuffer.current) {
        const path = await uploadToStorate();
        if (path) {
          tmpAvatarStoragePath = path;
        }
      }
      await updateMe({
        body: {
          username: sanitizeUsername(formData.username),
          firstName: formData.firstName,
          lastName: formData.lastName,
          tmpAvatarStoragePath,
        },
      });
      setEditing(false);
      setTmpImageBase64(null);
      tmpImageArrayBuffer.current = null;
    } catch (error: any) {
      setNetworkError(error?.message ?? ErrorCode.SOMETHING_WENT_WRONG);
    }
    setSubmitting(false);
  };

  const content = (
    <>
      <Typography variant="body2" weight="bold" css-mb={4}>
        General
      </Typography>
      <DescriptionList expandWidthSize={6}>
        <DescriptionListItem title="Username">
          {editing ? (
            <FormInput
              {...register('username', {
                validate: (value) =>
                  handleFormValidate(validateUsername(sanitizeUsername(value))),
              })}
              id="username"
              defaultValue={currentUser.username ?? ''}
              invalid={!!errors.username}
              invalidText={errors?.username?.message}
              fullWidth
              size="sm"
            />
          ) : (
            <>{currentUser.username}</>
          )}
        </DescriptionListItem>
        <DescriptionListItem
          title="Role"
          tooltipProps={{
            text: 'If you want to change your account role, please contact us at support@prospectdugout.com',
          }}
        >
          <span
            css={`
              color: var(--fg-muted);
            `}
          >
            {capitalize(currentUser.accountType)}
          </span>
        </DescriptionListItem>
        <DescriptionListItem
          title="Pro status"
          tooltipProps={{
            text: `Become a standout in Prospect Dugout with a verified pro account. ${!currentUser.verified ? 'Our team will review your account soon, please be patient.' : ''}`,
          }}
        >
          {currentUser.verified ? 'Verified' : 'No'}
        </DescriptionListItem>

        <DescriptionListItem title="First name">
          {editing ? (
            <FormInput
              {...register('firstName', {required: 'First name is required'})}
              id="firstName"
              defaultValue={currentUser.firstName ?? ''}
              invalid={!!errors.firstName}
              invalidText={errors?.firstName?.message}
              fullWidth
              size="sm"
            />
          ) : (
            <>{currentUser.firstName}</>
          )}
        </DescriptionListItem>
        <DescriptionListItem title="Last name">
          {editing ? (
            <FormInput
              {...register('lastName', {required: 'Last name is required'})}
              id="lastName"
              defaultValue={currentUser.lastName ?? ''}
              invalid={!!errors.lastName}
              invalidText={errors?.lastName?.message}
              fullWidth
              size="sm"
            />
          ) : (
            <>{currentUser.lastName}</>
          )}
        </DescriptionListItem>

        <DescriptionListItem title="Email">
          <span
            css={`
              color: var(--fg-muted);
            `}
          >
            {currentUser.email.replace(/(.{4})(.*)(@.*)/, '$1...$3')}
          </span>
        </DescriptionListItem>
        <DescriptionListItem title="Avatar">
          <Stack alignItems="center" gap={4}>
            {tmpImageBase64 ? (
              <Img
                src={tmpImageBase64}
                css={`
                  background-size: cover;
                  border-radius: 50%;
                  display: block;
                  height: 5rem;
                  margin-right: var(--gap-2);
                  width: 5rem;
                `}
              />
            ) : (
              <PDAvatar user={currentUser} size="xl" />
            )}

            {editing && (
              <Controller
                name={`tmpAvatarStoragePath`}
                control={control}
                render={({field: {ref}}) => (
                  <ImageInput
                    ref={ref}
                    aspectFn={() => 1}
                    buttonProps={{
                      buttonSize:
                        windowSize.width >= breakpoints.md ? 'md' : 'sm',
                      variant: 'outline',
                    }}
                    onSubmit={({base64, arrayBuffer}) => {
                      if (!base64 || !arrayBuffer) return;
                      tmpImageArrayBuffer.current = arrayBuffer;
                      setTmpImageBase64(base64);
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </DescriptionListItem>
      </DescriptionList>
    </>
  );

  if (editing) {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {ErrorCode.SOMETHING_WENT_WRONG}
          </ToastMessage>
        )}
        {content}
        <Stack gap={4} css-mt={8}>
          <FormSubmit
            value="Save"
            buttonSize="md"
            variant="primary"
            submitting={submitting}
          />
          <Button
            buttonSize="md"
            variant="default"
            onClick={() => setEditing(false)}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    );
  } else {
    return (
      <>
        {content}
        <Button
          buttonSize="md"
          variant="default"
          css-mt={8}
          onClick={() => setEditing(true)}
        >
          Edit settings
        </Button>
      </>
    );
  }
};

function sanitizeUsername(username: string) {
  return username.toLocaleLowerCase().trim();
}
