import {FunctionalComponent} from 'preact';
import {useState} from 'preact/hooks';
import styled, {css, keyframes} from 'styled-components';
import {Button, Typography} from 'pylon/ui';
import {GetUserPosts} from '@shared/api-types';
import {useCreateMePostLike, useDeleteMePostLike} from '@/fetch';
import {PDIcon} from '../PDIcon';

type Props = {
  post: GetUserPosts['response'][0];
};

export const LikePostButton: FunctionalComponent<Props> = ({
  post,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [createMePostLike] = useCreateMePostLike();
  const [deleteMePostLike] = useDeleteMePostLike();
  const [clicked, setClicked] = useState(false);

  const handleClick = async (evt: MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (submitting) return;
    setClicked(true);
    setSubmitting(true);
    try {
      const mutationFn = post.likedByCurrentUser
        ? deleteMePostLike
        : createMePostLike;
      await mutationFn({
        params: {
          postId: post.id,
        },
        updateCacheParams: {
          userId: post.user.id,
        },
      });
    } catch (error: unknown) {
      console.warn(error);
    }
    setSubmitting(false);
  };

  return (
    <Button
      variant="unstyled"
      onClick={handleClick}
      disabled={submitting}
      {...props}
    >
      <StyledHeartIcon
        icon={post.likedByCurrentUser ? 'heart' : 'heart-outline'}
        size={18}
        $liked={post.likedByCurrentUser}
        $clicked={clicked}
      />
      <Typography
        variant="body4"
        css-color="var(--white)"
        css={`
          margin-left: 0.25rem;
          min-width: 0.5rem;
          text-align: center;
        `}
      >
        {post.likesCount}
      </Typography>
    </Button>
  );
};

/* style-dcomponents */
const StyledHeartIcon = styled(PDIcon)<{$liked: boolean; $clicked: boolean}>`
  animation: ${(p) =>
    p.$liked && p.$clicked
      ? css`
          ${likeAnimation} 1.2s both cubic-bezier(0.45, 0.05, 0.55, 0.95)
        `
      : ''};
  margin-top: '-0.125rem';
  color: ${(p) => (p.$liked ? 'var(--red50)' : 'var(--white)')};
`;

const likeAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  15% {
    transform: scale(1.4);
  }
  32% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
`;
