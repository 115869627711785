import {FunctionalComponent} from 'preact';
import {useRef, useState} from 'preact/hooks';
import {css} from 'styled-components';
import {breakpoints, mediaQuery, useWindowSize} from 'pylon/lib';
import {
  Button,
  CheckboxGroup,
  FormSubmit,
  Overlay,
  Stack,
  Typography,
} from 'pylon/ui';
import {Search} from '@shared/api-types';
import {PlayerHand, PlayerLevel, PlayerPosition} from '@shared/database-types';
import {usStates} from '@shared/us-states';
import {beautifyPlayerPosition, capitalize} from '@/lib/utils';
import {SearchFilters_Collapsable} from './_Collapsable';
import {SearchFilters_GradYear} from './_GradYear';

export type SearchFiltersType = Omit<
  Search['request']['body'],
  'searchValue' | 'limit' | 'offset'
>;

type Props = {
  defaultValues: SearchFiltersType;
  onChange: (data: SearchFiltersType) => void;
};

export const SearchFilters: FunctionalComponent<Props> = ({
  defaultValues: defaultValuesProp,
  onChange,
}) => {
  const defaultValuesRef = useRef(defaultValuesProp);
  const defaultValues = defaultValuesRef.current;
  const [opened, setOpened] = useState(false);
  const windowSize = useWindowSize();
  const currentValuesRef = useRef<SearchFiltersType>(defaultValues);

  const handleChange = (value: (string | number)[], key: string) => {
    const newFormData = {
      ...currentValuesRef.current,
      [key]: value,
    };
    if (
      JSON.stringify(newFormData) !== JSON.stringify(currentValuesRef.current)
    ) {
      currentValuesRef.current = newFormData;
      defaultValuesRef.current = newFormData;
      onChange?.(newFormData);
      let newUrl = new URL(window.location.href);
      Object.entries(newFormData).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          newUrl.searchParams.set(key, value.join(','));
        } else {
          newUrl.searchParams.delete(key);
        }
      });
      window.history.pushState({}, '', newUrl.toString());
    }
  };

  const content = (
    <Stack direction="column" gap={4}>
      <SearchFilters_Collapsable
        title="Type"
        defaultOpened={!!defaultValues.type?.[0]}
      >
        <CheckboxGroup
          maxOptions={6}
          defaultChecked={defaultValues?.type}
          onChange={(values) => handleChange(values, 'type')}
          options={[
            {
              label: 'Player',
              value: 'player',
            },
            {
              label: 'Team',
              value: 'team',
            },
          ]}
        />
      </SearchFilters_Collapsable>
      <SearchFilters_Collapsable
        title="Level"
        defaultOpened={!!defaultValues.level?.[0]}
      >
        <CheckboxGroup
          maxOptions={6}
          defaultChecked={defaultValues?.level}
          onChange={(values) => handleChange(values, 'level')}
          options={Object.keys(PlayerLevel).map((key) => ({
            label: capitalize(key),
            value: key,
          }))}
        />
      </SearchFilters_Collapsable>
      <SearchFilters_Collapsable
        title="State"
        defaultOpened={!!defaultValues.state?.[0]}
      >
        <CheckboxGroup
          maxOptions={6}
          defaultChecked={defaultValues?.state}
          onChange={(values) => handleChange(values, 'state')}
          options={Object.keys(usStates).map((state) => ({
            label: state,
            value: state,
          }))}
        />
      </SearchFilters_Collapsable>
      <SearchFilters_Collapsable
        title="Grad year"
        defaultOpened={!!defaultValues.gradYear?.[0]}
      >
        <SearchFilters_GradYear
          defaultValues={defaultValues}
          handleChange={handleChange}
          currentValuesRef={currentValuesRef}
        />
      </SearchFilters_Collapsable>
      <SearchFilters_Collapsable
        title="Primary position"
        defaultOpened={!!defaultValues.position1?.[0]}
      >
        <CheckboxGroup
          maxOptions={6}
          defaultChecked={defaultValues?.position1}
          onChange={(values) => handleChange(values, 'position1')}
          options={Object.keys(PlayerPosition).map((key) => ({
            label: beautifyPlayerPosition(key),
            value: key,
          }))}
        />
      </SearchFilters_Collapsable>
      <SearchFilters_Collapsable
        title="Throwing hand"
        defaultOpened={!!defaultValues.throwingHand?.[0]}
      >
        <CheckboxGroup
          maxOptions={6}
          defaultChecked={defaultValues?.throwingHand}
          onChange={(values) => handleChange(values, 'throwingHand')}
          options={Object.keys(PlayerHand).map((key) => ({
            label: capitalize(key),
            value: key,
          }))}
        />
      </SearchFilters_Collapsable>
      <SearchFilters_Collapsable
        title="Batting hand"
        defaultOpened={!!defaultValues.battingHand?.[0]}
      >
        <CheckboxGroup
          maxOptions={6}
          defaultChecked={defaultValues?.battingHand}
          onChange={(values) => handleChange(values, 'battingHand')}
          options={Object.keys(PlayerHand).map((key) => ({
            label: capitalize(key),
            value: key,
          }))}
        />
      </SearchFilters_Collapsable>
    </Stack>
  );

  if (windowSize.width > breakpoints.md) {
    return content;
  } else {
    return (
      <div>
        <Button
          onClick={() => {
            setOpened(true);
          }}
          variant="outline"
          css-mb={6}
        >
          Filters
        </Button>
        <Overlay
          opened={opened}
          setOpened={setOpened}
          noAutoFocus
          withBackdrop
          cancelOnOutsideClick
          cancelOnEscKey
          disableBodyScroll
          position={{mode: 'centered'}}
          animation="scale-in"
          css={`
            background: var(--bg-overlay);
            border-radius: var(--radius-3);
            box-shadow: var(--shadow-3);
            max-height: 90%;
            max-width: 60rem;
            overflow: hidden;
            width: 100%;
            display: grid;
            grid-template-rows: auto 1fr;
          `}
        >
          <Stack
            alignItems="center"
            css={`
              background: inherit;
              gap: var(--gap-1);
              padding: 0 var(--gap-4);
              position: sticky;
              top: 0;
              min-height: 4.75rem;
              z-index: 1;
              ${mediaQuery(
                'md>',
                css`
                  gap: var(--gap-4);
                  padding: 0 var(--gap-8) 0;
                `,
              )}
            `}
          >
            <Typography
              textTransform="uppercase"
              fontStyle="italic"
              css={`
                font-size: var(--font-size-subtitle-2);
                font-weight: var(--font-weight-extra-bold);
                letter-spacing: -0.0625rem;
                ${mediaQuery(
                  'md>',
                  css`
                    font-size: var(--font-size-h5);
                  `,
                )}
              `}
            >
              Filters
            </Typography>
            <FormSubmit
              variant="primary"
              onClick={() => setOpened(false)}
              css-ml="auto"
            >
              Close
            </FormSubmit>
          </Stack>
          <div
            css={`
              padding: 0 var(--gap-4) var(--gap-4);
              overflow-y: auto;
              height: 100%;
            `}
          >
            {content}
          </div>
        </Overlay>
      </div>
    );
  }
};
