import {FunctionalComponent} from 'preact';
import {useRef, useState} from 'preact/hooks';
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import {Controller, useForm} from 'react-hook-form';
import {css} from 'styled-components';
import {makeid, mediaQuery} from 'pylon/lib';
import {
  Button,
  Form,
  FormSelect,
  FormSubmit,
  FormTextarea,
  IconButton,
  InputVideo,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
} from 'pylon/ui';
import {CreateMePost} from '@shared/api-types';
import {VIDEOS_TMP_STORAGE_PATH} from '@shared/constants';
import {VideoRatio} from '@shared/database-types';
import {validatePostContent} from '@shared/validators';
import {useCreateMePost, useMeAlbums} from '@/fetch';
import {useAuthenticatedContext} from '@/lib/app-context';
import {isDevelopment} from '@/lib/environment';
import {handleFormValidate} from '@/lib/error-messages';
import {useNetworkError} from '@/lib/use-network-error';
import {AlbumFormOverlay} from './AlbumFormOverlay';

type FormData = CreateMePost['request']['body'] & {
  _videoFile?: File;
  ratio: string | null;
};

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
};

enum PostFormStep {
  VIDEO = 'video',
  CONTENT = 'content',
}

export const PostForm: FunctionalComponent<Props> = ({opened, setOpened}) => {
  const {currentUser} = useAuthenticatedContext();
  const [submiting, setSubmiting] = useState(false);
  const [displayAlbumForm, setDisplayAlbumForm] = useState(false);
  const [networkError, setNetworkError] = useNetworkError();
  const [createMePost] = useCreateMePost();
  const canceledRef = useRef(false);
  const [step, setStep] = useState<PostFormStep>(PostFormStep.VIDEO);
  const {
    data: {albums},
    loading: albumsLoading,
    error: albumsError,
  } = useMeAlbums();

  const {
    control,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>({
    shouldUnregister: false,
  });

  const onSubmit = async (formData: FormData) => {
    if (!formData._videoFile || !formData.ratio) return;
    setNetworkError(null);
    setSubmiting(true);
    try {
      let videoUnprocessedStoragePath = '';
      if (formData._videoFile) {
        videoUnprocessedStoragePath = await uploadVideo(
          formData._videoFile,
          currentUser.id,
        );
      }

      if (canceledRef.current) {
        return;
      }

      await createMePost({
        body: {
          pinned: false,
          content: formData.content,
          videoUnprocessedStoragePath,
          ratio: formData.ratio,
          albumId: formData.albumId || null,
        },
      });
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
    setOpened(false);
  };

  const handleCancel = () => {
    canceledRef.current = true;
    setOpened(false);
  };

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      position={{mode: 'centered'}}
      animation="scale-in"
      noAutoFocus
      withBackdrop
      cancelOnEscKey
      disableBodyScroll
      css={`
        background: var(--bg-overlay);
        border-radius: var(--radius-3);
        box-shadow: var(--shadow-3);
        max-height: 100%;
        max-width: 740px;
        overflow: hidden;
        padding: var(--gap-4);
        width: 100%;
        ${mediaQuery(
          'md>',
          css`
            padding: var(--gap-6);
          `,
        )}
      `}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        css-mb={6}
      >
        <Typography variant="h5" textTransform="uppercase" fontStyle="italic">
          Share Highlight
        </Typography>
        <IconButton icon="close" onClick={handleCancel} />
      </Stack>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {networkError}
          </ToastMessage>
        )}
        <div
          css={`
            display: ${step === PostFormStep.VIDEO ? 'block' : 'none'};
          `}
        >
          <Controller
            control={control}
            name="_videoFile"
            rules={{required: 'Video is required'}}
            render={({field: {onChange, onBlur, value}}) => (
              <InputVideo
                allowedRatios={Object.keys(allowedRatios).join(',')}
                onClear={() => {
                  onChange(null);
                }}
                onFinish={(resp) => {
                  setValue('ratio', allowedRatios[resp.ratio]);
                  onChange(resp.outputVideo);
                  if (resp.outputVideo) {
                    setStep(PostFormStep.CONTENT);
                  }
                }}
              />
            )}
          />
        </div>
        {step === PostFormStep.CONTENT && (
          <Stack direction="column" gap={6}>
            <Controller
              control={control}
              name="content"
              rules={{
                validate: (value) =>
                  handleFormValidate(validatePostContent(value ?? '')),
              }}
              render={({field: {onChange, onBlur, value}}) => (
                <FormTextarea
                  id="content"
                  label="Caption"
                  defaultValue={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  invalid={!!errors.content}
                  invalidText={errors?.content?.message}
                  fullWidth
                />
              )}
            />
            <Stack direction="column" alignItems="flex-start" gap={4}>
              <Controller
                name="albumId"
                control={control}
                rules={{required: false}}
                render={({field}) => {
                  return (
                    <FormSelect
                      id="albumId"
                      label="Album"
                      placeholder="Select an album"
                      disabled={
                        albumsLoading || !!albumsError || albums?.length === 0
                      }
                      options={
                        albums?.map((album) => ({
                          label: album.name,
                          value: album.id,
                        })) ?? []
                      }
                      invalid={!!errors.albumId}
                      invalidText={errors?.albumId?.message}
                      fullWidth
                      {...(field as any)}
                    />
                  );
                }}
              />
              <Button
                variant="primary-soft"
                buttonSize="sm"
                onClick={() => setDisplayAlbumForm(true)}
              >
                Create new album
              </Button>
              <AlbumFormOverlay
                opened={displayAlbumForm}
                setOpened={setDisplayAlbumForm}
              />
            </Stack>
            <Stack justifyContent="space-between" css-mt={6}>
              <Button
                variant="muted"
                onClick={() => {
                  setStep(PostFormStep.VIDEO);
                }}
              >
                Back
              </Button>
              <FormSubmit submitting={submiting} disabled={submiting}>
                Share
              </FormSubmit>
            </Stack>
          </Stack>
        )}
      </Form>
    </Overlay>
  );
};

const allowedRatios: {
  [key: string]: VideoRatio;
} = {
  '19:9': VideoRatio.RATIO_16_9,
  '9:16': VideoRatio.RATIO_9_16,
  '1:1': VideoRatio.RATIO_1_1,
};

export const uploadVideo = async (videoFile: File, currentUserId: string) => {
  const storage = getStorage();
  if (isDevelopment) {
    connectStorageEmulator(storage, 'localhost', 9199);
  }
  const storageRef = ref(
    storage,
    `${VIDEOS_TMP_STORAGE_PATH}/${currentUserId}/${makeid(10)}`,
  );
  const resp = await uploadBytes(storageRef, videoFile);
  return resp.metadata.fullPath;
};
