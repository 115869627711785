import {FunctionalComponent} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import {
  Button,
  Form,
  FormInput,
  FormSubmit,
  Overlay,
  Stack,
  ToastMessage,
  Typography,
  overlayPopupCss,
} from 'pylon/ui';
import {CreateMeTeam} from '@shared/api-types';
import {ErrorCode} from '@shared/error-code';
import {validateTeamName} from '@shared/validators';
import {useCreateMeTeam} from '@/fetch';
import {handleFormValidate} from '@/lib/error-messages';
import {useNetworkError} from '@/lib/use-network-error';

type FormData = CreateMeTeam['request']['body'];

type Props = {
  onCompleted?: () => void;
  opened: boolean;
  setOpened: (value: boolean) => void;
};

export const UserSettingsCreateTeamFormOverlay: FunctionalComponent<Props> = ({
  onCompleted,
  opened,
  setOpened,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [createMeTeam] = useCreateMeTeam();
  const [networkError, setNetworkError] = useNetworkError();

  const {
    formState: {errors},
    handleSubmit,
    register,
    reset,
  } = useForm<FormData>();

  const onSubmit = async (formData: FormData) => {
    setNetworkError(null);
    setSubmiting(true);
    try {
      await createMeTeam({
        body: formData,
      });
      onCompleted?.();
      setOpened(false);
    } catch (error: unknown) {
      setNetworkError(error);
    }
    setSubmiting(false);
  };

  const handleCancel = () => {
    setNetworkError(null);
    setOpened(false);
  };

  useEffect(() => {
    if (!opened) {
      reset();
    }
  }, [opened]);

  return (
    <Overlay
      opened={opened}
      setOpened={setOpened}
      withBackdrop
      cancelOnEscKey
      cancelOnOutsideClick
      position={{
        mode: 'centered',
      }}
      css={`
        ${overlayPopupCss}
      `}
    >
      <Typography
        variant="h5"
        fontStyle="italic"
        textTransform="uppercase"
        css-mb={4}
      >
        Create team
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {networkError && (
          <ToastMessage severity="error" css-mb={5}>
            {networkError}
          </ToastMessage>
        )}
        <Stack direction="column" gap={6}>
          <FormInput
            {...register('name', {
              validate: (value) => handleFormValidate(validateTeamName(value)),
            })}
            id="name"
            label="Name"
            invalid={!!errors.name}
            invalidText={errors?.name?.message}
            fullWidth
          />
        </Stack>
        <Stack css-mt={6} gap={4}>
          <FormSubmit
            variant="primary"
            value="Create team"
            submitting={submiting}
            disabled={submiting}
          />{' '}
          <Button variant="muted" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Form>
    </Overlay>
  );
};
