import {FunctionalComponent} from 'preact';
import {useLocation} from 'preact-iso';
import {useState} from 'preact/hooks';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {useForm} from 'react-hook-form';
import {Anchor, Form, FormInput, FormSubmit, Stack, Typography} from 'pylon/ui';
import {ErrorCode} from '@shared/error-code';
import {validateEmail} from '@shared/validators';
import {AuthLayout} from '@/components/AuthLayout';
import {ROUTES} from '@/lib/app-routes';
import {AFTER_LOGIN_REDIRECT} from '@/lib/auth';
import {handleFormValidate} from '@/lib/error-messages';

type FormData = {
  email: string;
  password: string;
};

type Props = {};

export const GuestSignInRoute: FunctionalComponent<Props> = () => {
  const location = useLocation();
  const [submiting, setSubmiting] = useState(false);
  const {
    formState: {errors},
    handleSubmit,
    register,
    setError,
  } = useForm<FormData>();

  const onSubmit = async (formData: FormData) => {
    setSubmiting(true);
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      location.route(AFTER_LOGIN_REDIRECT);
    } catch (err: any) {
      setError('password', {
        type: 'manual',
        message: err?.message || ErrorCode.SOMETHING_WENT_WRONG,
      });
    }
    setSubmiting(false);
  };

  return (
    <AuthLayout title="Sign in to Prospect Dugout">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={8}>
          <FormInput
            {...register('email', {
              validate: (value) => handleFormValidate(validateEmail(value)),
            })}
            id="email"
            label="Email"
            type="email"
            size="lg"
            invalid={!!errors.email}
            invalidText={errors?.email?.message}
            fullWidth
          />
          <FormInput
            {...register('password')}
            id="password"
            size="lg"
            label={
              (
                <Stack justifyContent="space-between">
                  <span>Password</span>
                  <Anchor
                    tabIndex={-1}
                    href={ROUTES.GUEST_FORGOT_PASSWORD}
                    variant="primary"
                    css={`
                      font-weight: var(--font-weight-regular);
                    `}
                  >
                    Forgot?
                  </Anchor>
                </Stack>
              ) as any
            }
            type="password"
            invalid={!!errors.password}
            invalidText={errors?.password?.message}
            fullWidth
          />
          <FormSubmit
            variant="primary"
            value="Sign in"
            submitting={submiting}
            disabled={submiting}
            buttonSize="lg"
            fullWidth
          />
        </Stack>
        <Typography variant="body3" css-textAlign="center" css-mt={4}>
          Don't have an account?{' '}
          <Anchor variant="primary" href={ROUTES.GUEST_SIGN_UP}>
            Sign up
          </Anchor>
        </Typography>
      </Form>
    </AuthLayout>
  );
};
