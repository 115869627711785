import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const timeAgo = (date: string | Date | number) => {
  const now = dayjs();
  const targetDate = dayjs(date);

  const diffInDays = now.diff(targetDate, 'day');

  if (diffInDays < 7) {
    return targetDate.fromNow();
  } else if (diffInDays < 365) {
    return `on ${targetDate.format('MMMM D')}`;
  } else {
    return `on ${targetDate.format('MMMM D, YYYY')}`;
  }
};

export {timeAgo, dayjs};
